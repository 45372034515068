import { MTOEAssignmentDto } from '../models';
import { ISerializer } from './ISerializer';

export class MTOEAssignmentSerializer implements ISerializer<MTOEAssignmentDto> {
	public fromJson(json: Record<string, unknown>): MTOEAssignmentDto {
		const mtoeAssignment = new MTOEAssignmentDto();
		mtoeAssignment.id = json['id'] as number;
		mtoeAssignment.companyId = json['companyId'] as number;
		mtoeAssignment.dutyDefinitionId = json['dutyDefinitionId'] as number;
		// Map other properties from JSON to MTOEAssignmentDto
		return mtoeAssignment;
	}

	public toJson(entity: MTOEAssignmentDto): Record<string, unknown> {
		return {
			id: entity.id,
			companyId: entity.companyId,
			dutyDefinitionId: entity.dutyDefinitionId,
			// Map other properties from MTOEAssignmentDto to JSON
		};
	}
}