import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { RmsService } from 'src/app/services/rms.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { PickerOperation } from '../../models/PickerOperation';

interface IParams {
	operationId: number | null;
	memberId: number | number[] | null;
	dates: Date | Date[] | null;
}

type PickerEvent = {
	operation: PickerOperation;
	date: Date;
	endDate?: Date;
}

@Component({
	selector: 'app-operation-date-picker',
	templateUrl: './operation-date-picker.component.html',
	styleUrls: ['./operation-date-picker.component.scss'],
	providers: [DatePipe]
})
export class OperationDatePickerComponent implements OnInit {
	@Input()
	public watchForQueryParams: boolean = false;
	@Input()
	public showClear: boolean = false;
	@Input()
	public showDatePicker: boolean = true;
	@Input()
	public dateLabel: string = 'Duty Date';
	@Input()
	public datesInTandem: boolean = true;
	@Input()
	public datePickerMode: 'single' | 'range' = 'single';

	@ViewChild('dutyDateRangeCal')
	public dutyDateRangeCal: Calendar;

	public init: boolean = false;
	public operations: PickerOperation[] = [];
	public operationsCache: PickerOperation[] = [];
	public operation: PickerOperation = null;
	public operationsDisabled: boolean = true;
	public dutyDateMaxDate: Date = this.getTomorrowsDate();
	public dutyDateValue: Date | Date[] = new Date();
	public btnDisabled: boolean = true;

	@Output()
	public onChange = new EventEmitter<PickerEvent>();

	public constructor(
		public datePipe: DatePipe,
		private router: Router,
		private route: ActivatedRoute,
		private rmsService: RmsService,
		private spinnerService: SpinnerService,
		private messageService: MessageService
	) {}

	public async ngOnInit(): Promise<void> {
		this.spinnerService.showSpinner = true;

		this.operations = await this.getOperations();

		this.spinnerService.showSpinner = false;

		if (!this.watchForQueryParams) {
			return;
		}

		const params = this.getParams();

		if (params === null) {
			return;
		}

		// TODO: Find a way to get around the timeout
		setTimeout(() => {
			this.operation = this.operations.find((x) => x.id === Number(params.operationId)) ?? null;
			this.dutyDateValue = params.dates;

			if (this.checkDates()) {
				this.operationsDisabled = false;

				if (this.operation) {
					this.btnDisabled = false;
				}
			}

			void this.onOperationChange();
			this.emitChange();
		}, 1);

		// TODO: Do we even need this?
		this.router.events
			.pipe(
				filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd)
			)
			.subscribe((event) => {
				if (event.id === 1 && event.url === event.urlAfterRedirects) {
					this.operations = [];
				}
			});
	}

	public async onDateChange(): Promise<void> {
		if (this.checkDates()) {
			this.dutyDateRangeCal.toggle();
			this.operationsDisabled = false;

			if (this.watchForQueryParams) {
				await this.setParams();
			}
		}
	}

	public async onOperationChange() {
		if (this.checkDates()) {
			if (this.operation) {
				this.btnDisabled = false;
			}

			if (this.watchForQueryParams) {
				await this.setParams();
			}
		}
	}

	public onSubmit() {
		if (this.checkDates()) {
			this.emitChange();
		}
	}

	public async onClear(): Promise<void> {
		this.emitChange();

		if (this.watchForQueryParams) {
			await this.setParams();
		}
	}

	private checkDates(): boolean {
		return Array.isArray(this.dutyDateValue)
			? this.dutyDateValue?.every((x) => x !== null)
			: this.dutyDateValue instanceof Date;
	}

	private emitChange() {
		if (this.operation) {
			this.onChange.emit({
				operation: this.operation,
				date: Array.isArray(this.dutyDateValue)
					? this.dutyDateValue[0]
					: this.dutyDateValue,
				endDate: Array.isArray(this.dutyDateValue)
					? this.dutyDateValue[1]
					: null,
			});
		}
	}

	private async getOperations(): Promise<PickerOperation[]> {
		try {
			return await this.rmsService.getOperationsForPicker();
		}
		catch (error: unknown) {
			this.handlerError(error, 'Error Fetching Operations');
		}

		return [];
	}

	private getParams(): IParams {
		const operationId = this.route.snapshot.queryParams?.operationId as string | null;
		const memberId = this.route.snapshot.queryParams?.memberId as string | null;
		const dates = this.route.snapshot.queryParams?.dates as string | string[] | null;

		const parsedOperationId = operationId !== null ? Number(operationId) : null;
		const parsedMemberId = Array.isArray(memberId)
			? memberId.map((x: string) => Number(x))
			: memberId !== null ? Number(memberId) : null;

		const parseDates = (dateString: string): Date | null => {
			const parsedDate = Date.parse(dateString);
			return isNaN(parsedDate) ? null : new Date(parsedDate);
		};

		const parsedDates = Array.isArray(dates)
			? dates.map(parseDates).filter((date): date is Date => date !== null)
			: dates !== null ? parseDates(dates) : null;

		return {
			operationId: isNaN(parsedOperationId) ? null : parsedOperationId,
			memberId: Array.isArray(parsedMemberId)
				? parsedMemberId.map((x) => (isNaN(x) ? null : x))
				: isNaN(parsedMemberId) ? null : parsedMemberId,
			dates: parsedDates,
		};
	}

	private async setParams(): Promise<void> {
		const queryParams: {
			operationId?: number;
			dates?: string | string[];
		} = {};

		if (this.operation) {
			queryParams.operationId = this.operation.id;
		}

		if (this.dutyDateValue) {
			queryParams.dates = Array.isArray(this.dutyDateValue)
				? this.dutyDateValue?.map((x) => this.getYearMonthDayDateString(x))
				: this.getYearMonthDayDateString(this.dutyDateValue);
		}

		await this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			replaceUrl: !this.init,
		});

		if (!this.init) {
			this.init = true;
		}
	}

	private getYearMonthDayDateString(date: Date): string {
		return this.datePipe.transform(date, 'yyyy-MM-dd');
	}

	private getTomorrowsDate(): Date {
		const today = new Date();

		today.setDate(today.getDate() + 1);

		return today;
	}

	private handlerError(
		error: MessageServiceType,
		summary: string = 'HTTP Error'
	) {
		this.messageService.add({
			severity: 'error',
			summary,
			detail: error.message,
		});
	}
}
