import { SQIDto } from '../models/SQIDto';
import { ISerializer } from './ISerializer';

export class SQISerializer implements ISerializer<SQIDto> {
	public fromJson(json: Record<string, unknown>): SQIDto {
		const sqiDto = new SQIDto();
		sqiDto.id = json['id'] as number;
		sqiDto.code = json['code'] as string;
		sqiDto.name = json['name'] as string;
		// Map other properties from JSON to SQIDto
		return sqiDto;
	}

	public toJson(entity: SQIDto): Record<string, unknown> {
		return {
			id: entity.id,
			code: entity.code,
			name: entity.name,
			// Map other properties from SQIDto to JSON
		};
	}
}