import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { MOSDto } from '../models/MOSDto';
import { MOSSerializer } from '../serializers/MOSSerializer';

@Injectable()
export class MOSService extends GenericService<MOSDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'MOSs',
			new MOSSerializer()
		);
	}
}
