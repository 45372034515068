import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { BranchDto } from '../models';
import { BranchSerializer } from '../serializers';

@Injectable()
export class BranchService extends GenericService<BranchDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'Branches',
			new BranchSerializer()
		);
	}
}
