import { CompanyUnitDto } from '../models';
import { ISerializer } from './ISerializer';

export class CompanyUnitSerializer implements ISerializer<CompanyUnitDto> {
	public fromJson(json: Record<string, unknown>): CompanyUnitDto {
		const company = new CompanyUnitDto();
		company.id = json['id'] as number;
		company.uic = json['uic'] as string;
		company.branch = json['branch'] as string;
		company.brigade = json['brigade'] as string;
		company.battalion = json['battalion'] as string;
		company.company = json['company'] as string;
		company.branchId = json['branchId'] as number;
		company.brigadeId = json['brigadeId'] as number;
		company.battalionId = json['battalionId'] as number;
		company.companyId = json['companyId'] as number;
		// Map other properties from JSON to CompanyUnitDto
		return company;
	}

	public toJson(entity: CompanyUnitDto): Record<string, unknown> {
		return {
			id: entity.id,
			uic: entity.uic,
			branch: entity.branch,
			brigade: entity.brigade,
			battalion: entity.battalion,
			company: entity.company,
			branchId: entity.branchId,
			brigadeId: entity.brigadeId,
			battalionId: entity.battalionId,
			companyId: entity.companyId,
			// Map other properties from CompanyUnitDto to JSON
		};
	}
}
