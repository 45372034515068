import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

export type FeatureFlag = {
	id: number;
	name: string;
	enabled: boolean;
};

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	private baseUrl = environment.api;
	private flags: Array<FeatureFlag> = [];

	public constructor(
		private http: HttpClient,
		private userService: UserService
	) {}

	public async load(): Promise<void> {
		this.flags = await this.http
			.get<Array<FeatureFlag>>(this.baseUrl + 'api/FeatureFlags')
			.toPromise();
	}

	/**
	 * Checks if feature is enabled
	 * @param name Name of the feature
	 * @returns false if no feature flag is found, otherwise return feature flag enabled value.
	 */
	public isFeatureEnabled(name: string): boolean {
		if (this.userService.roles.some(x => x.id === 1)) {
			return true;
		}

		return this.flags.find((x) => x.name.toLowerCase() === name.toLowerCase())?.enabled ?? false;
	}
}
