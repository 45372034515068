import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, retry, map, tap } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { GlobalService } from './globals.service';
import { environment } from '../../environments/environment';
import MemberOccupationRequest from '../requests/MemberOccupationRequest';
import { Member, MemberModel } from '../interfaces/MemberModel';
import {
	MemberListItem,
	PaginatedResults,
	SearchParameters,
} from '../interfaces/PaginationTabelModel';
import { MyMember } from '../interfaces/MemberModel';
import { Recruiter } from '../interfaces/recruiter';
import { MemberAdvanceSearch } from '../interfaces/member-advance-search';
import { MemberPhoto } from '../interfaces/member-photo';
import { MilitaryUnit } from '../interfaces/MilitaryUnit';
import { Strength } from '../interfaces/strength';
import { OccupationBasicDto } from '../interfaces/IOccupation';

interface SearchResponse<T> {
	[index: number]: T;
}

@Injectable({
	providedIn: 'root',
})
export class MemberService {
	private handleError: HandleError;
	private baseUrl = environment.api;
	public myMember: MyMember;

	public static attendanceTypes = [
		{ id: 1, description: 'Meets Standard' },
		{ id: 2, description: 'Does Not Meet Standard' },
		// { id: 3, description: 'Above Standard'}
	];
	public static atAttendanceTypes = [
		{ id: 1, description: 'Attended' },
		{ id: 2, description: 'Did Not Attend - Excused' },
		{ id: 3, description: 'Did Not Attend - Unexcused' },
		// { id: 3, description: 'N/A - Joined Since'}
	];
	public static deployTypes = [
		{ id: 1, description: 'Deployable-All Areas (DEPA)' },
		{ id: 2, description: 'Deployable-Limited/Rear Areas (DEPL)' },
		{ id: 3, description: 'Not Deployable-Temporary (NDPTEM)' },
		{ id: 4, description: 'Not Deployable-Permanent (NDPER)' },
	];
	public static performanceTypes = [
		{ id: 1, description: 'Meets Standards' },
		{ id: 2, description: 'Unsatisfactory' },
		{ id: 3, description: 'Needs Improvement' },
		{ id: 4, description: 'Exceeds Standards' },
	];
	public static conductTypes = [
		{ id: 1, description: 'Meets Standards' },
		{ id: 2, description: 'Unsatisfactory' },
		{ id: 3, description: 'Needs Improvement' },
		{ id: 4, description: 'Exceeds Standards' },
	];
	public static trainingTypes = [
		{ id: 1, description: 'Meets Standards' },
		{ id: 2, description: 'Unsatisfactory' },
		{ id: 3, description: 'Needs Improvement' },
		{ id: 4, description: 'Exceeds Standards' },
	];
	public static leadershipTypes = [
		{ id: 1, description: 'Meets Standards' },
		{ id: 2, description: 'Unsatisfactory' },
		{ id: 3, description: 'Needs Improvement' },
		{ id: 4, description: 'Exceeds Standards' },
	];
	public static promoteTypes = [
		{ id: 1, description: 'Retain & Promote On Schedule' },
		{ id: 2, description: 'Retain & Promote At Earliest Point' },
		{ id: 3, description: 'Do Not Retain' },
	];
	public static dropdownList: { item_id: number; item_text: string }[] = [
		{ item_id: 1, item_text: 'Private Pilot' },
		{ item_id: 2, item_text: 'Commercial Pilot' },
		{ item_id: 3, item_text: 'Airline Transport Pilot' },
		{ item_id: 4, item_text: 'Flight Instructor' },
		{ item_id: 5, item_text: 'Part 197 Drone Pilot' },
		{ item_id: 6, item_text: 'Boater Safety Course' },
		{ item_id: 7, item_text: 'Coast Guard Aux Course' },
		{ item_id: 8, item_text: 'Boat Owner > 16ft' },
		{ item_id: 9, item_text: 'Boat Owner < 16ft' },
		{ item_id: 10, item_text: 'Open Water Scuba Diver' },
		{ item_id: 11, item_text: 'Advanced Open Water Scuba Diver' },
		{ item_id: 12, item_text: 'Rescue Diver' },
		{ item_id: 13, item_text: 'Dive Master/Master Diver' },
		{ item_id: 14, item_text: 'Dive Instructor' },
		{ item_id: 15, item_text: 'Marine Engineer Mechanic' },
		{ item_id: 16, item_text: 'Military Diver Qualified' },
		{ item_id: 17, item_text: 'Motorcycle License' },
		{ item_id: 18, item_text: 'CDL' },
		{ item_id: 19, item_text: 'Forklift Driver' },
		{ item_id: 20, item_text: 'Tow Truck Operator' },
		{ item_id: 21, item_text: 'FCC Technician Amateur Radio Licence' },
		{ item_id: 22, item_text: 'FCC General Amateur Radio Licence' },
		{ item_id: 23, item_text: 'FCC Advanced Amateur Radio Licence' },
		{ item_id: 24, item_text: 'FCC Extra Amateur Radio Licence' },
		{ item_id: 25, item_text: 'Amateur Radio Equipment Owner' },
		{ item_id: 26, item_text: 'Systems Administrator' },
		{ item_id: 27, item_text: 'IT Business Analyst' },
		{ item_id: 28, item_text: 'Cyber Security Certified' },
		{ item_id: 29, item_text: 'CPA' },
		{ item_id: 30, item_text: 'Certified Emergency Manager' },
		{ item_id: 31, item_text: 'Professional Engineer' },
		{ item_id: 32, item_text: 'Notary Public' },
		{ item_id: 33, item_text: 'Paralegal' },
		{ item_id: 34, item_text: 'Database Administrator' },
		{ item_id: 35, item_text: 'Licensed Electrician' },
		{ item_id: 36, item_text: 'Lawyer' },
		{ item_id: 37, item_text: 'Doctor MD/DO' },
		{ item_id: 38, item_text: 'Physicians Assistant' },
		{ item_id: 39, item_text: 'Nurse' },
		{ item_id: 40, item_text: 'EMT' },
		{ item_id: 41, item_text: 'Paramedic' },
		{ item_id: 42, item_text: 'Doctor of Divinity' },
		{ item_id: 43, item_text: 'Doctor of Ministry' },
		{ item_id: 44, item_text: 'Doctor of Theology' },
		{ item_id: 45, item_text: 'Master of Theology' },
		{ item_id: 46, item_text: 'Master of Divinity' },
		{ item_id: 47, item_text: 'Master of Theological Studies' },
		{ item_id: 48, item_text: 'Professional Surveyor & Mapper' },
		{ item_id: 49, item_text: 'Professional Land Surveyor' },
		{ item_id: 50, item_text: 'Law Enforcement Officer' },
		{ item_id: 51, item_text: 'Fire Officer' },
		{ item_id: 52, item_text: 'Professional Cook' },
		{ item_id: 53, item_text: 'Certified Plumber' },
		{ item_id: 54, item_text: 'Certifed Electrician' },
		{ item_id: 55, item_text: 'Crane Operator' },
		{ item_id: 56, item_text: 'Heavy Equipment Specialist' },
		{ item_id: 57, item_text: 'Licensed Barber' },
		{ item_id: 58, item_text: 'Locksmith' },
		{ item_id: 59, item_text: 'Welder' },
		{ item_id: 60, item_text: 'Small Equipment Mechanic' },
	];
	//httpClient: any;

	public constructor(
		// @Inject('BASE_URL') private baseUrl: string,
		public httpErrorHandler: HttpErrorHandler,
		private http: HttpClient,
		private globals: GlobalService
	) {
		this.handleError = httpErrorHandler.createHandleError('MemberService');
	}

	public search(term: string): Observable<Member[]> {
		if (term === '') {
			return of<Member[]>([]);
		}

		return this.http
			.get<SearchResponse<Member[]>>(this.baseUrl + 'api/Member/Search?term=' + term)
			.pipe(map((response) => response[1]));
	}

	public searchDischarged(term: string): Observable<Member[]> {
		if (term === '') {
			return of<Member[]>([]);
		}

		return this.http
			.get<SearchResponse<Member[]>>(this.baseUrl + 'api/Member/SearchDischarged?term=' + term)
			.pipe(map((response) => response[1]));
	}

	public getMemberDetail(memberId: number): Observable<MemberModel> {
		const url = `${this.baseUrl}api/Member/GetByMemberID?memberId=${memberId}`;
		const val = this.http.get<MemberModel>(url);
		return val;
	}

	public getMembersByIds(memberIds: number[]): Promise<Array<Member>> {
		return Promise.resolve(firstValueFrom(this.http.post<Array<Member>>(`${this.baseUrl}api/Members`, memberIds)));
	}

	public getMembersByUnit(unit: MilitaryUnit): Observable<MemberListItem[]> {
		return this.http
			.get<MemberListItem[]>(this.baseUrl +
					'api/member/getByUnit?unitId=' +
					unit.militaryUnitID)
			.pipe(
				catchError(
					this.handleError<MemberListItem[]>(
						'getMembersByUnit',
						undefined
					)
				)
			);
	}

	// Not Used
	// public getMembersByDdc(ddc): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/member/getByDdc?ddc=' + ddc.ddcid)
	// 		.pipe(catchError(this.handleError('getMembersByDdc', undefined)));
	// }

	// Not used
	// public getMembersByDdcRegion(ddc): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/member/getByDdc?region=' + ddc.region)
	// 		.pipe(catchError(this.handleError('getMembersByDdc', undefined)));
	// }

	public getStrengthStatus(): Observable<Strength> {
		return this.http
			.get<Strength>(this.baseUrl + 'api/dashboard/GetStrengthStatus')
			.pipe(
				catchError(
					this.handleError<Strength>('getStrengthStatus', undefined)
				)
			);
	}

	public getMemberAdvanceSearch(
		advSearch
	): Observable<MemberAdvanceSearch[]> {
		return this.http
			.get<MemberAdvanceSearch[]>(this.baseUrl + 'api/member/getByAdvanceSearch' + advSearch)
			.pipe(
				catchError(
					this.handleError<MemberAdvanceSearch[]>(
						'getMemberAdvanceSearch',
						undefined
					)
				)
			);
	}

	public GetMemberPaginationSeach(
		SearchParameters: SearchParameters
	): Observable<PaginatedResults<MemberListItem[]>> {
		return this.http.post<PaginatedResults<MemberListItem[]>>(
			this.baseUrl + 'api/member/GetByAdvanceSearchNew',
			SearchParameters,
			{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
		);
	}

	public getMemberPhoto(memberId: number): Observable<MemberPhoto> {
		return this.http
			.get<MemberPhoto>(this.baseUrl + 'api/MemberPhoto/' + memberId)
			.pipe(
				catchError(
					this.handleError<MemberPhoto>('getMemberPhoto', undefined)
				)
			);
	}

	public putMemberPhoto(memberId: number, photoData) {
		return this.http
			.put(
				this.baseUrl + 'api/MemberPhoto?MemberID=' + memberId,
				photoData,
				{ observe: 'response' }
			)
			.pipe(catchError(this.handleError('putMemberPhoto', undefined)));
	}

	public deleteMemberPhoto(memberId: number) {
		return this.http
			.delete(this.baseUrl + 'api/MemberPhoto/' + memberId, {
				observe: 'response',
			})
			.pipe(catchError(this.handleError('deleteMemberPhoto', undefined)));
	}

	public getMyMember(refresh = false): Observable<MyMember> {
		if (!refresh && this.myMember) {
			return of(this.myMember);
		} else {
			return this.http
				.get<MyMember>(this.baseUrl + 'api/MyMember/Get')
				.pipe(
					retry(3),
					tap((x) => (this.myMember = x))
				);
		}
	}

	public getMemberSSN(memberId: number): Promise<string> {
		return this.http
			.get<number>(`${this.baseUrl}api/member/getSocialSecurityNumber?SsnMemberID=${memberId}`)
			.pipe(map((x) => String(x)))
			.toPromise();
	}

	// Not used
	// public getMemberDDCs(member): Observable<any> {
	// 	return this.http
	// 		.get(
	// 			this.baseUrl +
	// 			'api/MemberRecord/GetDDC?MemberDDCID=' +
	// 			member.memberID
	// 		)
	// 		.pipe(catchError(this.handleError('getMemberDDCs', undefined)));
	// }

	// public getAllUnits(member): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/personnel/GetAllUnits?geoLookup=' + "false")
	// 		.pipe(catchError(this.handleError('getAllUnits', undefined)));
	// }

	public getUnitByMember(member: MemberModel): Observable<MilitaryUnit[]> {
		return this.http
			.get<MilitaryUnit[]>(this.baseUrl +
					'api/personnel/GetUnitByMember?MemberId=' +
					member.memberID)
			.pipe(
				catchError(
					this.handleError<MilitaryUnit[]>(
						'getGetUnitByMember',
						undefined
					)
				)
			);
	}

	public getRecruiters(): Observable<Recruiter[]> {
		return this.http
			.get<Recruiter[]>(this.baseUrl + 'api/recruiter/getRecruiters')
			.pipe(catchError(this.handleError('getRecruiters', [])));
	}

	public getRecruiter(): Observable<Recruiter[]> {
		return this.http
			.get<Recruiter[]>(this.baseUrl + 'api/recruiter/lookUpRecruiter')
			.pipe(catchError(this.handleError('getRecruiter', [])));
	}

	public getRecruiterUnderlings(): Observable<Recruiter[]> {
		return this.http
			.get<Recruiter[]>(this.baseUrl + 'api/recruiter/getUnderlings')
			.pipe(catchError(this.handleError('getRecruiterUnderlings', [])));
	}

	public getAvailableRecruiterUnderlings(): Observable<Recruiter[]> {
		return this.http
			.get<Recruiter[]>(this.baseUrl + 'api/recruiter/getAvailableUnderlings')
			.pipe(
				catchError(
					this.handleError('getAvailableRecruiterUnderlings', [])
				)
			);
	}

	public getPersonnelStats(months: number, depth: number) {
		const query = this.globals.getParams({
			Months: months,
			Depth: depth,
		});

		return this.http
			.get(this.baseUrl + 'api/personnelStats/GetStrength' + query)
			.pipe(
				retry(3),
				catchError(this.handleError('getPersonnelStats', undefined))
			);
	}

	public getGainLoss(
		unitId: number,
		interval: number,
		months: number,
		depth: number
	) {
		const query = this.globals.getParams({
			UnitId: unitId,
			Interval: interval,
			Months: months,
			Depth: depth,
		});

		return this.http
			.get(this.baseUrl + 'api/personnelStats/GetGainLossByUnit' + query)
			.pipe(
				retry(3),
				catchError(this.handleError('getPersonnelStats', undefined))
			);
	}

	public getGainLossByUnit(
		unit: string,
		interval: number,
		months: number,
		depth: number
	) {
		const query = this.globals.getParams({
			Unit: unit,
			Interval: interval,
			Months: months,
			Depth: depth,
		});

		return this.http
			.get(
				this.baseUrl +
					'api/personnelStats/GetGainLossByUnitName' +
					query
			)
			.pipe(
				retry(3),
				catchError(this.handleError('getPersonnelStats', undefined))
			);
	}

	public addEmergencyContact(ecUpdate: string) {
		return this.http
			.post(
				this.baseUrl +
					'api/Member/PostMemberEmergencyContact' +
					ecUpdate,
				ecUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(this.handleError('addEmergencyContact', undefined))
			);
	}
	public EditMemberEmergencyContact(params) {
		return this.http
			.put(
				this.baseUrl + 'api/Member/EditMemberEmergencyContact' + params,
				params,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('EditMemberEmergencyContact', undefined)
				)
			);
	}

	public putMemberPersonalUpdate(idUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberPersonalUpdate' + idUpdate,
				idUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberPersonalUpdate', undefined)
				)
			);
	}

	public putMemberIdentityUpdate(idUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberIdentityUpdate' + idUpdate,
				idUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberIdentityUpdate', undefined)
				)
			);
	}

	public putMemberContactUpdate(idUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberContactUpdate' + idUpdate,
				idUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberContactUpdate', undefined)
				)
			);
	}

	public putMemberCivEducationAdd(ceUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberCivEducation' + ceUpdate,
				ceUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberCivEducationAdd', undefined)
				)
			);
	}

	public putMemberMilEducationAdd(ceUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberMilEducation' + ceUpdate,
				ceUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberMilEducationAdd', undefined)
				)
			);
	}

	public putMemberFEMAEducationAdd(ceUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberFEMAEducation' + ceUpdate,
				ceUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberFEMAEducation', undefined)
				)
			);
	}

	public putMemberAwardAdd(
		memberAwardId: number,
		memberId: number,
		awardType: number,
		dateAwarded: Date,
		awardedBy: string,
		awardedByNonTXSG_Member: string,
		orderNumber: string,
		justification: string
	) {
		const dateAw = new Date(dateAwarded).toJSON();
		const query = this.globals.getParams({
			memberAwardId: memberAwardId,
			awardTypeId: awardType,
			memberId: memberId,
			awardedByMember: awardedBy,
			awardedByNonTXSG_Member: awardedByNonTXSG_Member,
			dateAwarded: dateAw,
			justification: justification,
			orderNumber: orderNumber,
		});

		return this.http
			.put(
				this.baseUrl + 'api/memberAward/AddEditHistoricalAward' + query,
				query,
				{ observe: 'response' }
			)
			.pipe(catchError(this.handleError('putMemberAwardAdd', undefined)));
	}

	public putMemberLicenseAdd(licUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberLicense' + licUpdate,
				licUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(this.handleError('putMemberLicenseAdd', undefined))
			);
	}

	public putMemberPreferenceAdd(pAdd: string) {
		return this.http
			.put(this.baseUrl + 'api/member/putMemberPreference' + pAdd, pAdd, {
				observe: 'response',
			})
			.pipe(
				catchError(
					this.handleError('putMemberPreferenceAdd', undefined)
				)
			);
	}

	public putMemberRoleAdd(rAdd: string) {
		return this.http.put(
			this.baseUrl + 'api/member/putMemberRole' + rAdd,
			rAdd,
			{ observe: 'response' }
		);
	}

	public putMemberFemaEdit(fdUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberFEMASID' + fdUpdate,
				fdUpdate,
				{ observe: 'response' }
			)
			.pipe(catchError(this.handleError('putMemberFemaEdit', undefined)));
	}

	public putMemberPhysicalUpdate(idUpdate: string) {
		return this.http.put(
			this.baseUrl + 'api/physicalInfo' + idUpdate,
			idUpdate,
			{ observe: 'response' }
		);
		// .pipe(catchError(this.handleError('putMemberPhysicalUpdate', undefined)))
	}

	public putRecruiterEdit(rUpdate) {
		return this.http
			.put(
				this.baseUrl + 'api/recruiter/putEditRecruiter' + rUpdate,
				rUpdate,
				{ observe: 'response' }
			)
			.pipe(catchError(this.handleError('putRecruiterEdit', undefined)));
	}

	public putSocialSecurityNumber(ssnUpdate) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putSocialSecurityNumber' + ssnUpdate,
				ssnUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putSocialSecurityNumber', undefined)
				)
			);
	}

	// public putMemberPhotoUpdate(editMember: any, file: File): Observable<HttpResponse<any>> {
	// 	return this.http
	// 		.put(this.baseUrl + 'api/memberphoto/putMemberPhoto?MemberID=' + editMember.memberID + '&file=' + file, { observe: 'response' })
	// 		.pipe(catchError(this.handleError('putMemberPhoto', undefined)));
	// }
	// public uploadFile(editMember: any, file: File): Observable<HttpResponse<any>> {

	// 	let headers = new HttpHeaders({
	// 	   'Access-Control-Allow-Origin': '*',
	// 	   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
	// 	   'Access-Control-Allow-Headers': 'Content-Type,Accept,X-Access-Token,X-Key,Authorization,X-Requested-With,Origin,Access-Control-Allow-Origin,Access-Control-Allow-Credentials,content-type=multipart/*'
	// 	})
	//    let options = {headers:headers, observer: 'response'};
	//    let formData: FormData = new FormData();
	//    formData.append('file', file, file.name);
	//    return this.http.put(this.baseUrl + 'api/memberphoto/PutMemberPhoto?MemberID='+editMember.memberID, formData, options)
	//    .pipe(catchError(this.handleError('putMemberPhoto', undefined)));
	//  }

	public uploadCivilianEducationFile(
		memberId: number,
		file: Blob,
		fileName: string
	) {
		const headers = new HttpHeaders({
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type,Accept,X-Access-Token,X-Key,Authorization,X-Requested-With,Origin,Access-Control-Allow-Origin,Access-Control-Allow-Credentials,content-type=multipart/*',
		});
		const options = {
			headers: headers,
			reportProgress: true,
			observe: 'events' as 'body',
		};

		const formData: FormData = new FormData();
		formData.append('file', file, fileName);

		return this.http
			.put(this.baseUrl + 'api/CivilianEducation?memberId=' + memberId, formData, options)
			.pipe(catchError(this.handleError('putUploadData', undefined)));
	}

	public putMemberCriminalBackgroundAdd(bUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberBackgroundCheck' + bUpdate,
				bUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError(
						'putMemberCriminalBackgroundAdd',
						undefined
					)
				)
			);
	}

	// public putMemberCareerEdit(cUpdate: string): Observable<HttpResponse<any>> {
	// 	return this.http
	// 		.put(this.baseUrl + 'api/member/putMemberOccupationUpdate' + cUpdate, cUpdate, { observe: 'response' })
	// 		.pipe(catchError(this.handleError('putMemberCareerEdit', undefined)));
	// }

	/**
	 * Requests for all occupations bound to a member.
	 * @param {number} memberId The identifier of the member being updated.
	 * @return {Promise<OccupationBasicDto[]>} Promise object returns an Array of Occupations.
	 */
	public getAllMemberOccupations(
		memberId: number
	): Promise<OccupationBasicDto[]> {
		const url: string = `${this.baseUrl}api/Members/${memberId}/Occupations`;

		return this.http.get<OccupationBasicDto[]>(url).toPromise();
	}

	/**
	 * Adds an Occupation to a member.
	 * @param {number} memberId The identifier of the member being updated.
	 * @param {MemberOccupationRequest} request The request containing a Member identifier and Occupation identifier.
	 * @returns Promise with no payload.
	 */
	public addOccupationToMember(
		memberId: number,
		request: MemberOccupationRequest
	) {
		const url: string = `${this.baseUrl}api/Members/${memberId}/Occupations`;

		return this.http.post(url, request).toPromise();
	}

	/**
	 * Removes an Occupation from a member.
	 * @param {number} memberId The identifier of the member being updated.
	 * @param {MemberOccupationRequest} request The request containing a Member identifier and Occupation identifier.
	 * @returns Promise with no payload.
	 */
	public removeOccupationFromMember(memberId: number, occupationId: number) {
		const url: string = `${this.baseUrl}api/Members/${memberId}/Occupations/${occupationId}`;

		return this.http.delete(url).toPromise();
	}

	public putMemberCurrentRankUpdate(rankUpdate: string) {
		return this.http.put(
			this.baseUrl + 'api/member/putMemberCurrentRankUpdate' + rankUpdate,
			rankUpdate,
			{ observe: 'response' }
		);
	}

	public putMemberRankAddEdit(rankAdd: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberRankUpdate' + rankAdd,
				rankAdd,
				{ observe: 'response' }
			)
			.pipe(
				catchError(this.handleError('putMemberRankUpdate', undefined))
			);
	}

	public putMemberDischargeDateUpdate(dischargeEdit: string) {
		return this.http
			.put(
				this.baseUrl +
					'api/member/putMemberDischargeDateUpdate' +
					dischargeEdit,
				dischargeEdit,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberDischargeDateUpdate', undefined)
				)
			);
	}

	public putMemberUnitUpdate(unitUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberUnitUpdate' + unitUpdate,
				unitUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(this.handleError('putMemberUnitUpdate', undefined))
			);
	}

	public putMemberUserNameUpdate(unUpdate: string) {
		return this.http
			.put(
				this.baseUrl + 'api/member/putMemberUserNameUpdate' + unUpdate,
				unUpdate,
				{ observe: 'response' }
			)
			.pipe(
				catchError(
					this.handleError('putMemberUserNameUpdate', undefined)
				)
			);
	}

	public putMemberDrillAdd(
		memberID: number,
		eventInstanceId: number,
		justification: string
	) {
		const query = this.globals.getParams({
			MemberID: memberID,
			EventInstanceId: eventInstanceId,
			ManuallyEnteredNotes: justification,
		});

		return this.http.put(
			this.baseUrl + 'api/EventAttendance/PutManualAttendance' + query,
			query,
			{ observe: 'response' }
		);
	}

	public deleteEmergencyContact(user: string, ecID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/Member/DeleteMemberEmergencyContact' +
					user +
					ecID
			)
			.pipe(
				catchError(
					this.handleError('deleteEmergencyContact', undefined)
				)
			);
	}

	public deleteMember(memberID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/DeleteMEmber?DeleteMemberID=' +
					memberID
			)
			.pipe(catchError(this.handleError('deleteMember', undefined)));
	}

	public deleteCivEducation(memberID: number, ceID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberCivEducation?MemberID=' +
					memberID +
					'&CivilianEducationId=' +
					ceID
			)
			.pipe(
				catchError(this.handleError('deleteCivEducation', undefined))
			);
	}

	public deleteRole(memberID: number, roleId: number) {
		return this.http.delete(
			this.baseUrl +
				'api/member/deleteMemberRole?MemberID=' +
				memberID +
				'&RoleId=' +
				roleId
		);
	}

	public deleteLicense(memberID: number, licenseType: string) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberLicense?MemberID=' +
					memberID +
					'&LicenseType=' +
					licenseType
			)
			.pipe(catchError(this.handleError('deleteLicense', undefined)));
	}

	public deletePref(memberID: number, mpID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberPreference?MemberID=' +
					memberID +
					'&MemberPreferenceID=' +
					mpID
			)
			.pipe(catchError(this.handleError('deletePref', undefined)));
	}

	public deleteImpersonate() {
		return this.http
			.delete(this.baseUrl + 'api/member/clearMemberImpersonate')
			.pipe(
				catchError(
					this.handleError('clearMemberImpersonate', undefined)
				)
			);
	}

	public deleteRank(memberID: number, mrID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberRank?MemberID=' +
					memberID +
					'&MemberRankID=' +
					mrID,
				{ observe: 'response' }
			)
			.pipe(catchError(this.handleError('deleteRank', undefined)));
	}

	public deleteMilEducation(memberID: number, meID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberMilEducation?MemberID=' +
					memberID +
					'&MilitaryEducationId=' +
					meID
			)
			.pipe(
				catchError(this.handleError('deleteMilEducation', undefined))
			);
	}

	public deleteFEMAEducation(memberID: number, meID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/member/deleteMemberFEMAEducation?MemberID=' +
					memberID +
					'&FEMAEducationId=' +
					meID
			)
			.pipe(
				catchError(this.handleError('deleteFEMAEducation', undefined))
			);
	}

	public deleteAward(memberAwardId: number, memberId: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/memberAward/RemoveHistoricalAward?memberAwardId=' +
					memberAwardId +
					'&memberId=' +
					memberId
			)
			.pipe(catchError(this.handleError('deleteAward', undefined)));
	}

	public deleteMilEducationCourse(user: string, meTypeID: number) {
		return this.http
			.delete(
				this.baseUrl +
					'api/militaryeducation?MilitaryEducationTypeId=' +
					meTypeID
			)
			.pipe(
				catchError(
					this.handleError('deleteMilEducationCourse', undefined)
				)
			);
	}

	public submitPerformanceAssessment(assessment) {
		const httpOptions = {
			headers: new HttpHeaders({
				Accept: 'application/json',
				'Content-Type': 'application/json; charset=utf-8',
			}),
		};
		const body = JSON.stringify(assessment);

		return this.http.post(
			this.baseUrl + 'api/performanceReview/Post',
			body,
			httpOptions
		);
	}

	public submitPerformanceAssessmentDelete(assessment) {
		return this.http.delete(
			this.baseUrl +
				'api/performanceReview/DeletePerformanceReview' +
				assessment,
			{ observe: 'response' }
		);
	}

	public submitPerformanceAssessmentSign(memberAssessmentID: number) {
		return this.http.put(
			this.baseUrl +
				'api/performanceReview/PutPerformanceReviewSign?MemberAssessmentID=' +
				memberAssessmentID,
			{ observe: 'response' }
		);
	}

	// not used
	// public async extractData(res: Response) {
	// 	const body: string = await res.json();

	// 	return body || {};
	// }

	// public upload(fileToUpload: any) {
	// 	const input = new FormData();

	// 	input.append("file", fileToUpload);

	// 	return this.http
	// 		.post(this.baseUrl + "api/files/upload", input)
	// 		.pipe(catchError(this.handleError('upload', 'post')));
	// }

	public memberDownload(selection: number[]) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json; charset=utf-8',
				'Content-Disposition': 'multipart/form-data',
			}),
			responseType: 'text' as 'json', // Specify the response type as 'text'
		};

		const body = {
			id: selection,
		};

		return this.http
			.post(this.baseUrl + 'api/memberdownload/Post', body, httpOptions)
			.pipe(catchError(this.handleError('memberDownload', 'post')));
	}

	//Rosters - Reports information All information should be in this area if related to a report or roster.

	//Start Rosters Section

	//  Not Used
	// public rosterReport(eventId: number): Observable<any> {
	// 	return this.http.get<any>(
	// 		this.baseUrl + 'api/roster/GetRosterReport?eventId=' + eventId
	// 	);
	// }

	public rosterDownload(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetRoster')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterHWDownload(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetHeightWeightRoster')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterRCDownload(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetRegionalCommandRoster')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterTIGDownload(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetTimeInGradeRoster')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterExpiredDL(homeBDE): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl +
					'api/roster/GetExpiredDLRoster?homeBDE=' +
					homeBDE)
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterAwardCub(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetAwardCub')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterPsoDcooc(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetPsoDcooc')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterGCMedal(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetGCMRoster')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterTxsgServiceDelta(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetTxsgServiceDelta')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterTxFaithfulService(): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl + 'api/roster/GetTxFaithfulService')
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	//added to build Training Reports
	public rosterMilitaryEducation(schoolCode): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl +
					'api/roster/GetMilitaryEducationRoster?SchoolCode=' +
					schoolCode)
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public rosterMissingMilEducation(
		schoolCode,
		mbrRank
	): Observable<string[]> {
		return this.http
			.get<string[]>(this.baseUrl +
					'api/roster/GetMissingMilEducationRoster?SchoolCode=' +
					schoolCode +
					'&acronym=' +
					mbrRank)
			.pipe(catchError(this.handleError('GetRoster', [])));
	}

	public updatePass(
		member: MemberModel,
		pass: string,
		newPass: string,
		localPasswordChange: boolean
	) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			}),
		};
		const body = JSON.stringify({
			UserName: member.userName,
			OldPassword: pass,
			NewPassword: newPass,
			LocalPasswordChange: localPasswordChange,
		});

		return (
			this.http
				.post(this.baseUrl + 'api/Password/Post', body, httpOptions)
				// .map(this.extractData)
				.pipe(catchError(this.handleError('updatePass', 'post')))
		);
	}

	// Not used
	// public getPkgMbrRel(mbrId): Observable<any> {
	// 	return this.http.get<any>(this.baseUrl + 'api/PackageMemberRel?MemberId=' + mbrId + '&packageId=123')
	// 		.pipe(catchError(this.handleError('GetRoster', [])));
	// }

	public formatMemberForDisplay(member: MemberModel): string {
		if (!member) {
			return null;
		}

		if (
			member.rank &&
			member.rank.rankType.acronym &&
			member.firstName &&
			member.lastName
		) {
			let disp = `${member.rank.rankType.acronym} ${member.firstName} ${member.lastName}`;

			if (member.suffix) {
				disp += ', ' + member.suffix;
			}

			return disp;
		}

		return null;
	}

	public static getQualifications(qualifications: number[]): string[] {
		const result: string[] = [];

		for (const q of qualifications) {
			for (let i = 0; i < qualifications.length; i++) {
				if (MemberService.dropdownList[i].item_id === q) {
					result.push(MemberService.dropdownList[i].item_text);

					break;
				}
			}
		}

		return result;
	}
}
