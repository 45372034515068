import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from './http-error-handler.service';
import { GlobalService } from './globals.service';
import { environment } from '../../environments/environment';
import { MissionCandidateSearch } from '../interfaces/missionCandidateSearch';
import { CreateOrdersRequest } from '../interfaces/orderCreateUpdate';
import { BranchComponent } from '../interfaces/branch-component';
// import { Right } from '../interfaces/right';
// import { Role } from '../interfaces/role';
// import { RoleRight } from '../interfaces/roleRight';
// import { MemberRightPut } from '../interfaces/memberRightPut';
import { OperationPayRate } from '../interfaces/OperationPayRate';
import { PayRate } from '../interfaces/payRate';
import { PayRateBatch } from '../interfaces/PayRateBatch';
import { PayRecordByPackageRequest } from '../member/pay-review/Models/PayRecordQueryRequest';
import { PayRecord, PayReviewSuggestion } from '../member/pay-review/Models';
import { MilitaryUnit } from '../interfaces/MilitaryUnit';
import { DiscrepancyReportDto } from '../management/discrepancy-report/Models/index';
import { OrdersFilter } from '../interfaces/OrdersFilter';
import { BranchType } from '../interfaces/branch-type';
import { RankType } from '../interfaces/rank-type';
import { CountyModel } from '../interfaces/CountyModel';
import { City } from '../interfaces/city';
import { ApplicantDispositionType, BloodType, MedicalScreeningTypes, RaceType } from '../interfaces/Applicant';
import { StateModel } from '../interfaces/StateModel';
import { EthnicityType } from '../interfaces/ethnicity-type';
import { Discharge } from '../interfaces/discharge-type';
import { FemaEducationType, MilitaryEducationType } from '../interfaces/education-types';
import { MilitaryFacility } from '../interfaces/MilitaryFacility';
import { Operation } from '../interfaces/operation';
import { OperationByDate, OperationType } from '../interfaces/OperationType';
import { OccupationCategory } from '../interfaces/occupation-category';
import { OperationClone } from '../interfaces/api/operation-clone';
import { NestedPackage, Package } from '../interfaces/package';
import { FTUSType } from '../interfaces/FTUSType';
import { MaintenanceBanner } from '../interfaces/maintenance-landing-banner';
import { SpecialtyQualification } from '../interfaces/specialtyQualification';
import { IOccupation } from '../interfaces/IOccupation';
import { EventType, EventTypeSimple } from '../interfaces/event-type';
import { DutyStatus } from '../interfaces/duty-status';
import { DegreeTypes } from '../interfaces/degree-types';
import { OccupationLicense } from '../interfaces/occupation-license';
import { AwardType } from '../interfaces/AwardModel';
import { MrpType } from '../interfaces/mrpType';
import { SpecialPay } from '../interfaces/specialPay';
import { MemberPreference } from '../interfaces/member-preferences';
import { MissionCandidateResponse } from '../interfaces/MissionCandidate';
import { Order } from '../interfaces/Order';
import { Record } from '../report-builder/records.model';
import { PayGrades } from '../interfaces/paygrades';
import { Role } from '../interfaces/role';
import { DrillData } from '../interfaces/MemberModel';
import { Accountability } from '../interfaces/accountability/accountability';
import { PerstatValidation } from '../interfaces/perstatValidation';
import { ColumnDefinitions } from '../interfaces/column-definitions';
import { LicenseType } from '../interfaces/LicenseType';
import { PayRateType } from '../interfaces/payRateType';
import { PayConfiguration } from '../shared/models/PayrollConfiguration';
import { MemberListItem } from '../interfaces/PaginationTabelModel';
import { PickerOperation } from '../shared/models/PickerOperation';
import { PayRateActivation } from '../interfaces/payRateActivation';
// import { start } from 'repl';

// const httpOptions = {
// 	headers: new HttpHeaders({
// 		'Content-Type': 'application/json',
// 		'Authorization': 'my-auth-token'
// 	})
// };

@Injectable({
	providedIn: 'root'
})
export class RmsService {

	private handleError: HandleError;
	private baseUrl = environment.api;

	public constructor(
		public httpErrorHandler: HttpErrorHandler,
		private http: HttpClient,
		private globals: GlobalService
	) {
		this.handleError = httpErrorHandler.createHandleError('RmsService');
	}

	public changeOrderEOM(ids: number[], eomDate: Date) {
		const eom = eomDate.toISOString().split('T')[0];
		let url = `api/Orders/change/${eom}`;
		for (let i = 0; i < ids.length; i++) {
			if (i === 0) {
				url = `${url}?ordersID=${ids[i]}`;
			} else {
				url = `${url}&ordersID=${ids[i]}`;
			}
		}
		return this.http.put(`${this.baseUrl}${url}`, 'empty body');
	}

	public resetOrderEOM(ids: number[]) {
		let url = `api/Orders/reset`;
		for (let i = 0; i < ids.length; i++) {
			if (i === 0) {
				url = `${url}?ordersID=${ids[i]}`;
			} else {
				url = `${url}&ordersID=${ids[i]}`;
			}
		}
		return this.http.put(`${this.baseUrl}${url}`, 'empty body');
	}

	public changeOrderDates(ids: number[], startDate: Date, endDate: Date) {
		const start = startDate.toISOString().split('T')[0];
		const end = endDate.toISOString().split('T')[0];
		let url = `api/Orders/change/${start}/${end}`;
		for (let i = 0; i < ids.length; i++) {
			if (i === 0) {
				url = `${url}?ordersID=${ids[i]}`;
			} else {
				url = `${url}&ordersID=${ids[i]}`;
			}
		}
		return this.http.put(`${this.baseUrl}${url}`, 'empty body');
	}

	public transfer(ids: number[], packageID: number) {
		let url = `api/Orders/change/${packageID}`;
		for (let i = 0; i < ids.length; i++) {
			if (i === 0) {
				url = `${url}?ordersID=${ids[i]}`;
			} else {
				url = `${url}&ordersID=${ids[i]}`;
			}
		}
		return this.http.put(`${this.baseUrl}${url}`, 'empty body');
	}

	public cloneOperation(cloneSourceOperationID: number, operationName: string, startDate: Date, endDate: Date): Observable<OperationClone[]> {
		const body: OperationClone = {
			cloneSourceOperationID: cloneSourceOperationID,
			operationDescription: operationName,
			startDate: startDate,
			endDate: endDate
		};
		return this.http
			.post<OperationClone[]>(`${this.baseUrl}api/Operations/Clone`, body);
	}

	private stringIsNullOrWhiteSpace(val: string): boolean {
		return val === null || val.trim() == '';
	}

	public getOperations(
		active: boolean = null,
		componentId: number = null,
		operationDescription: string = null,
		star: string = null,
		startDate: Date = null,
		endDate: Date = null,
		operationTypeId: number = null,
	): Observable<Operation[]> {
		const url = `${this.baseUrl}api/operations`;
		let params = new HttpParams();

		if (active !== null) {
			params = params.set('active', active);
		}

		if (componentId !== null && componentId > 0) {
			params = params.set('componentId', componentId);
		}

		if (!this.stringIsNullOrWhiteSpace(operationDescription)) {
			params = params.set('operationDescription', operationDescription);
		}

		if (!this.stringIsNullOrWhiteSpace(star)) {
			params = params.set('star', star);
		}

		if (startDate !== null) {
			params = params.set('startDate', startDate.toISOString().split('T')[0]);
		}

		if (endDate !== null) {
			params = params.set('endDate', endDate.toISOString().split('T')[0]);
		}

		if (operationTypeId !== null && operationTypeId > 0) {
			params = params.set('operationTypeId', operationTypeId);
		}

		return this.http.get<Operation[]>(url, { params });
	}

	public getOperationsForPicker(): Promise<PickerOperation[]> {
		return firstValueFrom(this.http.get<PickerOperation[]>(`${this.baseUrl}api/Operations/Picker`));
	}

	public getOperationsByDate(
		startDate: Date = null,
		endDate: Date = null,
		active: boolean = null
	): Observable<Operation[]> {
		const url = `${this.baseUrl}api/operations/bydate`;
		let params = new HttpParams();

		params = params.set('startDate', startDate.toISOString().split('T')[0]);

		if (active !== null) {
			params = params.set('active', active);
		}

		if (endDate !== null) {
			params = params.set('endDate', endDate.toISOString().split('T')[0]);
		}

		return this.http.get<Operation[]>(url, { params });
	}

	public getOperationsByType(OperationTypeId): Observable<Operation[]> {
		const url = `${this.baseUrl}api/operations/ByType/${OperationTypeId}`;
		const params = new HttpParams();
		return this.http.get<Operation[]>(url, { params });
	}

	public deactivateOpeartion(operationId: number) {
		const url = `${this.baseUrl}api/operations/deactivate/${operationId}`;
		const params = new HttpParams();
		return this.http.put<Operation[]>(url, { params });
	}

	public getComponents(): Observable<BranchComponent[]> {
		return this.http
			.get<BranchComponent[]>(this.baseUrl + 'api/Components');
	}

	public deleteComponent(id: number): Observable<BranchComponent[]> {
		return this.http
			.delete<BranchComponent[]>(`${this.baseUrl}api/Components/${id}`);
	}

	public postComponents(body: BranchComponent): Observable<BranchComponent[]> {
		return this.http
			.post<BranchComponent[]>(this.baseUrl + 'api/Components', body);
	}

	public putComponents(body: BranchComponent): Observable<BranchComponent[]> {
		return this.http
			.put<BranchComponent[]>(this.baseUrl + 'api/Components', body);
	}

	public getOperationTypes(): Observable<OperationType[]> {
		return this.http
			.get<OperationType[]>(this.baseUrl + 'api/OperationTypes');
	}

	public getOperationById(id: number): Observable<Operation> {
		return this.http.get<Operation>(`${this.baseUrl}api/Operations/${id}`);
	}

	public putOperation(body): Observable<Operation> {
		return this.http.put<Operation>(`${this.baseUrl}api/Operations`, body);
	}

	public ordersPDFStream(OrderID): Observable<Blob> {
		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Accept', 'application/json')
			.set('Access-Control-Allow-Origin', '*')
			.set('Access-Control-Allow-Headers', 'X-Requested-With')
			.set('Accept', 'application/pdf');
		return this.http.get(`${this.baseUrl}api/Orders/${OrderID}/PDFStream`, { headers, responseType: 'blob' });
	}

	public ordersEmail(OrderID: number) {
		return this.http.get(`${this.baseUrl}api/Orders/${OrderID}/PDFEmail`);
	}

	public postOperation(body) {
		return this.http.post(`${this.baseUrl}api/Operations`, body);
	}

	public getOperationPackages(id): Observable<Package[]> {
		return this.http.get<Package[]>(`${this.baseUrl}api/Operations/${id}/Packages`);
	}

	public deleteOperation(id): Observable<Operation> {
		return this.http.delete<Operation>(`${this.baseUrl}api/Operations/${id}`);
	}

	public getOperationNestedPackages(id): Observable<NestedPackage> {
		return this.http.get<NestedPackage>(`${this.baseUrl}api/Package/GetNestedPackages?OperationID=${id}`);
	}

	public getPackageOrders(id): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.baseUrl}api/Orders?PackageID=${id}`);
	}

	public getFTUS(): Observable<FTUSType[]> {
		return this.http
			.get<FTUSType[]>(this.baseUrl + 'api/FTUSType/GetFTUSTypes')
			.pipe(catchError(this.handleError('getFTUS', [])));
	}

	public assignFTUSToMember(memberId: number, ftusId: number): Promise<void> {
		return this.http.post<void>(`${this.baseUrl}api/Members/${memberId}/FTUS`, ftusId).toPromise();
	}

	public setMemberSADPayException(memberId: number, sadPayException: boolean): Promise<void> {
		return this.http.post<void>(`${this.baseUrl}api/Members/${memberId}/SADPayException`, sadPayException).toPromise();
	}

	public setPSOStatus(memberId: number, spoStatus: boolean): Promise<void> {
		return this.http.post<void>(`${this.baseUrl}api/Members/${memberId}/PSOStatus`, spoStatus).toPromise();
	}

	public getRankBranches(): Observable<BranchType[]> {
		return this.http
			.get<BranchType[]>(this.baseUrl + 'api/Rank/getPossibleBranchTypes')
			.pipe(catchError(this.handleError('getRankBranches', [])));
	}

	public getBranches(): Observable<BranchType[]> {
		return this.http
			.get<BranchType[]>(this.baseUrl + 'api/Branch/getBranchTypes')
			.pipe(catchError(this.handleError('getBranchTypes', [])));
	}

	public getAllBranchTypes(): Observable<BranchType[]> {
		return this.http
			.get<BranchType[]>(this.baseUrl + 'api/Branch/getAllBranchTypes')
			.pipe(catchError(this.handleError('getBranchTypes', [])));
	}

	public getTXSGBranches(): Observable<BranchType[]> {
		return this.http
			.get<BranchType[]>(this.baseUrl + 'api/Branch/getTXSGBranchTypes')
			.pipe(catchError(this.handleError('getBranchTypes', [])));
	}

	public getSpecialtyQualifications(): Observable<SpecialtyQualification[]> {
		return this.http
			.get<SpecialtyQualification[]>(`${this.baseUrl}api/SpecialtyQualification/getSpecialtyQualifications`)
			.pipe(catchError(this.handleError('getBranchTypes', [])));
	}

	public getAllUnits(): Observable<MilitaryUnit[]> {
		return this.http
			.get<MilitaryUnit[]>(this.baseUrl + 'api/personnel/getAllUnits?geoLookup=false')
			.pipe(catchError(this.handleError('getAllUnits', [])));
	}

	public getActiveUnits(): Observable<MilitaryUnit[]> {
		return this.http
			.get<MilitaryUnit[]>(this.baseUrl + 'api/personnel/getActiveUnits?geoLookup=false')
			.pipe(catchError(this.handleError('getAllUnits', [])));
	}

	public getUnitsAndStrength(active?: boolean): Observable<MilitaryUnit[]> {
		return this.http.get<MilitaryUnit[]>(this.baseUrl + 'api/personnel/getUnitsAndStrength?active=' + active);
	}

	public getAllUnitsAndStrength(active?: boolean): Observable<MilitaryUnit[]> {
		return this.http.get<MilitaryUnit[]>(this.baseUrl + 'api/personnel/getAllUnitsAndStrength?active=' + active);
	}

	public getScheduledBanners(): Observable<MaintenanceBanner[]> {
		return this.http.get<MaintenanceBanner[]>(this.baseUrl + 'api/GetScheduledBanners');
		// .pipe(
		// 	catchError(
		// 		this.handleError('GetScheduledBanners', undefined)
		// 	)
		// );
	}

	public getAllBranches(): Observable<BranchType[]> {
		return this.http
			.get<BranchType[]>(this.baseUrl + 'api/branch/getAllBranchTypes?All=1')
			.pipe(catchError(this.handleError('getAllBranchTypes', [])));
	}

	public getCareers(): Observable<IOccupation[]> {
		return this.http
			.get<IOccupation[]>(this.baseUrl + 'api/occupation/getPossibleOccupations')
			.pipe(catchError(this.handleError('getCareers', [])));
	}

	public getOccupationCategories(): Observable<OccupationCategory[]> {
		return this.http
			.get<OccupationCategory[]>(this.baseUrl + 'api/occupation/getOccupationCategories')
			.pipe(catchError(this.handleError('getOccupationCategories', [])));
	}

	public deleteOccupationCategory(OccupationCategoryId: number): Observable<number> {
		return this.http.delete<number>(this.baseUrl + 'api/occupation/DeleteOccupationCategory?OccupationCategoryId=' + OccupationCategoryId);
	}

	public postOccupationCategory(categoryName: string, occupationCategoryId: number) {

		const httpOptions = {
			headers: new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8'
			})
		};

		const body = JSON.stringify({ CategoryName: categoryName, OccupationCategoryId: occupationCategoryId });

		return this.http.post(this.baseUrl + 'api/occupation/Post', body, httpOptions);
	}

	public postOccupation(occupationId: number, occupationName: string, occupationCategoryId: number) {

		const httpOptions = {
			headers: new HttpHeaders({
				'Accept': 'application/json',
				'Content-Type': 'application/json; charset=utf-8'
			})
		};

		const body = JSON.stringify({ OccupationId: occupationId, OccupationName: occupationName, OccupationCategoryId: occupationCategoryId });

		return this.http.post(this.baseUrl + 'api/occupation/PostOccupation', body, httpOptions);
	}

	public deleteOccupation(OccupationId: number) {
		return this.http.delete(this.baseUrl + 'api/occupation/DeleteOccupation?OccupationId=' + OccupationId);
	}

	public getRbViews(): Observable<{ name: string }[]> {
		return this.http
			.get<{ name: string }[]>(this.baseUrl + 'api/ReportBuilder/Views')
			.pipe(catchError(this.handleError('getRbViews', [])));
	}

	public getSADProcs(): Observable<{ name: string }[]> {
		return this.http
			.get<{ name: string }[]>(this.baseUrl + 'api/ReportBuilder/SADReports')
			.pipe(catchError(this.handleError('getSADProcs', [])));
	}

	public getRbColumns(viewname): Observable<ColumnDefinitions[]> {
		return this.http
			.get<ColumnDefinitions[]>(`${this.baseUrl}api/ReportBuilder/Columns?viewName=${viewname}`)
			.pipe(catchError(this.handleError('getRbColumns', [])));
	}

	public getRbRows(viewname): Observable<Record[]> {
		return this.http
			.get<Record[]>(`${this.baseUrl}api/ReportBuilder/Rows?viewName=${viewname}`)
			.pipe(catchError(this.handleError('getRbRows', [])));
	}

	public getFilteredRows(reportName, operationID,packageId): Observable<Record[]> {

		let url = this.baseUrl + `api/GetReportDataByFilter?reportName=${reportName}&operationId=${operationID}`;
		if (packageId && packageId > "") url = url + `&packageId=${packageId}`;

		return this.http
			.get<Record[]>(url)
			.pipe(catchError(this.handleError('getRbRows', [])));
	}

	public getEvents(component = null, name = null, active: boolean = true): Observable<EventType[]> {
		let url = this.baseUrl + `api/Event/getEvents?Active=${active}`;
		if (component && component > "") url = url + `&Component=${component}`;
		if (name && component > "") url = url + `&Name=${name}`;
		//if (startDate) url = url + `&StartDate=${startDate}`
		//if (endDate) url = url + `&EndDate=${endDate}`
		return this.http.get<EventType[]>(url)
			.pipe(catchError(this.handleError('getEvents', [])));
	}

	public getEventTypes(): Observable<EventTypeSimple[]> {
		return this.http
			.get<EventTypeSimple[]>(this.baseUrl + 'api/EventType/getEventTypes')
			.pipe(catchError(this.handleError('getEventTypes', [])));
	}

	public getEventTypeFilters(): Observable<EventType[]> {
		return this.http
			.get<EventType[]>(this.baseUrl + 'api/EventType/getEventTypeFilters?Filter=true')
			.pipe(catchError(this.handleError('getEventTypeFilters', [])));
	}

	public getEventInstances(): Observable<EventType[]> {
		return this.http
			.get<EventType[]>(this.baseUrl + 'api/EventInstance/getEventInstances')
			.pipe(catchError(this.handleError('getEventInstances', [])));
	}

	public getEventByID(EventId: number): Observable<EventType[]> {
		return this.http
			.get<EventType[]>(this.baseUrl + `api/Event/getEventByID?EventId=${EventId}`)
			.pipe(catchError(this.handleError('getEventInstances', [])));
	}

	public searchEventInstances(typeName: string, selectedUnitID: number, active?: boolean): Observable<EventType[]> {
		console.log(typeName);
		const params = {
			TypeName: typeName || 'Drill', // Always include TypeName field, even if it's an empty string
			UnitID: selectedUnitID,
			Active: active
		};

		const query = this.globals.getParams(params);

		return this.http
			.get<EventType[]>(this.baseUrl + 'api/EventInstance/searchEventInstances' + query)
			.pipe(catchError(this.handleError('getEventInstancesByType', [])));
	}

	public processPayClose(operationId: number, startDate: Date, endDate:  Date, payCloseEmail: string) {
		const payload = { operationId, startDate, endDate, payCloseEmail };

		return this.http.post(this.baseUrl + 'api/payclose/processPayClose', payload).toPromise();
	}

	public getPayConfigurationByOperationId(operationId: number): Promise<PayConfiguration> {
		return this.http
			.get<PayConfiguration>(`${this.baseUrl}api/payconfiguration/ByOperationId/${operationId}`)
			.toPromise();
	}

	public getPaygrades(): Observable<PayGrades[]> {
		return this.http
			.get<PayGrades[]>(this.baseUrl + 'api/paygrade/getPaygrades')
			.pipe(catchError(this.handleError('getPaygrades', [])));
	}

	public getPerstat(packageId: number, dutyDate: Date): Observable<Accountability[]> {
		return this.http
			.get<Accountability[]>(`${this.baseUrl}api/perstat/${packageId}/${dutyDate.toISOString().split('T')[0]}`);
	}

	public getPackageReport(packageId: number, startDate: Date, endDate: Date) {
		return this.http
			.get(`${this.baseUrl}api/perstatreport/package/${packageId}/${startDate.toISOString().split('T')[0]}/${endDate.toISOString().split('T')[0]}`);
	}

	public getOperationReport(operationId: number, startDate: Date, endDate: Date) {
		return this.http
			.get(`${this.baseUrl}api/perstatreport/operation/${operationId}/${startDate.toISOString().split('T')[0]}/${endDate.toISOString().split('T')[0]}`);
	}

	public getMemberReport(memberId: number, startDate: Date, endDate: Date): Observable<Accountability[]> {
		return this.http
			.get<Accountability[]>(`${this.baseUrl}api/perstatreport/member/${memberId}/${startDate.toISOString().split('T')[0]}/${endDate.toISOString().split('T')[0]}`);
	}

	//getServiceMemberNames
	// public getServiceMemberNames(query): Observable<any[]> {
	// 	return this.http.get<any>(`${this.baseUrl}api/members?query=${query}`);
	// }
	public look4Member(term): Observable<[string[], string[]]> {
		return this.http.get<[string[], string[]]>(`${this.baseUrl}api/Member/Search?term=` + term);
	}

	public putPerstat(body) {
		return this.http.put(`${this.baseUrl}api/perstat`, body);
	}

	public getPerstatValidation(packageId: number, dutyDate: Date): Observable<PerstatValidation[]> {
		return this.http.get<PerstatValidation[]>(`${this.baseUrl}api/perstatvalidation/${packageId}/${dutyDate.toISOString().split('T')[0]}`);
	}

	public postPerstatValidation(body) {
		return this.http.post(`${this.baseUrl}api/perstatvalidation`, body);
	}

	public async getPayReviewSuggestions(operationId: number, dutyDate: Date = null): Promise<PayReviewSuggestion[]> {
		return this.http.get<PayReviewSuggestion[]>(`${this.baseUrl}api/PaySelections/Operation/${operationId}/PayReviewSuggestions${(dutyDate ? `?dutyDate=${dutyDate.toJSON()}` : '')}`).toPromise();
	}

	public getDutyStatusTypes(): Observable<DutyStatus[]> {
		return this.http
			.get<DutyStatus[]>(`${this.baseUrl}api/DutyStatusType`)
			.pipe(catchError(this.handleError('getDutyStatusType', [])));
	}

	public getRanks(branch: BranchType): Observable<RankType[]> {
		return this.http
			.get<RankType[]>(this.baseUrl + 'api/rank/getAllRanks?BranchTypeID=' + branch.branchTypeID)
			.pipe(catchError(this.handleError('getRanks', [])));
	}

	public getRanksAllBranches(): Observable<RankType[]> {
		return this.http
			.get<RankType[]>(`${this.baseUrl}api/Rank/getRanks`)
			.pipe(catchError(this.handleError('getRanks', [])));
	}

	public getDegreeTypes(): Observable<DegreeTypes[]> {
		return this.http
			.get<DegreeTypes[]>(this.baseUrl + 'api/civilianeducation/getDegreeTypes')
			.pipe(catchError(this.handleError('getDegreeTypes', [])));
	}

	public getCounties(): Observable<CountyModel[]> {
		return this.http
			.get<CountyModel[]>(this.baseUrl + 'api/county/getCounties')
			.pipe(catchError(this.handleError('getCounties', [])));
	}

	public getCities(county): Observable<City[]> {
		return this.http
			.get<City[]>(this.baseUrl + 'api/member/getCities?county=' + county)
			.pipe(catchError(this.handleError('getCities', [])));
	}

	public putPackageSpecialPay() {
		return this.http.put(`${this.baseUrl}api/PackageSpecialPay`, 'empty');
	}

	public getBloodTypes(): Observable<BloodType[]> {
		return this.http
			.get<BloodType[]>(this.baseUrl + 'api/BloodType/getBloodTypes')
			.pipe(catchError(this.handleError('getBloodTypes', [])));
	}

	public getStates(): Observable<StateModel[]> {
		return this.http
			.get<StateModel[]>(this.baseUrl + 'api/State/getStates')
			.pipe(catchError(this.handleError('getStates', [])));
	}

	// Not sure this is being used
	public getDdcs() {
		return this.http
			.get(this.baseUrl + 'api/ddc/getDDCsAndStrength')
			.pipe(catchError(this.handleError('getDdcs', [])));
	}

	public getRaceTypes(): Observable<RaceType[]> {
		return this.http
			.get<RaceType[]>(this.baseUrl + 'api/Race/getRaceTypes')
			.pipe(catchError(this.handleError('getRaceTypes', [])));
	}

	public geEthnicityTypes(): Observable<EthnicityType[]> {
		return this.http
			.get<EthnicityType[]>(this.baseUrl + 'api/EthnicityType')
			.pipe(catchError(this.handleError('geEthnicityTypes', [])));
	}

	public getMedicalScreeningTypes(): Observable<MedicalScreeningTypes[]> {
		return this.http
			.get<MedicalScreeningTypes[]>(this.baseUrl + 'api/medicalScreeningType/getMedicalScreeningTypes')
			.pipe(catchError(this.handleError('getMedicalScreeningTypes', [])));
	}

	public getLicenseOccupationTypes(): Observable<OccupationLicense[]> {
		return this.http
			.get<OccupationLicense[]>(this.baseUrl + 'api/LicenseOccupationType/getLicenseOccupationTypes')
			.pipe(catchError(this.handleError('getLicenseOccupationTypes', [])));
	}

	public getDischargeTypes(): Observable<Discharge[]> {
		return this.http
			.get<Discharge[]>(this.baseUrl + 'api/dischargeType/getDischargeTypes')
			.pipe(catchError(this.handleError('getDischargeTypes', [])));
	}

	public getMilitaryEducationTypes(): Observable<MilitaryEducationType[]> {
		return this.http
			.get<MilitaryEducationType[]>(this.baseUrl + 'api/militaryEducation/getMilitaryEducationTypes')
			.pipe(catchError(this.handleError('getMilitaryEducationTypes', [])));
	}

	public getFEMAEducationTypes(): Observable<FemaEducationType[]> {
		return this.http
			.get<FemaEducationType[]>(this.baseUrl + 'api/femaEducation/getFEMAEducationTypes')
			.pipe(catchError(this.handleError('getFEMAEducationTypes', [])));
	}

	public getAwardTypes(): Observable<AwardType[]> {
		return this.http
			.get<AwardType[]>(this.baseUrl + 'api/awardType/getAwardTypes')
			.pipe(catchError(this.handleError('getAwardTypes', [])));
	}

	public getActiveAwardTypes(): Observable<AwardType[]> {
		return this.http
			.get<AwardType[]>(this.baseUrl + 'api/awardType/getActiveAwardTypes')
			.pipe(catchError(this.handleError('getActiveAwardTypes', [])));
	}

	public getLicenseTypes(): Observable<LicenseType[]> {
		return this.http
			.get<LicenseType[]>(this.baseUrl + 'api/license/getLicenseTypes')
			.pipe(catchError(this.handleError('getLicenseTypes', [])));
	}

	public getMilitaryFacilities(): Observable<MilitaryFacility[]> {
		return this.http.get<MilitaryFacility[]>(this.baseUrl + 'api/militaryFacility/getFacilities');
	}
	// Not used
	// public getActivities(): Observable<any> {

	// 	return this.http.get(this.baseUrl + 'api/role/GetActivities');
	// }

	public getOrders(eventInstanceId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.baseUrl}api/Orders?EventInstanceID=${eventInstanceId}`);
	}

	public getOrdersById(orderId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.baseUrl}api/Orders?OrderID=${orderId}`);
	}

	public getOrdersByMemberId(memberId: number): Observable<Order[]> {
		return this.http.get<Order[]>(`${this.baseUrl}api/Orders?MemberID=${memberId}`);
	}

	public getActiveOrders(ordersFilter: OrdersFilter): Observable<Order[]> {
		const url = `${this.baseUrl}api/Orders/GetActiveOrders`;
		let params = new HttpParams();

		if (ordersFilter.MemberID !== null) {
			params = params.set("memberID", ordersFilter.MemberID);
		}

		if (ordersFilter.StartDate !== null) {
			params = params.set("startDate", ordersFilter.StartDate.toLocaleString());
		}

		if (ordersFilter.EndDate !== null) {
			params = params.set("endDate", ordersFilter.EndDate.toLocaleString());
		}

		if (ordersFilter.OrdersID !== null) {
			params = params.set("ordersID", ordersFilter.OrdersID);
		}

		return this.http.get<Order[]>(url, { params });
	}

	// public getPackageList(eventInstanceId: number): Observable<any>  {
	// 	return this.http.get(`${this.baseUrl}api/Package?EventInstanceID=${eventInstanceId}`);
	// }

	public deleteOrder(id) {
		return this.http.delete(`${this.baseUrl}api/Orders/DeleteSingleOrder?OrderID=${id}`);
	}

	public revokeOrders(ids: number[]) {
		let url = 'api/Orders/revoke';

		for (let i = 0; i < ids.length; i++) {
			if (i === 0) {
				url = `${url}?ordersID=${ids[i]}`;
			} else {
				url = `${url}&ordersID=${ids[i]}`;
			}
		}
		return this.http.put<Order[]>(`${this.baseUrl}${url}`, 'empty body');
	}

	public getOperationDetails(operationId: number): Observable<Operation> {
		return this.http.get<Operation>(`${this.baseUrl}api/Operations/${operationId}`);
	}

	public getOperationPackageList(operationId: number, dutyDate?: string): Observable<Package[]> {
		return this.http.get<Package[]>(`${this.baseUrl}api/Operations/${operationId}/packages/${dutyDate ? dutyDate : ''}`);
	}

	public getPayOperationPackageList(operationId: number, dutyDate?: string): Observable<Package[]> {
		return this.http.get<Package[]>(`${this.baseUrl}api/Operations/${operationId}/paypackages/${dutyDate ? dutyDate : ''}`);
	}

	/*  Dashboard Management Operations   */

	/* Dashboard => CRUD for permissions */

	// public getRights(): Observable<any> {
	// 	return this.http.get(this.baseUrl + 'api/permissions/right');
	// }

	// public postRight(right: Right): Observable<any> {
	// 	return this.http.post(this.baseUrl + 'api/permissions/right', right);
	// }
	// public putRight(right: Right): Observable<any> {
	// 	return this.http.put(this.baseUrl + 'api/permissions/right', right);
	// }
	// public deleteRight(RightID: number): Observable<any> {
	// 	return this.http.delete(`${this.baseUrl}api/permissions/right/${RightID}`);
	// }

	/* Dashboard => CRUD for limits */

	// public getLimits(): Observable<any> {
	// 	return this.http.get(this.baseUrl + 'api/permissions/limit');
	// }

	// public postLimit(limit: Limit): Observable<any> {
	// 	return this.http.post(this.baseUrl + 'api/permissions/limit', limit);
	// }

	// public putLimit(limit: Limit): Observable<any> {
	// 	return this.http.put(this.baseUrl + 'api/permissions/limit', limit);
	// }

	// public deleteLimit(limitID: number): Observable<any> {
	// 	return this.http.delete(`${this.baseUrl}api/permissions/limit/${limitID}`);
	// }

	/* Dashboard operations for permissions assignment related with specific SM id */
	// public putMemberRight(memberRightPut: MemberRightPut): Observable<any> {
	// 	return this.http.put(`${this.baseUrl}api/permissions/memberright`, memberRightPut);
	// }

	// /* Dasboard for Role CRUD methods */
	// public getPermissionsRoles(): Observable<any> {
	// 	return this.http.get(this.baseUrl + 'api/permissions/role');
	// }

	// public postPermissionsRole(role: Role): Observable<any> {
	// 	return this.http.post(this.baseUrl + 'api/permissions/role', role);
	// }

	// public putPermissionsRole(role: Role): Observable<any> {
	// 	return this.http.put(this.baseUrl + 'api/permissions/role', role);
	// }

	// public deletePermissionsRole(RoleID: number): Observable<any> {
	// 	return this.http.delete(`${this.baseUrl}api/permissions/role/${RoleID}`);
	// }



	/* Dasboard functional related for role - permissions relations */

	//Listing role_right_id, role_id, rightId (permission id)
	// public getRoleRights(): Observable<any> {
	// 	return this.http.get(this.baseUrl + 'api/permissions/roleright');
	// }

	// public postRoleRights(roleRight: RoleRight): Observable<any> {
	// 	return this.http.post(this.baseUrl + 'api/permissions/roleright', roleRight);
	// }

	// public deleteRoleRights(roleID: number, rightID): Observable<any> {
	// 	return this.http.delete(`${this.baseUrl}api/permissions/roleright/${roleID}/${rightID}`);
	// }

	/* Others */
	// public getLimitOptions(limitOption: string): Observable<any> {
	// 	return this.http.get(`${this.baseUrl}api/permissions/limitoptions/${limitOption}`);
	// }

	/* ..::  Functional Related Calls ::.. */

	// public getMemberLimits(MemberID: number): Observable<any> {
	// 	return this.http.get(`${this.baseUrl}api/permissions/limit/${MemberID}`);
	// }

	public deletePackage(packageId: number) {
		return this.http.delete(`${this.baseUrl}api/Package/${packageId}`);
	}

	public SearchMRPTypes(): Observable<MrpType[]> {
		return this.http.get<MrpType[]>(this.baseUrl + 'api/MRPType/SearchMRPTypes');
	}

	public getMRPTypes(): Observable<MrpType[]> {
		return this.http.get<MrpType[]>(this.baseUrl + 'api/MRPType/GetMRPTypes');
	}

	public getMRPType(id): Observable<MrpType[]> {
		return this.http.get<MrpType[]>(`${this.baseUrl}api/MRPType/GetMRPType?MrpTypeId=${id}`);
	}

	public DeleteMRPType(MRPTypeId) {
		return this.http.delete(this.baseUrl + 'api/MRPType/DeleteMRPType?MRPTypeId=' + MRPTypeId);
	}

	public GetMissionCandidates(search: MissionCandidateSearch): Observable<MissionCandidateResponse[]> {
		let url = `${this.baseUrl}api/Member/MissionCandidate?packageId=${search.packageId}`;
		let idx = 0;

		if (search.Action) {
			idx++;
			url = this.appendParam(idx, '&Action', search.Action, url);
		}

		if (search.countiesString && search.countiesString > '') {
			idx++;
			url = this.appendParam(idx, '&countiesString', search.countiesString, url);
		}
		if (search.unitsString && search.unitsString > '') {
			idx++;
			url = this.appendParam(idx, '&unitsString', search.unitsString, url);
		}
		if (search.specialtyString && search.specialtyString > '') {
			idx++;
			url = this.appendParam(idx, '&specialtyString', search.specialtyString, url);
		}
		if (search.componentString && search.componentString > '') {
			idx++;
			url = this.appendParam(idx, '&componentString', search.componentString, url);
		}
		if (search.lastName && search.lastName > '') {
			idx++;
			url = this.appendParam(idx, '&lastName', search.lastName, url);
		}
		if (search.firstName && search.firstName > '') {
			idx++;
			url = this.appendParam(idx, '&firstName', search.firstName, url);
		}

		if (search.edipi && search.edipi > '') {
			idx++;
			url = this.appendParam(idx, '&edipi', search.edipi, url);
		}

		if (search.LicenseIdNumber && search.LicenseIdNumber > '') {
			idx++;
			url = this.appendParam(idx, '&LicenseIdNumber', search.LicenseIdNumber, url);
		}

		if (search.ranksString && search.ranksString > '') {
			idx++;
			url = this.appendParam(idx, '&RanksString', search.ranksString, url);
		}

		if (search.mos && search.mos > '') {
			idx++;
			url = this.appendParam(idx, '&MOS', search.mos, url);
		}

		if (search.city && search.city > '') {
			idx++;
			url = this.appendParam(idx, '&City', search.city, url);
		}

		if (search.OnOrders === true) {
			idx++;
			url = this.appendParam(idx, '&OnOrders', 'true', url);
		} else {
			idx++;
			url = this.appendParam(idx, '&OnOrders', 'false', url);
		}

		if (search.memberID) {
			idx++;
			url = this.appendParam(idx, '&memberID', search.memberID, url);
		}

		if (search.SocialNumber) {
			idx++;
			url = this.appendParam(idx, '&SocialNumber', search.SocialNumber, url);
		}

		if (search.active) {
			idx++;
			url = this.appendParam(idx, '&Active', search.active, url);
		}

		return this.http.get<MissionCandidateResponse[]>(url);
	}

	public GetMemberSearch(search: MissionCandidateSearch): Observable<MemberListItem[]> {
		let url = `${this.baseUrl}api/Member/MemberSearch?packageId=4`;
		let idx = 0;

		if (search.Action) {
			idx++;
			url = this.appendParam(idx, '&Action', search.Action, url);
		}

		if (search.componentString && search.componentString > '') {
			idx++;
			url = this.appendParam(idx, '&componentString', search.componentString, url);
		}
		if (search.lastName && search.lastName > '') {
			idx++;
			url = this.appendParam(idx, '&lastName', search.lastName.trim(), url);
		}
		if (search.firstName && search.firstName > '') {
			idx++;
			url = this.appendParam(idx, '&firstName', search.firstName.trim(), url);
		}
		if (search.LicenseIdNumber && search.LicenseIdNumber > '') {
			idx++;
			url = this.appendParam(idx, '&LicenseIdNumber', search.LicenseIdNumber.trim(), url);
		}
		if (search.edipi && search.edipi > '') {
			idx++;
			url = this.appendParam(idx, '&edipi', search.edipi.trim(), url);
		}

		if (search.ranksString && search.ranksString > '') {
			idx++;
			url = this.appendParam(idx, '&RanksString', search.ranksString.trim(), url);
		}
		if (search.memberID) {
			idx++;
			url = this.appendParam(idx, '&memberID', search.memberID, url);
		}

		if (search.SocialNumber) {
			idx++;
			url = this.appendParam(idx, '&SocialNumber', search.SocialNumber.trim(), url);
		}
		if (search.active) {
			idx++;
			url = this.appendParam(idx, '&active', search.active, url);
		}

		if (search.screening) {
			idx++;
			url = this.appendParam(idx, '&screening', search.screening, url);
		}
		if (search.suspension && search.screening > '') {
			idx++;
			url = this.appendParam(idx, '&suspension', search.suspension, url);
		}

		if (search.career) {
			idx++;
			url = this.appendParam(idx, '&career', search.career, url);
		}
		if (search.unitsString) {
			idx++;
			url = this.appendParam(idx, '&unitsString', search.unitsString, url);
		}
		if (search.countiesString) {
			idx++;
			url = this.appendParam(idx, '&countiesString', search.countiesString, url);
		}

		return this.http.get<MemberListItem[]>(url);
	}

	public appendParam(idx: number, paramName: string, paramValue: string | number, url: string) {
		if (idx === 1) {
			url = `${url}${paramName}=${paramValue}`;
			return url;
		} else {
			url = `${url}&${paramName}=${paramValue}`;
			return url;
		}
	}

	public PutMRPType(MRPTypeId, MRP) {
		return this.http.put(this.baseUrl + 'api/MRPType/PutMRPType', MRP);
	}

	public PostMRPType(MRP) {
		return this.http.post(this.baseUrl + 'api/MRPType/PostMRPType', MRP);
	}

	public GetPayRateTypes(): Observable<PayRateType[]> {
		return this.http.get<PayRateType[]>(this.baseUrl + 'api/PayRateTypes');
	}

	public GetPayRates(): Observable<PayRate[]> {
		return this.http.get<PayRate[]>(this.baseUrl + 'api/PayRates');
	}

	public GetPayRate(id): Observable<PayRate> {
		return this.http.get<PayRate>(`${this.baseUrl}'api/PayRates/${id}`);
	}

	public DeletePayRate(id){
		return this.http.delete(`${this.baseUrl}api/PayRates/${id}`);
	}

	public PutPayRate(id, payRate) {
		return this.http.put(`${this.baseUrl}api/PayRates/${id}`, payRate);
	}

	public PostPayRate(payRate) {
		return this.http.post(this.baseUrl + 'api/PayRates', payRate);
	}

	public ImportPayRates(formData: FormData) {
		return this.http.post(this.baseUrl + 'api/PayRates', formData).toPromise();
	}

	public GetPayRatesForOperation(operationId: number): Promise<PayRate[]> {
		return this.http.get<PayRate[]>(`${this.baseUrl}api/Operations/${operationId}/PayRates`).toPromise();
	}

	public SavePayActivations(payRates: PayRateActivation[]): Promise<object> {
		return this.http.post(`${this.baseUrl}api/PayRates/BulkActivation`, payRates).toPromise();
	}

	public AssignPayRateToOperationAsync(operationPayRate: OperationPayRate): Promise<object> {
		return this.http.post(this.baseUrl + 'api/Operations/AssignPayRate', operationPayRate).toPromise();
	}

	public ActivateDeactivePayRateByOperationId(operationPayRate: OperationPayRate): Promise<object> {
		return this.http.post(this.baseUrl + 'api/Operations/TogglePayRate', operationPayRate).toPromise();
	}

	public RemovePayRateFromOperation(operationPayRate: OperationPayRate): Promise<object> {
		return this.http.post(this.baseUrl + 'api/Operations/RemovePayRate', operationPayRate).toPromise();
	}

	public AddPayRateBatchToOperation(batch: PayRateBatch) {
		return this.http.post(this.baseUrl + 'api/Operations/AddPayRateBatch', batch).toPromise();
	}

	// public GetPayRecordsByPackage(query: PayRecordQueryRequest) {
	// 	let queryParams = query.memberIds.reduce(
	// 		(acc: HttpParams, curr: number) => acc.append('memberIds', curr),
	// 		new HttpParams()
	// 	);

	// 	queryParams = queryParams.set('packageId', query.packageId);
	// 	queryParams = queryParams.set('date', query.date);

	// 	return this.http.get<PayRecord[]>(`${this.baseUrl}api/PayRecords?${queryParams.toString()}`).toPromise();
	// }

	public GetPayRecordsByPackage(request: PayRecordByPackageRequest): Promise<PayRecord[]> {
		return Promise.resolve(firstValueFrom(this.http.post<PayRecord[]>(`${this.baseUrl}api/PayRecords/ByPackage`, request)));
	}

	public CreatePayRecords(payRecords: PayRecord[]): Promise<PayRecord[]> {
		return this.http.post<PayRecord[]>(this.baseUrl + 'api/PayRecords', payRecords).toPromise();
	}

	public GetSpecialPay(): Observable<SpecialPay[]> {
		return this.http.get<SpecialPay[]>(this.baseUrl + 'api/SpecialPay/GetSpecialPay');
	}

	public GetSpecialPays(): Observable<SpecialPay[]> {
		return this.http.get<SpecialPay[]>(this.baseUrl + 'api/SpecialPay/GetSpecialPays');
	}

	public PackageSpecialPay(id: number): Observable<SpecialPay[]> {
		return this.http.get<SpecialPay[]>(`${this.baseUrl}api/PackageSpecialPay?PackageId=${id}`);
	}

	public DeleteSpecialPay(id) {
		return this.http.delete(this.baseUrl + 'api/SpecialPay/DeleteSpecialPay?SpecialPayId=' + id);
	}

	public PutSpecialPay(id, specialPay) {
		return this.http.put(this.baseUrl + 'api/SpecialPay/PutSpecialPay', specialPay);
	}

	public DeletePackageSpecialPay(id) {
		return this.http.delete(`${this.baseUrl}api/PackageSpecialPay/${id}`);
	}

	public PutPackageSpecialPay(PackageId, SpecialPayId) {
		const headers = new HttpHeaders()
			.set('Content-Type', 'application/json');
		return this.http.put(`${this.baseUrl}api/SpecialPay/PutSpecialPay?PackageId=${PackageId}&SpecialPayId=${SpecialPayId}`, '{}', { headers });
	}


	// public getRoleActivityMapping(roleId: number): Observable<any[]> {
	// 	return this.http.get<any>(`${this.baseUrl}api/role/getRoleActivityMapping?roleId=${roleId}`);
	// }

	// public mapActivitiesToRole(roleName: string, roleDescription: string, activityMap: {}, roleID?: number) {
	// 	const activityMapArray = [];
	// 	for (const activityID in activityMap) {

	// 		activityMapArray.push({ ActivityID: parseInt(activityID), ModifierID: activityMap[activityID] });
	// 	}

	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Accept': 'application/json',
	// 			'Content-Type': 'application/json; charset=utf-8'
	// 		})
	// 	};
	// 	const body = JSON.stringify({ RoleID: roleID, RoleName: roleName, RoleDescription: roleDescription, MappedActivities: activityMapArray });

	// 	return this.http.post(this.baseUrl + 'api/role/Post', body, httpOptions);
	// }

	// public deleteRole(roleID: number): Observable<any> {
	// 	return this.http.delete(this.baseUrl + 'api/role/DeleteRole?RoleID=' + roleID);
	// }

	public getPreferences(): Observable<MemberPreference[]> {
		return this.http
			.get<MemberPreference[]>(this.baseUrl + 'api/memberPreferenceOptions/getMemberPreferences')
			.pipe(catchError(this.handleError('getPreferences', [])));
	}

	// Not used
	// public getInitialUsername(firstName, lastName): Observable<any[]> {
	// 	return this.http
	// 		.get<any>(this.baseUrl + 'api/userName/get?FirstName=' + firstName + '&LastName=' + lastName)
	// 		.pipe(catchError(this.handleError('getInitialUsername', [])));
	// }

	// Not used
	// public getEmailTemplate(): Observable<any[]> {
	// 	return this.http
	// 		.get<any>(this.baseUrl + 'api/emailtemplate/getTemplates')
	// 		.pipe(catchError(this.handleError('getEmailTemplate', [])));
	// }

	public getRecruiterAwards(awardLev: number, selPeriod: number): Observable<AwardType[]> {
		return this.http
			.get<AwardType[]>(this.baseUrl + 'api/recruiter/getAwards?AwrdLev=' + awardLev + '&selectedPeriod=' + selPeriod)
			.pipe(catchError(this.handleError('getRecruiterAwards', [])));
	}

	public getUnapprovedActivities(): Observable<DrillData[]> {
		return this.http.get<DrillData[]>(this.baseUrl + 'api/EventInstance/GetUnapprovedActivities');
	}

	public getNestedPackages(id: number) {
		return this.http.get(`${this.baseUrl}api/Package/GetNestedPackages?EventInstanceId=${id}`);
	}

	public getPackage(id: number): Observable<Package> {
		return this.http.get<Package>(`${this.baseUrl}api/Package?PackageId=${id}`);
	}

	public putPackage(pkg) {
		const url = `${this.baseUrl}api/Package`;
		return this.http.put(url, pkg);
	}

	public putApproveActivity(ActivityId: number, ActivityType: number, Notes: string, Approve: boolean) {
		const query = this.globals.getParams({
			ActivityId: ActivityId,
			ActivityType: ActivityType,
			Notes: Notes,
			Approve: Approve
		});

		return this.http.put(this.baseUrl + 'api/EventInstance/PutApproveActivity' + query, {
			observe: 'response'
		});
	}

	public toggleEventActive(event: EventType) {
		return this.http
			.put(this.baseUrl + 'api/Event/putEventActive?EventId=' + event.eventId, event.eventId)
			.pipe(catchError(this.handleError('toggleEventActive', undefined)));
	}

	public toggleTypesActive(event: MilitaryEducationType) {
		return this.http
			.put(
				`${this.baseUrl}api/militaryEducation/putMilitaryEducationCourseActive?MilitaryEducationTypeId=${event.militaryEducationTypeId}`,
				event.militaryEducationTypeId
			)
			.pipe(catchError(this.handleError('toggleTypesActive', undefined)));
	}

	public toggleFEMATypesActive(event: FemaEducationType) {
		return this.http
			.put(
				`${this.baseUrl}api/femaEducation/putFEMAEducationCourseActive?femaEducationTypeId=${event.femaEducationTypeId}`,
				event.femaEducationTypeId
			)
			.pipe(catchError(this.handleError('toggleTypesActive', undefined)));
	}


	public toggleAwardType(active: boolean, type: AwardType) {
		return this.http
			.put(this.baseUrl + 'api/awardType/PutAwardTypeActive?AwardTypeId=' + type.awardTypeId + '&Active=' + active, type.awardTypeId)
			.pipe(catchError(this.handleError('toggleAwardType', undefined)));
	}

	public retireAwardType(type: AwardType) {
		return this.http
			.put(this.baseUrl + 'api/awardType/retire?AwardTypeId=' + type.awardTypeId, type.awardTypeId)
			.pipe(catchError(this.handleError('retireAwardType', undefined)));
	}

	public putMilitaryEducationAdd(branch: string, school: string, schoolCode: string) {
		return this.http
			.put(this.baseUrl + 'api/militaryEducation/putMilitaryEducationCourse?BranchName=' + branch + '&School=' + school + '&SchoolCode=' + schoolCode, school, { observe: 'response' })
			.pipe(catchError(this.handleError('putMilitaryEducationAdd', undefined)));
	}

	public putFEMAEducationAdd(femaEducationTypeId: number, branch: BranchType, school: string, schoolCode: string) {
		return this.http
			.put(this.baseUrl + 'api/femaEducation/putFEMAEducationCourse?femaEducationTypeId=' + femaEducationTypeId + '&branchName=' + branch.name + '&school=' + school + '&schoolCode=' + schoolCode, school, { observe: 'response' })
			.pipe(catchError(this.handleError('putFEMAEducationAdd', undefined)));
	}

	public putNewAwardType(name: string, description: string, dateEstablished: Date, branchTypeID: number) {
		const dateEst = new Date(dateEstablished).toJSON();
		const qs = `?name=${name}&description=${description}&dateEstablished=${dateEst}&BranchTypeId=${branchTypeID}`;

		return this.http
			.put(this.baseUrl + 'api/awardType/PutNewAwardType' + qs, qs, {
				observe: 'response'
			})
			.pipe(catchError(this.handleError('putNewAwardType', undefined)));
	}

	public putEventAdd(eventId: number, unitId: number, description: string) {
		return this.http
			.put(this.baseUrl + 'api/Event/putEvent?EventTypeId=' + eventId + '&MilitaryUnitId=' + unitId + '&Description=' + description, description, { observe: 'response' })
			.pipe(catchError(this.handleError('putEventAdd', undefined)));
	}

	public putEvent(event) {
		return this.http
			.put(this.baseUrl + 'api/Event/PutEvent', event)
			.pipe(catchError(this.handleError('putEventAdd', undefined)));
	}

	public putEventInstanceNotes(eventInstanceId, eventNotes) {
		return this.http
			.put(this.baseUrl + 'api/EventInstance/putEventInstanceNotes?EventInstanceId=' + eventInstanceId + '&EventNotes=' + eventNotes, eventInstanceId, { observe: 'response' })
			.pipe(catchError(this.handleError('putEventInstanceNotes', undefined)));
	}

	public putOneTimeEventInstance(otei) {
		return this.http
			.put(this.baseUrl + 'api/EventInstance/putOneTimeEventInstance' + otei, otei, { observe: 'response' })
			.pipe(catchError(this.handleError('putOneTimeEventInstance', undefined)));
	}

	public putEventInstance(ei) {
		return this.http
			.put(this.baseUrl + 'api/EventInstance/putEventInstance' + ei, ei, { observe: 'response' })
			.pipe(catchError(this.handleError('putEventInstance', undefined)));
	}

	// Not used
	// public hideCheckIn(hci): Observable<HttpResponse<any>> {
	// 	return this.http
	// 		.put(this.baseUrl + 'api/EventInstance/putHideCheckInEvent' + hci, hci, { observe: 'response' })
	// 		.pipe(catchError(this.handleError('hidEventInstance', undefined)));
	// }

	// Not used
	// public mapInstanceToCheckIn(mei): Observable<HttpResponse<any>> {
	// 	return this.http
	// 		.put(this.baseUrl + 'api/EventInstance/putMapEventInstanceToCheckInEvent' + mei, mei, { observe: 'response' })
	// 		.pipe(catchError(this.handleError('mapInstanceToCheckinEvent', undefined)));
	// }

	// Not used
	// public unmapInstanceToCheckIn(umei): Observable<HttpResponse<any>> {
	// 	return this.http
	// 		.put(this.baseUrl + 'api/EventInstance/putUnmapEventInstanceToCheckInEvent' + umei, umei, { observe: 'response' })
	// 		.pipe(catchError(this.handleError('unmapInstanceToCheckinEvent', undefined)));
	// }

	public putFacilityAdd(facString) {
		return this.http
			.put(this.baseUrl + 'api/militaryFacility/putMilitaryFacility' + facString, facString, { observe: 'response' })
			.pipe(catchError(this.handleError('putFacilityAdd', undefined)));
	}

	//?Name=' + name + '&Address=' + address + '&City=' + city + "&Zip=" + zip + "&Latitude=" + longitude + "&Longitude=" + longitude

	public deleteMilitaryEducation(milID: number) {
		return this.http
			.delete(this.baseUrl + 'api/militaryEducation/deleteMilEducationCourse?MilitaryEducationTypeId=' + milID)
			.pipe(catchError(this.handleError('deleteMilitaryEducation', undefined)));
	}


	public deleteFEMAEducation(femaEducationTypeId: number) {
		return this.http
			.delete(this.baseUrl + 'api/femaEducation/deleteFEMAEducationCourse?femaEducationTypeId=' + femaEducationTypeId)
			.pipe(catchError(this.handleError('deleteFEMAEducation', undefined)));
	}

	public deleteMilEvent(delID: number) {
		return this.http
			.delete(this.baseUrl + 'api/Event/deleteEvent?EventDelId=' + delID)
			.pipe(catchError(this.handleError('deleteMilEvent', undefined)));
	}

	public deleteFacility(milID: number) {
		return this.http
			.delete(this.baseUrl + 'api/militaryFacility/deleteMilFacility?MilitaryFacilityID=' + milID)
			.pipe(catchError(this.handleError('deleteFacility', undefined)));
	}

	// Not used
	// public getNotInRosterList(notInRosterId): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/EventInstance/getNotInRosterList?NotInRosterId=' + notInRosterId)
	// 		.pipe(catchError(this.handleError('getNotInRosterList', undefined)));
	// }

	//  Not used
	// public getFullCheckInList(fullCheckInEventInstanceId): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/EventInstance/getFullCheckInList?FullCheckInEventInstanceId=' + fullCheckInEventInstanceId)
	// 		.pipe(catchError(this.handleError('getFullCheckInList', undefined)));
	// }

	//  Not used
	// public getCheckInEvents(): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/checkinevent/getUnreconciledCheckInEvents')
	// 		.pipe(catchError(this.handleError('getCheckInEvents', undefined)));
	// }

	//  Not Used
	// public getLinkedCheckInEvents(eventInstanceId): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/checkinevent/getReconciledCheckInEvents?EventInstanceId=' + eventInstanceId)
	// 		.pipe(catchError(this.handleError('getLinkedCheckInEvents', undefined)));
	// }

	//  Not used
	// public getEventInstanceMembers(eventInstanceId): Observable<any> {
	// 	return this.http
	// 		.get(this.baseUrl + 'api/EventInstance/getMembers?eventInstanceId=' + eventInstanceId)
	// 		.pipe(catchError(this.handleError('getEventInstanceMembers', undefined)));
	// }

	public deleteEventInstance(eventInstID) {
		return this.http
			.delete(this.baseUrl + 'api/EventInstance/deleteEventInstance?EventInstID=' + eventInstID)
			.pipe(catchError(this.handleError('deleteEventInstance', undefined)));
	}

	public getDispositionTypes(): Observable<ApplicantDispositionType[]> {
		return this.http
			.get<ApplicantDispositionType[]>(this.baseUrl + 'api/applicantdispositiontype/getDispositionTypes')
			.pipe(catchError(this.handleError('getDispositionTypes', [])));
	}

	public postEmailTemplate(desc, subject, body) {
		// const httpOptions = {
		// 	headers: new HttpHeaders({
		// 		'Content-Type': 'application/json'
		// 	})
		// };

		// let bodypost = JSON.stringify({ "Description": desc, "Subject": subject, "Body": body });

		return this.http
			.post(this.baseUrl + 'api/emailtemplate/PostEmailTemplate', body)
			// .map(this.extractData)
			.pipe(catchError(this.handleError('postEmailTemplate', 'post')));
	}

	public putAddUnit(
		ParentMilitaryUnitID: number,
		MilitaryUnitID: number,
		UnitName: string,
		UnitDesignation: string,
		UnitCode: string,
		BranchTypeID: number,
		UnitLocation: string,
		MilitaryFacilityID: number,
		IsHqUnit: boolean,
		IsAssignable: boolean,
		SortOrder: number
	) {
		const query = this.globals.getParams({
			ParentMilitaryUnitID: ParentMilitaryUnitID,
			MilitaryUnitID: MilitaryUnitID,
			UnitName: UnitName,
			UnitDesignation: UnitDesignation,
			UnitCode: UnitCode,
			BranchTypeID: BranchTypeID,
			UnitLocation: UnitLocation,
			MilitaryFacilityID: MilitaryFacilityID,
			IsHqUnit: IsHqUnit,
			IsAssignable: IsAssignable,
			SortOrder: SortOrder
		});

		return this.http.put(this.baseUrl + 'api/unit/PutUnit' + query, query, { observe: 'response' });
	}

	public deleteUnit(MilitaryUnitID: number) {
		const query = this.globals.getParams({
			MilitaryUnitID: MilitaryUnitID
		});

		return this.http.put(this.baseUrl + 'api/unit/DeleteUnit' + query, query, { observe: 'response' });
	}

	public putUnitDisable(unitId: number) {
		return this.http.put(this.baseUrl + 'api/unit/DeactivateUnit?MilitaryUnitID=' + unitId, unitId, { observe: 'response' });
	}

	public CreateOrders(req: CreateOrdersRequest): Promise<void> {
		return this.http.post<void>(`${this.baseUrl}api/Orders`, req).toPromise();
	}

	public getOperationOrders(id: number) {
		const url = encodeURI(`${this.baseUrl}api/Operations/${id}/Orders`);
		return this.http.get(url);
	}

	public putUnitEnable(unitId: number) {
		return this.http.put(this.baseUrl + 'api/unit/ActivateUnit?MilitaryUnitID=' + unitId, unitId, { observe: 'response' });
	}

	public getUnitRoles(unit: MilitaryUnit): Observable<Role[]> {
		return this.http.get<Role[]>(this.baseUrl + 'api/unit/GetUnitRoles?UnitId=' + unit.militaryUnitID);
	}

	public putRole(unitId: number, roleId: number) {
		return this.http.put(this.baseUrl + 'api/unit/PutRole?MilitaryUnitID=' + unitId + "&RoleID=" + roleId, unitId, { observe: 'response' });
	}

	public removeRole(unitId: number, roleId: number) {
		return this.http.delete(this.baseUrl + 'api/unit/DeleteRole?MilitaryUnitID=' + unitId + "&RoleID=" + roleId);
	}

	public getOperationTypesByDates(start: Date, end: Date): Observable<OperationByDate[]> {
		const startDate = start.toISOString().split('T')[0];
		const endDate = end.toISOString().split('T')[0];

		return this.http
			.get<OperationByDate[]>(`${this.baseUrl}api/GetOperationTypes?startDate=${startDate}&endDate=${endDate}`)
			.pipe(catchError(this.handleError('GetOperationTypes', [])));
	}

	public getPackagesByOperationId(OperationId: number): Observable<Package[]> {
		return this.http
			.get<Package[]>(`${this.baseUrl}api/GetPackagesByOperationID?OperationId=${OperationId}`)
			.pipe(catchError(this.handleError('GetOperationTypes', [])));
	}

	//  Not used
	// public rosterReport(eventInstanceId: number): Observable<any> {
	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Content-Type': 'application/json; charset=utf-8'
	// 		})
	// 	};

	// 	const body = {
	// 		'eventInstanceId': eventInstanceId
	// 	};

	// 	return this.http.post(this.baseUrl + 'api/roster/Post', body, httpOptions);
	// }

	public GetDiscrepancies(operationId: number, dutyDateRange: Date[]): Promise<DiscrepancyReportDto> {
		const params = new HttpParams()
			.set('operationId', operationId)
			.set('dutyDateStart', dutyDateRange[0].toJSON())
			.set('dutyDateEnd', dutyDateRange[1].toJSON());

		return this.http.get<DiscrepancyReportDto>(`${this.baseUrl}api/DiscrepancyReport/?${params.toString()}`).toPromise();
	}
}
