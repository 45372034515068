import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuItem } from '../interfaces/menu-item';

@Injectable({
	providedIn: 'root',
})
export class MenuItemService {
	private baseUrl = environment.api;

	public constructor(private http: HttpClient) {}

	public GetMenuItems(): Promise<MenuItem[]> {
		return this.http
			.get<MenuItem[]>(`${this.baseUrl}api/MenuItems`)
			.toPromise();
	}
}
