import { BranchDto } from '../models/BranchDto';
import { ISerializer } from './ISerializer';

export class BranchSerializer implements ISerializer<BranchDto> {
	public fromJson(json: Record<string, unknown>): BranchDto {
		const branchDto = new BranchDto();
		branchDto.id = json['id'] as number;
		branchDto.name = json['name'] as string;
		// Map other properties from JSON to BranchDto
		return branchDto;
	}
	public toJson(entity: BranchDto): Record<string, unknown> {
		return {
			id: entity.id,
			name: entity.name,
			// Map other properties from BranchDto to JSON
		};
	}
}
