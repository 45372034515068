import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
	public transform(value: string): string {
		return value ? 'Yes' : 'No';
	}
}
