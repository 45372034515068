export class BaseEntity {
	public id: number = null;

	public equals?(obj: BaseEntity): boolean {
		const aProps = Object.getOwnPropertyNames(this);
		const bProps = Object.getOwnPropertyNames(obj);

		if (aProps.length != bProps.length) {
			return false;
		}

		for (let i = 0; i < aProps.length; i++) {
			const propName = aProps[i];

			if (this[propName] !== obj[propName]) {
				return false;
			}
		}

		return true;
	}
}
