import {
	Component,
	ViewChild,
	ElementRef,
	AfterViewInit,
	Renderer2,
} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import {
	animate,
	state,
	style,
	transition,
	trigger,
	AnimationEvent,
} from '@angular/animations';

@Component({
	selector: 'app-search',
	template: `
		<div class="layout-search">
			<div
				class="search-container"
				[@animation]="appMain.search ? 'visible' : 'hidden'"
				(@animation.done)="onAnimationEnd($event)"
			>
				<i class="pi pi-search"></i>
				<input
					#input
					type="text"
					class="p-inputtext search-input"
					placeholder="Search"
					(click)="appMain.searchClick = true"
					(keydown)="onInputKeydown($event)"
				/>
			</div>
		</div>
	`,
	animations: [
		trigger('animation', [
			state(
				'hidden',
				style({
					transform: 'translateY(20px)',
					opacity: 0,
					visibility: 'hidden',
				})
			),
			state('visible', style({ opacity: 1, visibility: 'visible' })),
			transition(
				'hidden <=> visible',
				animate('.4s cubic-bezier(.05,.74,.2,.99)')
			),
		]),
	],
})
export class AppSearchComponent implements AfterViewInit {
	@ViewChild('input', { static: true }) public inputElement!: ElementRef<HTMLInputElement>;

	public constructor(
		public appMain: AppMainComponent,
		private renderer: Renderer2
	) {}

	public ngAfterViewInit() {
		this.renderer.setAttribute(
			this.inputElement.nativeElement,
			'autofocus',
			''
		);
	}

	public onAnimationEnd(event: AnimationEvent) {
		if (event.toState === 'visible') {
			this.inputElement.nativeElement.focus();
		}
	}

	public onInputKeydown(event: KeyboardEvent) {
		const key = event.key;
		// escape, tab and enter
		if (key === 'Escape' || key === 'Tab' || key === 'Enter') {
			this.appMain.onSearchHide();
		}
	}
}
