import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from "@angular/router";
import { ngxPermissionsGuard } from "ngx-permissions";
import { UserService } from "../services/user.service";
import { inject } from "@angular/core";


export const permissionsGuard = (route: ActivatedRouteSnapshot & Route, state: RouterStateSnapshot & UrlSegment[]) => {
	const userService = inject(UserService);

	// SuperSysAdmin is always role ID 1
	if (userService.roles.some(x => x.id === 1)) {
		return Promise.resolve(true);
	}

	return ngxPermissionsGuard(route, state);
};
