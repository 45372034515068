import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './app.menu.service';
import { AppMainComponent } from './app.main.component';
import { RouterType } from './interfaces/RouterType';

@Component({
	/* tslint:disable:component-selector */
	selector: '[app-menuitem]',
	/* tslint:enable:component-selector */
	template: `
		<ng-container>
			@if (root && item.visible !== false) {
			<div class="layout-menuitem-root-text">
				{{ item.label }}
			</div>
		}

		@if ((!item.routerLink || item.items) && item.visible !== false) {
			<a
				[attr.href]="item.url"
				(click)="itemClick($event)"
				(mouseenter)="onMouseEnter()"
				(keydown.enter)="itemClick($event)"
				[ngClass]="item.class"
				pRipple
				[attr.target]="item.target"
				[attr.tabindex]="0"
				>
				<i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
				<span class="layout-menuitem-text">{{ item.label }}</span>
				@if (item.items) {
					<i class="pi pi-fw layout-submenu-toggler" [ngClass]="!appMain.isHorizontal() ? 'pi-chevron-down' : 'pi-angle-down'"></i>
				}
			</a>
		}

		@if (item.routerLink && !item.items && item.visible !== false) {
			<a
				(click)="itemClick($event)"
				(mouseenter)="onMouseEnter()"
				[routerLink]="item.routerLink"
				routerLinkActive="active-route"
				[ngClass]="item.class"
				pRipple
				[routerLinkActiveOptions]="{ exact: !item.preventExact }"
				[attr.target]="item.target"
				[attr.tabindex]="0"
				>
				<i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
				<span class="layout-menuitem-text">{{ item.label }}</span>
				@if (item.items) {
					<i class="pi pi-fw layout-submenu-toggler" [ngClass]=" !appMain.isHorizontal() ? 'pi-chevron-down' : 'pi-angle-down' "></i>
				}
			</a>
		}

		@if (item.items && item.visible !== false) {
			<ul
				role="menu"
				[@children]="
					appMain.isSlim() || appMain.isHorizontal()
						? root
							? appMain.isMobile()
								? 'visible'
								: slimClick && !appMain.isHorizontal()
								? active
									? 'slimVisibleAnimated'
									: 'slimHiddenAnimated'
								: active
								? 'visible'
								: 'hidden'
							: appMain.isSlim() || appMain.isHorizontal()
							? active
								? 'visibleAnimated'
								: 'hiddenAnimated'
							: active
							? 'visible'
							: 'hidden'
						: root
						? 'visible'
						: active
						? 'visibleAnimated'
						: 'hiddenAnimated'
				"
			>
		@for (child of item.items; track child; let i = $index) {
			<li
				app-menuitem
				[item]="child"
				[index]="i"
				[parentKey]="key"
				[class]="child.badgeClass"
				>
			</li>
		}
			</ul>
		}
		</ng-container>
		`,
	animations: [
		trigger('children', [
			state('void', style({ height: '0px', })),
			state('hiddenAnimated', style({ height: '0px', })),
			state('visibleAnimated', style({ height: '*', })),
			state('visible', style({ height: '*', 'z-index': 100, })),
			state('hidden', style({ height: '0px', 'z-index': '*', })),
			state('slimVisibleAnimated', style({ opacity: 1, transform: 'none', })),
			state('slimHiddenAnimated', style({ opacity: 0, transform: 'translateX(20px)', })),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('void => visibleAnimated, visibleAnimated => void', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('void => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
			transition('slimHiddenAnimated => slimVisibleAnimated', animate('400ms cubic-bezier(.05,.74,.2,.99)')),
		]),
	],
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
	@Input()
	public item: RouterType;

	@Input()
	public index: number;

	@Input()
	@HostBinding('class.layout-root-menuitem')
	public root: boolean = false;

	@Input()
	public parentKey: string;

	public active = false;
	public menuSourceSubscription: Subscription;
	public menuResetSubscription: Subscription;
	public key: string;
	public slimClick = false;

	@HostBinding('class.active-menuitem')
	private activeMenuItem: boolean = false;

	public constructor(
		public appMain: AppMainComponent,
		public router: Router,
		private cd: ChangeDetectorRef,
		private menuService: MenuService
	) {
		this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
			(key) => {
				// deactivate current active menu
				if (
					this.active &&
					this.key !== key &&
					key.indexOf(this.key) !== 0
				) {
					this.active = false;
				}
			}
		);

		this.menuResetSubscription = this.menuService.resetSource$.subscribe(
			() => {
				this.active = false;
			}
		);

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				if (this.appMain.isSlim() || this.appMain.isHorizontal()) {
					this.active = false;
				} else {
					if (this.item.routerLink) {
						this.updateActiveStateFromRoute();
					} else {
						this.active = false;
					}
				}
			});
	}

	public ngOnInit() {
		if (
			!(this.appMain.isSlim() || this.appMain.isHorizontal()) &&
			this.item.routerLink
		) {
			this.updateActiveStateFromRoute();
		}

		this.key = this.parentKey
			? this.parentKey + '-' + this.index
			: String(this.index);

		this.activeMenuItem = (this.active && !this.root) || (this.active && (this.appMain.isSlim() || this.appMain.isHorizontal()));
	}

	public updateActiveStateFromRoute() {
		this.active = this.router.isActive(
			this.item.routerLink[0],
			!this.item?.jRMSSubMenu?.length && !this.item.preventExact
		);
	}

	public itemClick(event: Event) {
		if (this.appMain.isSlim()) {
			this.slimClick = true;
		}

		// avoid processing disabled items

		if (this.item.disabled) {
			event.preventDefault();
			return;
		}

		// navigate with hover in horizontal mode
		if (this.root) {
			this.appMain.menuHoverActive = !this.appMain.menuHoverActive;
		}

		// notify other items

		this.menuService.onMenuStateChange(this.key);

		// execute command
		if (this.item.command) {
			this.item.command({ originalEvent: event, item: this.item });
		}

		// toggle active state
		if (this.item?.jRMSSubMenu?.length) {
			this.active = !this.active;
		} else {
			// activate item
			this.active = true;

			if (this.appMain.isMobile()) {
				this.appMain.staticMenuMobileActive = false;
			}

			// reset horizontal menu
			if (this.appMain.isSlim() || this.appMain.isHorizontal()) {
				this.menuService.reset();
				this.appMain.menuHoverActive = false;
			}

			this.appMain.unblockBodyScroll();
		}

		this.removeActiveInk(event);
	}

	public onMouseEnter() {
		// activate item on hover
		if (
			this.root &&
			(this.appMain.isSlim() || this.appMain.isHorizontal()) &&
			this.appMain.isDesktop()
		) {
			if (this.appMain.menuHoverActive) {
				this.menuService.onMenuStateChange(this.key);
				this.active = true;
				this.slimClick = false;
			} else {
				if (this.appMain.isSlim()) {
					this.slimClick = true;
				}
			}
		}
	}

	public removeActiveInk(event: Event) {
		const currentTarget = event.currentTarget as HTMLElement;

		setTimeout(() => {
			if (currentTarget) {
				const activeInk = currentTarget.querySelector('.p-ink-active');
				if (activeInk) {
					if (activeInk.classList) {
						activeInk.classList.remove('p-ink-active');
					} else {
						activeInk.className = activeInk.className.replace(
							new RegExp(
								'(^|\\b)' +
									'p-ink-active'.split(' ').join('|') +
									'(\\b|$)',
								'gi'
							),
							' '
						);
					}
				}
			}
		}, 401);
	}

	public ngOnDestroy() {
		if (this.menuSourceSubscription) {
			this.menuSourceSubscription.unsubscribe();
		}

		if (this.menuResetSubscription) {
			this.menuResetSubscription.unsubscribe();
		}
	}
}
