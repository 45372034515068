import { BrigadeFullDto } from '../models';
import { ISerializer } from './ISerializer';

export class BrigadeSerializer implements ISerializer<BrigadeFullDto> {
	public fromJson(json: Record<string, unknown>): BrigadeFullDto {
		const brigade = new BrigadeFullDto();
		brigade.branch = json['branch'] as string;
		brigade.branchId = json['branchId'] as number;
		brigade.id = json['id'] as number;
		brigade.name = json['name'] as string;
		// Map other properties from JSON to BrigadeFullDto
		return brigade;
	}
	public toJson(entity: BrigadeFullDto): Record<string, unknown> {
		return {
			branch: entity.branch,
			branchId: entity.branchId,
			id: entity.id,
			name: entity.name,
			// Map other properties from BrigadeFullDto to JSON
		};
	}
}
