import { BaseEntity } from "./BaseEntity";

export class DutyDefinitionDto extends BaseEntity {
	public asi1Id: number | null = null;
	public asi2Id: number | null = null;
	public asi3Id: number | null = null;
	public asi4Id: number | null = null;
	public auth: number | null = null;
	public dutyPosition: string | null = null;
	public fte: boolean | null = null;
	public grade: string | null = null;
	public ln: string | null = null;
	public milcivexper: string | null = null;
	public paraNo: number | null = null;
	public paraTitle: string | null = null;
	public pmosId: number | null = null;
	public req: number | null = null;
	public rsmosId: number | null = null;
	public sqiId: number | null = null;
}
