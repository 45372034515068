/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T>(
	operation?: string,
	result?: T
) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
	public constructor(private messageService: MessageService) {}

	/** Create curried handleError function that already knows the service name */
	public createHandleError =
		(serviceName = '') =>
			<T>(operation = 'operation', result = {} as T) =>
				this.handleError(serviceName, operation, result);

	/**
	 * Returns a function that handles Http operation failures.
	 * This error handler lets the app continue to run as if no error occurred.
	 * @param serviceName = name of the data service that attempted the operation
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	public handleError<T>(
		serviceName = '',
		operation = 'operation',
		result = {} as T
	): (error: HttpErrorResponse) => Observable<T> {
		return (error: HttpErrorResponse): Observable<T> => {
			// let msgstring: string;

			//mderobio: As far as I could see, this function was not doing anything
			// except double logging the error to the console (it's already being output)
			// and swallowing the error. But I leave it
			// in place for future call to log error to backend service.
			// rethrowing error so that calling component can react appropriately
			return throwError(() => new Error(error.message));

			// TODO: send the error to remote logging infrastructure
			// if (error.error instanceof ErrorEvent) {
			// 	console.error('An error occurred:', error.error.message); // log to console instead
			// 	alert(`An error occurred: ${error.error.message}`);
			// } else {
			// 	// backend returned an unsuccessful response code
			// 	if (error.status == 401) {
			// 		//this.authService.instance.loginRedirect();
			// 		// msgstring = 'Authentication error: Go to login page and login in';
			// 		// alert(msgstring);
			// 		// console.error(msgstring);
			// 	} else {
			// 		msgstring = `Backend returned code ${error.status}, body was ${error.error}`;
			// 		// SAH
			// 		// alert(`HTTP error: ${error.status} (See console for details)`);
			// 		console.error(msgstring);
			// 	}
			// }

			// const message =
			// 	error.error instanceof ErrorEvent
			// 		? error.error.message
			// 		: msgstring;
			// `server returned code ${error.status} with body "${error.error}"`;

			// TODO: better job of transforming error for user consumption
			// this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);
			// this.messageService.add({
			// 	severity: 'error',
			// 	summary: `${serviceName} failed`,
			// 	detail: message,
			// });

			// Let the app keep running by returning a safe result.
			// return of(result);
		};
	}
}
