import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SpinnerService {

	public showSpinner = false;

	public constructor() {

	}

}
