import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IconData } from 'src/app/interfaces/icon';

@Injectable({
	providedIn: 'root'
})
export class IconService {
	private apiUrl = 'assets/demo/data/icons.json';

	public constructor(private http: HttpClient) { }

	public getIcons(): Observable<IconData[]> {
		return this.http.get<{ icons: IconData[] }>(this.apiUrl).pipe(
			map(response => {
				return response.icons;
			})
		);
	}
}