import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { BattalionFullDto } from '../models';
import { BattalionSerializer } from '../serializers';
import { CustomErrorResponse } from 'src/app/interfaces/custom-error-response';

@Injectable()
export class BattalionService extends GenericService<BattalionFullDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'Battalions',
			new BattalionSerializer()
		);
	}

	public getAllByBrigadeId(brigadeId: number): Promise<BattalionFullDto[]> {
		return new Promise((resolve, reject) => {
			this.httpClient
				.get<BattalionFullDto[]>(`${this.url}/${this.endpoint}/Brigade/${brigadeId}`)
				.subscribe({
					next: (data: BattalionFullDto[]) => {
						const convertedData = this.convertData(data);
						resolve(convertedData);
					},
					error: (errorResponse: CustomErrorResponse) => {
						const handledError = this.handleErrors(errorResponse);
						reject(handledError);
					}
				});
		});
	}
}
