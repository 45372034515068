import { Component, ElementRef, AfterViewInit, Input, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

declare global {
	interface Window {
		Prism: {
			highlightElement: (element: HTMLElement) => void;
		};
	}
}

@Component({
	selector: 'app-code',
	template: `
        <pre [ngClass]="'language-' + lang"><code #code><ng-content></ng-content>
</code></pre>
    `,
})
export class AppCodeComponent implements AfterViewInit {

	@Input() public lang = 'markup';

	@ViewChild('code') public codeViewChild: ElementRef<HTMLElement>;

	public constructor(public el: ElementRef) { }

	public ngAfterViewInit() {
		if (window.Prism) {
			(window.Prism as { highlightElement: (element: HTMLElement) => void }).highlightElement(this.codeViewChild.nativeElement);
		}
	}
}

@NgModule({
	imports: [CommonModule],
	exports: [AppCodeComponent],
	declarations: [AppCodeComponent]
})
export class AppCodeModule { }
