import {
	ChangeDetectorRef,
	Directive,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import {
	NgxPermissionsConfigurationService,
	NgxPermissionsPredefinedStrategies,
	NgxPermissionsService,
	NgxRolesService,
	StrategyFunction,
} from 'ngx-permissions';
import { Subscription, merge } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';

/**
 *
 * This is a copy of NgxPermissionsDirective with logic added in to allow SuperSysAdmins to have full access.
 *
 */
@Directive({
	selector: '[PermissionsOnly],[PermissionsExcept]',
})
export class AuthDirective implements OnInit, OnDestroy, OnChanges {
	@Input()
	public PermissionsOnly: string | string[];

	@Input()
	public PermissionsOnlyThen: TemplateRef<unknown>;

	@Input()
	public PermissionsOnlyElse: TemplateRef<unknown>;

	@Input()
	public PermissionsExcept: string | string[];

	@Input()
	public PermissionsExceptElse: TemplateRef<unknown>;

	@Input()
	public PermissionsExceptThen: TemplateRef<unknown>;

	@Input()
	public PermissionsThen: TemplateRef<unknown>;

	@Input()
	public PermissionsElse: TemplateRef<unknown>;

	@Input()
	public PermissionsOnlyAuthorisedStrategy: string | StrategyFunction;

	@Input()
	public PermissionsOnlyUnauthorisedStrategy: string | StrategyFunction;

	@Input()
	public PermissionsExceptUnauthorisedStrategy: string | StrategyFunction;

	@Input()
	public PermissionsExceptAuthorisedStrategy: string | StrategyFunction;

	@Input()
	public PermissionsUnauthorisedStrategy: string | StrategyFunction;

	@Input()
	public PermissionsAuthorisedStrategy: string | StrategyFunction;

	@Output()
	public permissionsAuthorized: EventEmitter<unknown> = new EventEmitter();

	@Output()
	public permissionsUnauthorized: EventEmitter<unknown> = new EventEmitter();

	private initPermissionSubscription: Subscription;
	private firstMergeUnusedRun = 1;
	private currentAuthorizedState: boolean;

	public constructor(
		private readonly permissionsService: NgxPermissionsService,
		private readonly configurationService: NgxPermissionsConfigurationService,
		private readonly rolesService: NgxRolesService,
		private readonly viewContainer: ViewContainerRef,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly templateRef: TemplateRef<unknown>,
		private readonly userService: UserService
	) {}

	public ngOnInit(): void {
		this.viewContainer.clear();
		this.initPermissionSubscription = this.validateExceptOnlyPermissions();
	}

	public ngOnChanges(changes: SimpleChanges): void {
		const onlyChanges = changes['PermissionsOnly'];
		const exceptChanges = changes['PermissionsExcept'];

		if (onlyChanges || exceptChanges) {
			// Due to bug when you pass empty array
			if (onlyChanges && onlyChanges.firstChange) {
				return;
			}
			if (exceptChanges && exceptChanges.firstChange) {
				return;
			}

			merge(
				this.permissionsService.permissions$,
				this.rolesService.roles$
			)
				.pipe(skip(this.firstMergeUnusedRun), take(1))
				.subscribe(() => {
					if (this.notEmptyValue(this.PermissionsExcept)) {
						this.validateExceptAndOnlyPermissions();
						return;
					}

					if (this.notEmptyValue(this.PermissionsOnly)) {
						this.validateOnlyPermissions();
						return;
					}

					this.handleAuthorisedPermission(
						this.getAuthorisedTemplates()
					);
				});
		}
	}

	public ngOnDestroy(): void {
		if (this.initPermissionSubscription) {
			this.initPermissionSubscription.unsubscribe();
		}
	}

	private validateExceptOnlyPermissions(): Subscription {
		return merge(
			this.permissionsService.permissions$,
			this.rolesService.roles$
		)
			.pipe(skip(this.firstMergeUnusedRun))
			.subscribe(() => {
				if (this.notEmptyValue(this.PermissionsExcept)) {
					this.validateExceptAndOnlyPermissions();
					return;
				}

				if (this.notEmptyValue(this.PermissionsOnly)) {
					this.validateOnlyPermissions();
					return;
				}
				this.handleAuthorisedPermission(this.getAuthorisedTemplates());
			});
	}

	private validateExceptAndOnlyPermissions(): void {
		Promise.all([
			this.permissionsService.hasPermission(this.PermissionsExcept),
			this.rolesService.hasOnlyRoles(this.PermissionsExcept),
		])
			.then(([hasPermission, hasRole]) => {
				if (hasPermission || hasRole) {
					this.handleUnauthorisedPermission(
						this.PermissionsExceptElse || this.PermissionsElse
					);
					return;
				}

				if (!!this.PermissionsOnly) {
					throw false;
				}

				this.handleAuthorisedPermission(
					this.PermissionsExceptThen ||
						this.PermissionsThen ||
						this.templateRef
				);
			})
			.catch(() => {
				if (!!this.PermissionsOnly) {
					this.validateOnlyPermissions();
				}
				else {
					this.handleAuthorisedPermission(
						this.PermissionsExceptThen ||
							this.PermissionsThen ||
							this.templateRef
					);
				}
			});
	}

	private validateOnlyPermissions(): void {
		Promise.all([
			this.permissionsService.hasPermission(this.PermissionsOnly),
			this.rolesService.hasOnlyRoles(this.PermissionsOnly),
			Promise.resolve(this.userService.roles.some((x) => x.id === 1)),
		])
			.then(([hasPermissions, hasRoles, isSuperAdmin]) => {
				if (hasPermissions || hasRoles || isSuperAdmin) {
					this.handleAuthorisedPermission(
						this.PermissionsOnlyThen ||
							this.PermissionsThen ||
							this.templateRef
					);
				}
				else {
					this.handleUnauthorisedPermission(
						this.PermissionsOnlyElse || this.PermissionsElse
					);
				}
			})
			.catch(() => {
				this.handleUnauthorisedPermission(
					this.PermissionsOnlyElse || this.PermissionsElse
				);
			});
	}

	private handleUnauthorisedPermission(template: TemplateRef<unknown>): void {
		if (
			this.isBoolean(this.currentAuthorizedState) &&
			!this.currentAuthorizedState
		) {
			return;
		}

		this.currentAuthorizedState = false;
		this.permissionsUnauthorized.emit();

		if (this.getUnAuthorizedStrategyInput()) {
			this.applyStrategyAccordingToStrategyType(
				this.getUnAuthorizedStrategyInput()
			);
			return;
		}

		if (
			this.configurationService.onUnAuthorisedDefaultStrategy &&
			!this.elseBlockDefined()
		) {
			this.applyStrategy(
				this.configurationService.onUnAuthorisedDefaultStrategy
			);
		}
		else {
			this.showTemplateBlockInView(template);
		}
	}

	private handleAuthorisedPermission(template: TemplateRef<unknown>): void {
		if (
			this.isBoolean(this.currentAuthorizedState) &&
			this.currentAuthorizedState
		) {
			return;
		}

		this.currentAuthorizedState = true;
		this.permissionsAuthorized.emit();

		if (this.getAuthorizedStrategyInput()) {
			this.applyStrategyAccordingToStrategyType(
				this.getAuthorizedStrategyInput()
			);
			return;
		}

		if (
			this.configurationService.onAuthorisedDefaultStrategy &&
			!this.thenBlockDefined()
		) {
			this.applyStrategy(
				this.configurationService.onAuthorisedDefaultStrategy
			);
		}
		else {
			this.showTemplateBlockInView(template);
		}
	}

	private applyStrategyAccordingToStrategyType(
		strategy: string | StrategyFunction
	): void {
		if (this.isString(strategy)) {
			this.applyStrategy(strategy);
			return;
		}

		if (this.isFunction(strategy)) {
			this.showTemplateBlockInView(this.templateRef);
			(strategy)(this.templateRef);
			return;
		}
	}

	private showTemplateBlockInView(template: TemplateRef<unknown>): void {
		this.viewContainer.clear();
		if (!template) {
			return;
		}

		this.viewContainer.createEmbeddedView(template);
		this.changeDetector.markForCheck();
	}

	private getAuthorisedTemplates(): TemplateRef<unknown> {
		return (
			this.PermissionsOnlyThen ||
			this.PermissionsExceptThen ||
			this.PermissionsThen ||
			this.templateRef
		);
	}

	private elseBlockDefined(): boolean {
		return !!this.PermissionsExceptElse || !!this.PermissionsElse;
	}

	private thenBlockDefined() {
		return !!this.PermissionsExceptThen || !!this.PermissionsThen;
	}

	private getAuthorizedStrategyInput() {
		return (
			this.PermissionsOnlyAuthorisedStrategy ||
			this.PermissionsExceptAuthorisedStrategy ||
			this.PermissionsAuthorisedStrategy
		);
	}

	private getUnAuthorizedStrategyInput() {
		return (
			this.PermissionsOnlyUnauthorisedStrategy ||
			this.PermissionsExceptUnauthorisedStrategy ||
			this.PermissionsUnauthorisedStrategy
		);
	}

	private applyStrategy(name: string) {
		if (name === NgxPermissionsPredefinedStrategies.SHOW) {
			this.showTemplateBlockInView(this.templateRef);
			return;
		}

		if (name === NgxPermissionsPredefinedStrategies.REMOVE) {
			this.viewContainer.clear();
			return;
		}
		const strategy = this.configurationService.getStrategy(name);
		this.showTemplateBlockInView(this.templateRef);
		strategy(this.templateRef);
	}

	private notEmptyValue(value: string | string[]): boolean {
		if (Array.isArray(value)) {
			return value.length > 0;
		}
		return !!value;
	}

	private isBoolean(value): value is boolean {
		return typeof value === 'boolean';
	}

	private isString(value): value is string {
		return !!value && typeof value === 'string';
	}

	private isFunction<T>(value): value is T {
		return typeof value === 'function';
	}
}
