<div class="card">
	<h4>Icons</h4>

	<h5>Download</h5>
	<app-code lang="markup" ngPreserveWhitespaces ngNonBindable>
		npm install primeicons --save
	</app-code>

	<h5>Getting Started</h5>
	<p>
		PrimeIcons use the <strong>pi pi-&#123;icon&#125;</strong> syntax such
		as <strong>pi pi-check</strong>. A standalone icon can be displayed
		using an element such as <i>i</i> or <i>span</i>
	</p>

	<app-code lang="markup" ngPreserveWhitespaces ngNonBindable>
		&lt;i class="pi pi-check"&gt;&lt;/i&gt; &lt;i class="pi
		pi-times"&gt;&lt;/i&gt;
	</app-code>

	<i class="pi pi-check" style="margin-right: 0.5rem"></i>
	<i class="pi pi-times"></i>

	<h5>Size</h5>
	<p>Size of the icons can easily be changed using font-size property.</p>

	<app-code lang="markup" ngPreserveWhitespaces ngNonBindable>
		&lt;i class="pi pi-check"&gt;&lt;/i&gt;
	</app-code>

	<i class="pi pi-check"></i>

	<app-code lang="markup" ngPreserveWhitespaces ngNonBindable>
		&lt;i class="pi pi-check" style="font-size: 2rem"&gt;&lt;/i&gt;
	</app-code>

	<i class="pi pi-check" style="font-size: 2rem"></i>

	<h5>Spinning Animation</h5>
	<p>Special pi-spin class applies infinite rotate to an icon.</p>

	<app-code lang="markup" ngPreserveWhitespaces ngNonBindable>
		&lt;i class="pi pi-spin pi-spinner" style="font-size:
		2rem"&gt;&lt;/i&gt;
	</app-code>

	<i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>

	<h5>List of Icons</h5>
	<p>
		Here is the current list of PrimeIcons, more icons will be added
		periodically. You may also
		<a href="https://github.com/primefaces/primeicons/issues"
			>request new icons</a
		>
		at the issue tracker.
	</p>
	<div>
		<input
			class="icon-filter"
			(input)="onFilter($event)"
			pInputText
			placeholder="Search an icon"
			class="w-full p-3 mt-3 mb-5 w-full p-3 mt-3 mb-5"
		/>
	</div>

	<div class="grid text-center">
		@for (icon of filteredIcons; track icon) {
		<div class="col-6 sm:col-4 lg:col-3 xl:col-2 pb-5">
			<i class="text-2xl pi pi-{{ icon.icon.properties.name }}"></i>
			<div>pi-{{ icon.icon.properties.name }}</div>
		</div>
		}
	</div>
</div>
