import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppMainComponent } from './app.main.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { ContributorsComponent } from './contributors/contributors.component';
import { DocComponent } from './doc/doc.component';
import { LandingComponent } from './landing/landing.component';

import { AuthGuard } from './auth/auth.guard';
import * as Permissions from './permissions.constants';
import { permissionsGuard } from './auth/permissions.guard';

@NgModule({
	imports: [
		RouterModule.forRoot(
			[
				{
					path: 'sec',
					component: AppMainComponent,
					canActivate: [AuthGuard],
					children: [
						{
							path: '',
							pathMatch: 'full',
							component: DashboardDemoComponent,
							canActivate: [AuthGuard],
						},
						{
							path: 'applicant',
							loadChildren: () =>
								import('./applicant/applicant.module').then(
									(m) => m.ApplicantModule
								),
							canActivate: [AuthGuard],
						},
						{
							path: 'member',
							loadChildren: () =>
								import('./member/member.module').then(
									(m) => m.MemberModule
								),
							canActivate: [AuthGuard],
						},
						{
							path: 'activity',
							loadChildren: () =>
								import('./activity/activity.module').then(
									(m) => m.ActivityModule
								),
							canActivate: [AuthGuard],
						},
						{
							path: 'contributors',
							component: ContributorsComponent,
							canActivate: [AuthGuard],

						},
						{
							path: 'doc',
							component: DocComponent,
							canActivate: [AuthGuard],

						},
						{
							path: 'operations',
							loadChildren: () =>
								import('./operations/operations.module').then(
									(m) => m.OperationsModule
								),
							canActivate: [AuthGuard],
						},
						{
							path: 'management',
							loadChildren: () =>
								import('./management/management.module').then(
									(m) => m.ManagementModule
								),
							canActivate: [AuthGuard],
						},
						{
							path: 'reportbuilder',
							loadChildren: () =>
								import(
									'./report-builder/report-builder.module'
								).then((m) => m.ReportBuilderModule),
							canActivate: [AuthGuard],
						},
						{
							path: 'mtoe',
							loadChildren: () =>
								import('./mtoe/mtoe.module').then(
									(m) => m.MtoeModule
								),
							canActivate: [AuthGuard, permissionsGuard],
							data: {
								permissions: {
									only: [Permissions.MtoeRead],
								},
							},
						},
						{
							path: 'dashboard',
							loadChildren: () =>
								import('./Dashboard/dashboard.module').then(
									(m) => m.DashboardModule
								),
							canActivate: [AuthGuard],
						},
					],
				},
				{
					path: 'login',
					component: AppLoginComponent,
					// do not use any guards on this route
				},
				{
					path: '',
					component: LandingComponent,
					// do not use any guards on this route
				},
				{ path: 'error', component: AppErrorComponent },
				{ path: 'access', component: AppAccessdeniedComponent },
				{ path: 'notfound', component: AppNotfoundComponent },
				{ path: '**', redirectTo: '/notfound' },
			],
			{ useHash: false, scrollPositionRestoration: 'enabled' }
		),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
