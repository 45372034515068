<!-- <head>
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
<meta name="apple-mobile-web-app-capable" content="yes" />
<link rel="shortcut icon" href="../layout/images/favicon.ico" type="image/x-icon" />
<link rel="icon" href="../layout/images/favicon.ico" type="image/x-icon" />
<title>PrimeNG Diamond</title>
<link rel="stylesheet" href="primeicons-static.css" />
<link rel="stylesheet" href="../layout/css/landing.css" />
<link rel="stylesheet" href="primeflex.min.css" />
</head> -->
@if (activeB) {
<div class="maintenance" id="maintenance" [innerHTML]="bannerVerbiage"></div>
}
<div class="landing-body">
	<div #landingwrapper class="landing-wrapper">
		<div id="home" class="landing-header">
			<div class="landing-topbar">
				<a href="#home" class="logo">
					<!-- <img src="../layout/images/TMD-Logo-250.png" alt="logo" />
          <h1>DIAMOND</h1> -->
				</a>

				<ul class="landing-menu">
					<li>
						<a
							(click)="closeMenu()"
							id="landing-menu-close"
							tabindex="0"
						>
							<i class="pi pi-times"></i>
						</a>
					</li>
					<li class="contact landing-menu-item cursor-pointer">
						<a (click)="login()">{{ loginText }}</a>
					</li>
					<!-- <li class="landing-menu-item">
          <a href="#pricing">BUY NOW</a>
        </li> -->
				</ul>

				<!-- <a onclick="openMenu()" id="landing-menu-button" tabindex="0">
      <i class="pi pi-bars" (click)="menu.toggle($event)"></i>

      <button type="button" pButton icon="pi pi-bars" label="Show"></button>
      <p-menu #menu [popup]="true" [model]="items"></p-menu>


      <p-menu #menu [popup]="true" [model]="items"></p-menu>
    </a> -->

				<ul class="contact-menu cursor-pointer">
					<li>
						<a (click)="login()">{{ loginText }}</a>
					</li>

					<!-- <li>
      <a href="#"><i class="pi pi-github"></i></a>
    </li>

    <li>
      <a href="#"><i class="pi pi-twitter"></i></a>
    </li> -->
				</ul>
			</div>

			<div class="landing-header-content">
				<div class="header-text">
					<h1>Texas Military Department Proudly Presents JRMS</h1>

					<p>
						The Joint Readiness Management System (JRMS) is a modern
						and highly responsive application with new functions and
						features that support all Texas Military Department
						personnel.
					</p>

					<button class="landing-btn">
						<a href="https://tmd.texas.gov"
							><span>TMD Website</span></a
						>
					</button>
				</div>

				<div class="header-image">
					<img
						class="lazy"
						src="/assets/placeholder-hero-image.jpg"
						data-src="/assets/TMD-Logo-250.png"
						alt="TMD logo"
						data-srcset="/assets/TMD-Logo-250.png"
					/>

					<p class="pr-6">Version: {{ build }}-{{ envName }}</p>
				</div>
			</div>
		</div>

		<div id="meetDiamond" class="landing-meetdiamond">
			<div class="section-title">
				<h1>Duty. Honor. Texas.</h1>
				<span>Texans Serving Texas</span>
			</div>

			<div class="grid">
				<div class="col-12 md:col-6 lg:col-3">
					<div class="card widget-feature-box">
						<img src="/assets/icon-devices.svg" alt="device" />
						<h2>Responsive</h2>
						<span
							>Deliver the best experience to users, whether
							they’re on their desktop, laptop, tablet, or
							smartphone.</span
						>
					</div>
				</div>

				<div class="col-12 md:col-6 lg:col-3">
					<div class="card widget-feature-box">
						<img src="/assets/icon-design.svg" alt="design" />
						<h2>Modern Design</h2>
						<span>Lines Are Clean and Decor Is Minimal.</span>
					</div>
				</div>

				<div class="col-12 md:col-6 lg:col-3">
					<div class="card widget-feature-box">
						<img src="/assets/icon-document.svg" alt="document" />
						<h2>Cloud Storage</h2>
						<span
							>Cloud computing model that stores data on the
							Internet through a cloud computing provider who
							manages and operates data storage.</span
						>
					</div>
				</div>

				<div class="col-12 md:col-6 lg:col-3">
					<div class="card widget-feature-box">
						<img src="/assets/icon-diamond.svg" alt="diamond" />
						<h2>Premium Support</h2>
						<span>Support is handled by JRMS Team.</span>
					</div>
				</div>
			</div>
		</div>

		<div id="features" class="landing-features">
			<div class="feature-row">
				<div class="feature-text">
					<h2>Texas Army National Guard</h2>
					<span
						>The incredible versatility of the Texas Army National
						Guard enables us to respond to domestic emergencies,
						combat missions, counterdrug efforts, reconstruction
						missions and more—all with equal speed, strength and
						efficiency.</span
					>
				</div>

				<div class="feature-image">
					<img
						class="lazy"
						alt="feature"
						src="/assets/soldier-photo/soldier-smiling.jpg"
						data-src="/assets/soldier-photo/soldier-smiling.jpg"
						data-srcset="/assets/soldier-smiling.jpg"
					/>
				</div>
			</div>

			<div class="feature-row row-reverse">
				<div class="feature-text">
					<h2>Texas Air National Guard</h2>
					<span
						>The Texas Air National Guard, (TXANG) is a component of
						the Texas Military Forces. We operate in a climate of
						respect and provide leadership and personal growth
						opportunities for our members. Our Citizen-Airmen live
						in the community, and serve at Wings and Squadrons
						throughout the great state of Texas.</span
					>
				</div>

				<div class="feature-image">
					<img
						class="lazy"
						alt="feature"
						src="/assets/soldier-photo/airmen-walking.jpg"
						data-src="/assets/soldier-photo/airmen-walking.jpg"
						data-srcset="/assets/soldier-photo/airmen-walking.jpg"
					/>
				</div>
			</div>

			<div class="feature-row">
				<div class="feature-text">
					<h2>Texas State Guard</h2>
					<span
						>The mission of the Texas State Guard (TXSG) is to
						provide mission-ready military forces to assist state
						and local authorities in times of state emergencies; to
						conduct homeland security and community service
						activities under the umbrella of Defense Support to
						Civil Authorities, and to augment the Texas Army
						National Guard and Texas Air National Guard as
						required.</span
					>
				</div>

				<div class="feature-image">
					<img
						class="lazy"
						alt="feature"
						src="/assets/soldier-photo/State-Guard.jpg"
						data-src="/assets/soldier-photo/State-Guard.jpg"
						data-srcset="/assets/soldier-photo/State-Guard.jpg"
					/>
				</div>
			</div>
		</div>

		<div id="pricing" class="landing-pricing">
			<!-- <div class="section-title">
        <h1>Our Pricing</h1>
        <span>The best price for our customers</span>
      </div> -->

			<div class="pricing-cards">
				<div class="widget-pricing-card">
					<h2>
						TMD Sexual<br />
						Harassment/Assault<br />
						Support Line
					</h2>

					<ul>
						<li>(512) 565-4410</li>
					</ul>

					<a href="#">Learn More</a>
				</div>

				<div class="widget-pricing-card">
					<h2>
						TMD<br />
						Counseling Line <br /><br />
					</h2>

					<ul>
						<li>(512) 782-5069</li>
						<li></li>
					</ul>

					<a href="#">Learn More</a>
				</div>

				<div class="widget-pricing-card">
					<h2>
						Veteran<br />
						Crisis Hotline <br /><br />
					</h2>

					<ul>
						<li>1 (800) 273-8255</li>
						<li></li>
					</ul>

					<a href="#">Learn More</a>
				</div>

				<div class="widget-pricing-card">
					<h2>
						24/7 TMD<br />
						Family Assistance <br /><br />
					</h2>

					<ul>
						<li>1 (800) 252-8032</li>
						<li></li>
					</ul>

					<a href="#">Learn More</a>
				</div>
			</div>
		</div>

		<div id="contact" class="landing-contact">
			<div class="section-title">
				<!-- <h1>Let’s Get In Touch</h1>
        <span>Just drop us an email</span> -->
			</div>

			<div class="contact-wrapper">
				<div class="contact-left">
					<div class="card-contact">
						<h3>
							* USE OF THIS SYSTEM CONSTITUTES CONSENT TO
							MONITORING FOR ALL LAWFUL PURPOSES.
						</h3>
					</div>
				</div>

				<div class="contact-right">
					<div class="contact-text">
						<!-- <h1>
            *Feel free to contact us and we will get <br />
            back to you <br />
            as soon as we can.
          </h1> -->
						<div class="contact-info">
							<div class="info">
								<h2>Opening Hours</h2>
								<p>
									Monday - Friday <br />
									8am - 4pm <br />
									Weekend <br />
									Closed
								</p>
							</div>

							<div class="info">
								<h2>Address</h2>
								<p>
									2200 West 35th Street, Building 1<br />
									Camp Mabry (Austin)<br />
									Texas 78763-5218
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="landing-footer">
				<a href="#" class="logo">
					<img src="/assets/TMD-Logo-250.png" alt="logo" />
					<h1>Texas Military Department</h1>
				</a>
				<!-- <div class="social">
      <a href="#"><i class="pi pi-github"></i></a>
      <a href="#"><i class="pi pi-twitter"></i></a>
    </div> -->
			</div>

			<div class="landing-mask"></div>
		</div>
	</div>
</div>
