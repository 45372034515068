import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { SpinnerService } from './services/spinner.service';
import { UserService } from './services/user.service';
import { AppMainComponent } from './app.main.component';
import { AuthService } from './auth/auth.service';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit, OnDestroy {
	public username = '';
	public memberId: number = 0;
	public subscription: Subscription;
	public items: MenuItem[];

	public constructor(
		public breadcrumbService: BreadcrumbService,
		private authService: AuthService,
		public appMain: AppMainComponent,
		public spinnerSvc: SpinnerService,
		private userService: UserService
	) {
		this.subscription = breadcrumbService.itemsHandler.subscribe(
			(response) => (this.items = response)
		);
	}

	public ngOnInit(): void {
		this.username = this.userService.info?.email ?? '';
		this.memberId = this.userService.myMemberData?.memberID ?? 0;
	}

	public ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	public async logout(): Promise<void> {
		await this.authService.logout();
	}
}
