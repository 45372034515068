<p-toast position="top-right"></p-toast>
<div
	class="layout-wrapper"
	[ngClass]="{
		'layout-overlay': app.menuMode === 'overlay',
		'layout-static': app.menuMode === 'static',
		'layout-slim': app.menuMode === 'slim',
		'layout-horizontal': app.menuMode === 'horizontal',
		'layout-sidebar-dim': app.colorScheme === 'dim',
		'layout-sidebar-dark': app.colorScheme === 'dark',
		'layout-overlay-active': overlayMenuActive,
		'layout-mobile-active': staticMenuMobileActive,
		'layout-static-inactive':
			staticMenuDesktopInactive && app.menuMode === 'static',
		'p-input-filled': app.inputStyle === 'filled',
		'p-ripple-disabled': !app.ripple
	}"
	[class]="app.colorScheme === 'light' ? app.menuTheme : ''"
	[attr.data-theme]="app.colorScheme"
	(click)="onLayoutClick()"
>
	<div class="layout-content-wrapper">
		<app-topbar></app-topbar>

		<div class="layout-content">
			@for (item of items; track item; let last = $last) {
				<span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
			}

			<router-outlet></router-outlet>
		</div>

		<app-footer></app-footer>
	</div>
</div>
