import { ASIDto } from '../models/ASIDto';
import { ISerializer } from './ISerializer';

export class ASISerializer implements ISerializer<ASIDto> {
	public fromJson(json: Record<string, unknown>): ASIDto {
		const asiDto = new ASIDto();
		asiDto.id = json['id'] as number;
		asiDto.code = json['code'] as string;
		asiDto.name = json['name'] as string;
		// Map other properties from JSON to ASIDto
		return asiDto;
	}

	public toJson(entity: ASIDto): Record<string, unknown> {
		return {
			id: entity.id,
			code: entity.code,
			name: entity.name,
			// Map other properties from ASIDto to JSON
		};
	}
}
