import { Component, Input, OnChanges } from '@angular/core';
import { MemberService } from '../../services/member.service';
import { GlobalService } from '../../services/globals.service';
import { MessageService } from 'primeng/api';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MemberModel, MyMember } from 'src/app/interfaces/MemberModel';
import { MemberListItem } from 'src/app/interfaces/PaginationTabelModel';
// import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'app-member-list',
	templateUrl: './member-list.component.html',
	providers: [MemberService, GlobalService],
})
export class MemberListComponent implements OnChanges {
	@Input()
	public searchMembers: MemberListItem[];

	@Input()
	public myMember: MyMember;

	@Input()
	public activateLink;

	public viewMember: MemberModel;
	public display: boolean = false;
	// public myMember: any;
	public editMember: MemberModel;
	public success: boolean;
	public result: string[];
	public count: number;
	public loading: boolean = false;
	private selectedId: number;

	public constructor(
		private memberService: MemberService,
		private globals: GlobalService,
		private messageService: MessageService,
		private spinnerService: SpinnerService
	) {}

	public ngOnChanges(): void {
		if (this.searchMembers) {
			this.count = this.searchMembers.length;
		}

		// console.log('change',this.searchMembers)
	}

	public saveCSV(): void {
		const selection: number[] = [];
		if (Array.isArray(this.searchMembers)) {
			for (const member of this.searchMembers) {
				if (member && typeof member.memberID === 'number') {
					selection.push(member.memberID);
				}
			}
		}
		this.spinnerService.showSpinner = true;
		this.memberService.memberDownload(selection).subscribe({
			next: (result: string[]) => {
				this.result = result;
				const csvStr: string = this.result.join('\n');
				this.globals.downloadCsv(csvStr, 'members');
				this.spinnerService.showSpinner = false;
			},
			complete: () => {},
			error: (err: MessageServiceType) => {
				this.messageService.add({
					severity: 'error',
					summary: 'HTTP Error',
					detail: `${err.message}`,
				});
				this.spinnerService.showSpinner = false;
			},
		});
	}
}
