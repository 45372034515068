import { DutyDefinitionFullDto } from '../models';
import { ISerializer } from './ISerializer';

export class DutyDefinitionSerializer implements ISerializer<DutyDefinitionFullDto> {
	public fromJson(json: Record<string, unknown>): DutyDefinitionFullDto {
		const dutyDefinition: DutyDefinitionFullDto = new DutyDefinitionFullDto();
		dutyDefinition.asi1 = json['asi1'] as string;
		dutyDefinition.asi1Id = json['asi1Id'] as number;
		dutyDefinition.asi2 = json['asi2'] as string;
		dutyDefinition.asi2Id = json['asi2Id'] as number;
		dutyDefinition.asi3 = json['asi3'] as string;
		dutyDefinition.asi3Id = json['asi3Id'] as number;
		dutyDefinition.asi4 = json['asi4'] as string;
		dutyDefinition.asi4Id = json['asi4Id'] as number;
		dutyDefinition.auth = json['auth'] as boolean;
		dutyDefinition.dutyPosition = json['dutyPosition'] as string;
		dutyDefinition.fte = json['fte'] as boolean;
		dutyDefinition.grade = json['grade'] as string;
		dutyDefinition.id = json['id'] as number;
		dutyDefinition.ln = json['ln'] as string;
		dutyDefinition.milcivexper = json['milcivexper'] as string;
		dutyDefinition.paraNo = json['paraNo'] as number;
		dutyDefinition.paraTitle = json['paraTitle'] as string;
		dutyDefinition.pmosCode = json['pmosCode'] as string;
		dutyDefinition.pmosId = json['pmosId'] as number;
		dutyDefinition.pmosName = json['pmosName'] as string;
		dutyDefinition.req = json['req'] as boolean;
		dutyDefinition.rsmosCode = json['rsmosCode'] as string;
		dutyDefinition.rsmosId = json['rsmosId'] as number;
		dutyDefinition.rsmosName = json['rsmosName'] as string;
		dutyDefinition.sqi = json['sqi'] as string;
		// Map other properties from JSON to DutyDefinitionFullDto
		return dutyDefinition;
	}

	public toJson(item: DutyDefinitionFullDto): Record<string, unknown> {
		return {
			asi1: item.asi1,
			asi1Id: item.asi1Id,
			asi2: item.asi2,
			asi2Id: item.asi2Id,
			asi3: item.asi3,
			asi3Id: item.asi3Id,
			asi4: item.asi4,
			asi4Id: item.asi4Id,
			auth: item.auth,
			dutyPosition: item.dutyPosition,
			fte: item.fte,
			grade: item.grade,
			id: item.id,
			ln: item.ln,
			milcivexper: item.milcivexper,
			paraNo: item.paraNo,
			paraTitle: item.paraTitle,
			pmosCode: item.pmosCode,
			pmosId: item.pmosId,
			pmosName: item.pmosName,
			req: item.req,
			rsmosCode: item.rsmosCode,
			rsmosId: item.rsmosId,
			rsmosName: item.rsmosName,
			sqi: item.sqi,
			// Map other properties from DutyDefinitionFullDto to JSON
		};
	}
}