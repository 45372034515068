import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { Operation } from 'src/app/interfaces/operation';
import { RmsService } from 'src/app/services/rms.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { filter } from 'rxjs/operators';

interface IParams {
	operationId: number | null;
	memberId: number | number[] | null;
	dates: Date | Date[] | null;
}

@Component({
	selector: 'app-operation-date-picker',
	templateUrl: './operation-date-picker.component.html',
	styleUrls: ['./operation-date-picker.component.scss'],
})
export class OperationDatePickerComponent implements OnInit {
	// Operation
	public operations: Operation[] = [];
	public operation: Operation = null;

	@Input()
	public watchForQueryParams: boolean = false;

	@Input()
	public showClear: boolean = false;

	@Input()
	public showDatePicker: boolean = true;

	// Duty Date(s)
	@Input()
	public dateLabel: string = 'Duty Date';

	@Input()
	public datesInTandem: boolean = true;

	@Input()
	public datePickerMode: 'single' | 'range' = 'single';

	@ViewChild('dutyDateRangeCal')
	public dutyDateRangeCal: Calendar;

	public operationDateStart: Date;
	public operationDateEnd: Date = this.getEndOfTodayDate();
	public dutyDateValue: Date | Date[] = new Date();
	public dutyDateDisabled: boolean = true;
	public operationsDisabled: boolean = true;
	public DatesDisabled: boolean = true;

	public init: boolean = false;

	@Output()
	public onChange = new EventEmitter<{
			operation: Operation,
			date: Date,
			endDate?: Date
		}>();

	public constructor(
		private router: Router,
		private route: ActivatedRoute,
		private rmsService: RmsService,
		private spinnerService: SpinnerService,
		private messageService: MessageService,
	) {

		this.operationsDisabled = false;

	}

	public async ngOnInit(): Promise<void> {
		await this.initAsync();

		this.router.events
			.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
			.subscribe(event => {
				if (
					event.id === 1 &&
					event.url === event.urlAfterRedirects
				) {
					this.operations = [];
				}
			});
		if (!this.showDatePicker) {
			this.datesInTandem = false;
		}

		this.dutyDateDisabled = this.datesInTandem;
	}

	public async onOperationChange() {
		this.DatesDisabled = false;
		this.operationDateStart = this.operation ? new Date(this.operation.startDate) : undefined;
		if (this.datesInTandem) {
			//this.activateCalendar();

			if (this.checkDates()) {
				this.emitChange();
			}
		}
		else {
			this.emitChange();
			await this.setParams();
		}
	}

	public onOperationChangeEnable(): void {
		this.DatesDisabled = false;
	}

	public async onDutyDateRangeChange(): Promise<void> {
		//pull back operations for date
		//populate dropdown with appropriate operations
		this.operationsDisabled = false;
		this.DatesDisabled = true;
		if (this.checkDates()) {
			this.dutyDateRangeCal.toggle();

			const startDate = Array.isArray(this.dutyDateValue) ?
				this.dutyDateValue[0] :
				this.dutyDateValue;
			const endDate = Array.isArray(this.dutyDateValue) ?
				new Date(this.dutyDateValue[1]) :
				null;

			await this.SetOpsInDateRange(new Date(startDate), endDate);


			if (this.watchForQueryParams) {
				await this.setParams();
			}
		}
	}

	public async onClear(): Promise<void> {
		this.emitChange();

		if (this.watchForQueryParams) {
			await this.setParams();
		}
	}

	private emitChange() {
		if (this.operation) {
			this.onChange.emit({
				operation: this.operation,
				date: Array.isArray(this.dutyDateValue) ? this.dutyDateValue[0] : this.dutyDateValue,
				endDate: Array.isArray(this.dutyDateValue) ? this.dutyDateValue[1] : null
			});
		}
	}

	private checkDates(): boolean {
		return (
			Array.isArray(this.dutyDateValue) &&
			this.dutyDateValue?.every((x) => x !== null)
		) ||
			this.dutyDateValue instanceof Date;
	}

	private async initAsync(): Promise<void> {
		this.spinnerService.showSpinner = true;

		this.operations = await this.getOperations();

		this.spinnerService.showSpinner = false;

		if (!this.watchForQueryParams) {
			return;
		}

		const params = this.getParams();

		if (params === null) {
			return;
		}

		setTimeout(() => {
			this.operation = this.operations.find(x => x.operationID === Number(params.operationId)) ?? null;
			this.dutyDateValue = params.dates;

			void this.onOperationChange();
			this.emitChange();
		}, 1);
	}

	private async getOperations(): Promise<Operation[]> {
		try {
			return await this.rmsService.getOperations().toPromise();
		}
		catch (error: unknown) {
			this.handlerError(error, 'Error Fetching Operations');
		}

		return [];
	}
	private async SetOpsInDateRange(startDate: Date, endDate: Date): Promise<void> {
		this.operations = await this.GetOpsInDateRange(startDate, endDate);
	}

	private async GetOpsInDateRange(startDate: Date, endDate: Date): Promise<Operation[]> {
		try {
			return await this.rmsService.getOperationsByDate(startDate, endDate, true).toPromise();
		}
		catch (error: unknown) {
			this.handlerError(error, 'Error Fetching Operations');
		}

		return [];
	}

	private getParams(): IParams {
		const operationId = this.route.snapshot.queryParams?.operationId as string | null;
		const memberId = this.route.snapshot.queryParams?.memberId as string | null;
		const dates = this.route.snapshot.queryParams?.dates as string | string[] | null;

		const parsedOperationId = operationId !== null ? parseInt(operationId, 10) : null;
		const parsedMemberId = Array.isArray(memberId)
			? memberId.map((x: string) => parseInt(x, 10))
			: memberId !== null
				? parseInt(memberId, 10)
				: null;

		const parseDates = (dateString: string): Date | null => {
			const parsedDate = Date.parse(dateString);
			return isNaN(parsedDate) ? null : new Date(parsedDate);
		};

		const parsedDates = Array.isArray(dates)
			? dates.map(parseDates).filter((date): date is Date => date !== null)
			: dates !== null
				? parseDates(dates)
				: null;

		return {
			operationId: isNaN(parsedOperationId) ? null : parsedOperationId,
			memberId: Array.isArray(parsedMemberId)
				? parsedMemberId.map(x => isNaN(x) ? null : x)
				: isNaN(parsedMemberId)
					? null
					: parsedMemberId,
			dates: parsedDates,
		};
	}

	private async setParams(): Promise<void> {
		const queryParams: {
			operationId?: number,
			dates?: string | string[]
		} = {};

		if (this.operation) {
			queryParams.operationId = this.operation.operationID;
		}

		if (this.dutyDateValue) {
			queryParams.dates = Array.isArray(this.dutyDateValue) ?
				this.dutyDateValue?.map(x => this.getYearMonthDayDateString(x)) :
				this.getYearMonthDayDateString(this.dutyDateValue);
		}

		await this.router.navigate([], {
			relativeTo: this.route,
			queryParams,
			replaceUrl: !this.init,
		});

		if (!this.init) {
			this.init = true;
		}
	}

	private getYearMonthDayDateString(dateString: Date) {
		const date = new Date(dateString);

		return `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}`;
	}

	private activateCalendar() {
		if (this.operation !== null) {
			this.dutyDateDisabled = false;
		}
	}

	private getEndOfTodayDate() {
		const date = new Date();

		date.setUTCHours(23, 59, 59, 999);

		return date;
	}

	private handlerError(error: MessageServiceType, summary: string = 'HTTP Error') {
		this.messageService.add({
			severity: 'error',
			summary,
			detail: error.message,
		});
	}
}
