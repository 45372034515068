/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => {
		const errorMessage = err instanceof Error ? err.message : JSON.stringify(err);
		const statusText = err instanceof HttpErrorResponse ? err.statusText : JSON.stringify(err);
		if (err) {
			const loader = document.querySelector('.loader-container');
			const errorContainer = document.createElement('div');
			const logo = document.createElement('img');
			const content = document.createElement('div');
			const header: HTMLDivElement = document.createElement('div');
			const message: HTMLDivElement = document.createElement('div');

			logo.classList.add('logo');
			logo.src = 'assets/images/Logos/jrms_icon_logo.png';
			logo.alt = 'JRMS';

			header.classList.add('exception-title');
			header.textContent = statusText;

			message.classList.add('exception-detail');
			message.textContent = errorMessage;

			content.classList.add('exception-content');
			content.append(header, message);

			errorContainer.classList.add('exception-body', 'error');
			errorContainer.append(content);

			loader.parentNode.append(errorContainer);
			loader.remove();
		}
	});
