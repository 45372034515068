<div class="card mb-5">
	<div class="p-fluid p-formgrid grid">
		@if (showDatePicker) {
		<div class="field col-12 md:col-2 mb-0">
			<label for="dutyDateRange"
				>{{ dateLabel
				}}{{ datePickerMode === "range" ? " Range" : "" }}</label
			>
			<p-calendar
				#dutyDateRangeCal
				inputId="dutyDateRange"
				selectionMode="{{ datePickerMode }}"
				placeholder="Select Date{{
					datePickerMode === 'range' ? ' Range' : ''
				}}"
				[(ngModel)]="dutyDateValue"
				[readonlyInput]="true"
				[showIcon]="true"
				(onSelect)="onDutyDateRangeChange()"
				[maxDate]="operationDateEnd"
				[showClear]="showClear"
				(onClear)="onClear()"
			>
			</p-calendar>
		</div>
		}

		<div class="field col-12 md:col-2 mb-0">
			<label id="operationInput">Operation</label>

			<p-dropdown
				inputId="operationInput"
				[options]="operations"
				[(ngModel)]="operation"
				[disabled]="operationsDisabled"
				(onChange)="onOperationChangeEnable()"
				optionLabel="operationDescription"
				placeholder="Select Operation"
				[showClear]="showClear"
				(onClear)="onClear()"
			>
			</p-dropdown>
		</div>
		<!-- (operations.length==0?true:false)&& -->

		<div class="field col-12 md:col-2 mb-0" style="margin-top: 25px">
			<button
				pTooltip="Search for Candidates"
				tooltipPosition="left"
				style="width: 100px"
				pButton
				type="submit"
				label="Search"
				(click)="onOperationChange()"
				icon="pi pi-search"
			></button>
		</div>

		<div class="right-box">
			<ng-content></ng-content>
		</div>
	</div>
</div>
