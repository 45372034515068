import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
	selector: 'app-login',
	templateUrl: './app.login.component.html',
})
export class AppLoginComponent implements OnInit {
	public constructor(
		private router: Router,
		private authService: AuthService
	) {}

	public async ngOnInit(): Promise<void> {
		const authenticated = await this.authService.authenticated();

		if (authenticated) {
			await this.router.navigate(['/sec'], { replaceUrl: true });
		}
		else {
			await this.router.navigate(['/']);
		}
	}
}
