import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'app-deactivate-confirm',
	template: `
    <p>
      Changes will be lost. <br>

      <button pButton type="button" label="OK" (click)="ok()"></button>
      <button class="ml-2" pButton type="button" label="Cancel" (click)="cancel()"></button>

    </p>
  `,
})
export class DeactivateConfirmComponent {

	public constructor(public ref: DynamicDialogRef) { }

	public ok() {
		this.ref.close(true);
	}

	public cancel() {
		this.ref.close(false);
	}

}
