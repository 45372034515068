@if (!searchMembers) {
<div>No records to display.</div>
} @if (searchMembers) {
<div>
	<p-table
		[value]="searchMembers"
		[paginator]="true"
		[rows]="10"
		[showCurrentPageReport]="true"
		responsiveLayout="scroll"
		currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
		[rowsPerPageOptions]="[10, 25, 50]"
	>
		<ng-template pTemplate="header">
			<tr>
				<!-- <th>Action</th> -->
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(
							searchMembers,
							'userName',
							$event
						)
					"
				>
					UserName
				</th>
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(
							searchMembers,
							'rankName',
							$event
						)
					"
				>
					Rank
				</th>
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(
							searchMembers,
							'lastName',
							$event
						)
					"
				>
					LastName
				</th>
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(
							searchMembers,
							'firstName',
							$event
						)
					"
				>
					FirstName
				</th>
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(
							searchMembers,
							'component',
							$event
						)
					"
				>
					Branch
				</th>
				<th
					class="sortable"
					(click)="
						globals.TableColumnSort(searchMembers, 'unit', $event)
					"
				>
					Unit
				</th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-member>
			<tr>
				<td>
					<a
						routerLink="/sec/member/members/{{ member.memberID }}"
						p-tooltip="Go to member"
						>{{ member.userName }}</a
					>
				</td>
				<td>{{ member.rankName }}</td>
				<td>{{ member.lastName }}</td>
				<td>{{ member.firstName }}</td>
				<td>{{ member.component }}</td>
				<td>{{ member.unit }}</td>
			</tr>
		</ng-template>
	</p-table>
	<div style="float: right">
		<i>{{ searchMembers?.length }} Members Displayed</i>
	</div>
	<button pButton (click)="saveCSV()" (show)="(searchMembers?.length > 0)">
		Download
	</button>
</div>
}
