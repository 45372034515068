import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { LoginAlertComponent } from '../login-alert/login-alert.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../services/user.service';
import { RmsService } from '../services/rms.service';
import { MaintenanceBanner } from '../interfaces/maintenance-landing-banner';
import { LazyImage } from '../interfaces/lazy-image';

@Component({
	selector: 'app-landing',
	templateUrl: './landing.component.html',
	styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, AfterViewInit {
	public loginText: string = 'Login';
	public build = 'build not read';
	public envName: string;
	public items = [{ label: 'Login', routerLink: ['/sec'] }];
	public bannerVerbiage: string;
	public activeB: boolean = false;

	public constructor(
		private messageService: MessageService,
		private dialogService: DialogService,
		private authService: AuthService,
		private configService: ConfigService,
		private userService: UserService,
		private rmsService: RmsService
	) {}

	public ngOnInit(): void {
		this.build = this.configService.build;
		this.envName = environment.name;
		this.showLoginAlert();

		if (this.userService.authenticated) {
			this.loginText = 'Dashboard';
		}
	}

	public async login() {
		this.loginText = 'Logging in...';
		await this.authService.login();
	}

	public showLoginAlert(): void {
		// This code will allow dismissal of the alert for the day and will reset at 12 midnight.
		const dismissedDate = window.localStorage.getItem('loginAlert');
		const showDate = new Date(dismissedDate);
		const newDate = new Date();

		showDate.setDate(showDate.getDate() + 1);
		newDate.setHours(0, 0, 0, 0);

		if (newDate >= showDate) {
			this.dialogService
				.open(LoginAlertComponent, {
					width: '500px',
					contentStyle: { 'text-align': 'center' },
					closable: false,
				})
				.onClose.subscribe(() => {
					window.localStorage.setItem('loginAlert', newDate.toJSON());
				});
		}
	}

	public ngAfterViewInit(): void {
		let lazyImages: LazyImage[] = Array.from(document.querySelectorAll('img.lazy'));
		let active = false;

		const lazyLoad = function () {
			if (active === false) {
				active = true;

				setTimeout(function () {
					lazyImages.forEach(function (lazyImage) {
						if (
							lazyImage.getBoundingClientRect().top <=
								window.innerHeight &&
							lazyImage.getBoundingClientRect().bottom >= 0 &&
							getComputedStyle(lazyImage).display !== 'none'
						) {
							lazyImage.src = lazyImage.dataset.src;
							lazyImage.classList.remove('lazy');

							setTimeout(function () {
								lazyImage.classList.add('animated');
							}, 150);

							lazyImages = lazyImages.filter(function (image) {
								return image !== lazyImage;
							});

							if (lazyImages.length === 0) {
								document.removeEventListener(
									'scroll',
									lazyLoad
								);
								window.removeEventListener('resize', lazyLoad);
								window.removeEventListener(
									'orientationchange',
									lazyLoad
								);
							}
						}
					});

					active = false;
				}, 0);
			}
		};

		lazyLoad();

		document.addEventListener('scroll', lazyLoad);
		window.addEventListener('resize', lazyLoad);
		window.addEventListener('orientationchange', lazyLoad);
		this.checkBannerCampaign();
	}

	public openMenu() {
	}

	public checkBannerCampaign(){
		let bannerScope: MaintenanceBanner[];
		this.rmsService.getScheduledBanners().subscribe({
			next: (result: MaintenanceBanner[]) => {
				bannerScope = result;
			},
			complete: () => {
				bannerScope.forEach( (element) =>{
					this.activeBanner(element.verbiage, element.release_date, element.expiration_date);
				});

			},
			error: (err: MessageServiceType) => {
				this.messageService.add({
					severity: 'error',
					summary: 'HTTP Error',
					detail: `${err.message}`,
				});
			}
		});
	}

	public activeBanner(verbiage: string, release: string, expiration: string){

		this.bannerVerbiage = verbiage;
		const displayBanner: Date =  new Date(release);
		const expirationDate: Date = new Date(expiration);

		const now = new Date();


		if(now > displayBanner && now < expirationDate){
			this.activeB =  true;
			return this.activeB;
		}

		return this.activeB;
	}

	public closeMenu() {}
}
