import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { ConfigService } from './services/config.service';

@Component({
	selector: 'app-footer',
	templateUrl: './app.footer.component.html',
})
export class AppFooterComponent implements OnInit {
	public build = 'build not read';

	public constructor(
		public app: AppComponent,
		private configService: ConfigService
	) {}

	public ngOnInit(): void {
		this.build = this.configService.build;
	}
}
