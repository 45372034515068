<div class="login-alert-container">
	<h2>Government Computer System Warning!</h2>
	<p>
		----------------------------------------------------<br>
		Unauthorized access to this computer system or unauthorized use of any confidential or federal tax information
		may constitute a violation of federal law and give rise to civil and criminal liability and penalties. This
		system is subject to monitoring and stored data may be accessed and recorded. Anyone using this system consents
		to monitoring and should have no expectation of privacy. Evidence of criminal liability will be provided to law
		enforcement agencies.</p>

	<p>
		This website is intended for use by State of Texas employees and others expressly authorized by State of Texas.
		Attempting to access this website without authorization may violate criminal laws and subject you to fines and
		imprisonment. Offenders will be prosecuted.
		<br>----------------------------------------------------
	</p>
</div>

<div class="login-alert-acknowledge">
	<br>
	<p-button label="Acknowledge" styleClass="p-button-warning" (click)="closeAlert()"></p-button>
	<p-button label="Leave" styleClass="p-button-danger" (click)="leaveSite()"></p-button>
</div>
