import { BattalionFullDto } from '../models';
import { ISerializer } from './ISerializer';

export class BattalionSerializer implements ISerializer<BattalionFullDto> {
	public fromJson(json: Record<string, unknown>): BattalionFullDto {
		const brigade = new BattalionFullDto();
		brigade.brigade = json['brigade'] as string;
		brigade.brigadeId = json['brigadeId'] as number;
		brigade.id = json['id'] as number;
		brigade.name = json['name'] as string;
		// Map other properties from JSON to BattalionFullDto
		return brigade;
	}

	public toJson(entity: BattalionFullDto): Record<string, unknown> {
		return {
			brigade: entity.brigade,
			brigadeId: entity.brigadeId,
			id: entity.id,
			name: entity.name,
			// Map other properties from BattalionFullDto to JSON
		};
	}
}