import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class BreadcrumbService {
	private itemsSource = new BehaviorSubject<MenuItem[]>([{ label: 'Dashboard', routerLink: ['/'] }]);
	public itemsHandler = this.itemsSource.asObservable();

	public setItems(items: MenuItem[]) {
		this.itemsSource.next(items);
	}
}