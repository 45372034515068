import {Component, OnInit} from '@angular/core';
import { IconService } from '../demo/service/iconservice';
import {BreadcrumbService} from '../app.breadcrumb.service';
import { IconData } from '../interfaces/icon';

@Component({
	templateUrl: './icons.component.html',
})
export class IconsComponent implements OnInit {

	public icons: IconData[];
	public filteredIcons: IconData[];
	public selectedIcon: IconData;

	public constructor(private iconService: IconService, private breadcrumbService: BreadcrumbService) {
		this.breadcrumbService.setItems([
			{label: 'Icons'}
		]);
	}

	public ngOnInit() {
		this.iconService.getIcons().subscribe(data => {
			data = data.filter(value => {
				return value.icon.properties.tags.indexOf('deprecate') === -1;
			});

			const icons = data;
			icons.sort((icon1, icon2) => {
				if(icon1.icon.properties.name < icon2.icon.properties.name)
					return -1;
				else if(icon1.icon.properties.name < icon2.icon.properties.name)
					return 1;
				else
					return 0;
			});

			this.icons = icons;
			this.filteredIcons = data;
		});
	}

	public onFilter(event: KeyboardEvent): void {
		const searchText = (event.target as HTMLInputElement).value;

		if (!searchText) {
			this.filteredIcons = this.icons;
		}
		else {
			this.filteredIcons = this.icons.filter( it => {
				return it.icon.properties.tags[0].includes(searchText);
			});
		}
	}
}
