import { Injectable } from '@angular/core';
import { MyMember } from '../interfaces/MemberModel';
import { Permission, Role } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export type UserInfo = {
	given_name: string;
	family_name: string;
	name: string;
	guid: string;
	uid: string;
	mail: string;
	email: string;
	sub: string;
	subname: string;
};

@Injectable({ providedIn: 'root' })
export class UserService {
	private baseUrl = environment.api;
	public authenticated = false;
	public info: UserInfo = null;
	public roles: Role[] = null;
	public permissions: Permission[] = null;
	public myMemberData: MyMember = null;

	public constructor(private http: HttpClient) {}

	public resetUser() {
		this.authenticated = false;
		this.info = null;
		this.roles = null;
		this.permissions = null;
		this.myMemberData = null;
	}

	public async getUserSecurityInfo() {
		await Promise.all([
			this.getRolesForCurrentUser(),
			this.getPermissionsForCurrentUser()
		]);
	}

	public async getRolesForCurrentUser(): Promise<Role[]> {
		if (this.roles === null) {
			this.roles = await this.http.get<Role[]>(`${this.baseUrl}api/Security/User/Roles`).toPromise();
		}

		return this.roles;
	}

	public async getPermissionsForCurrentUser(): Promise<Permission[]> {
		if (this.permissions === null) {
			this.permissions = await this.http.get<Permission[]>(`${this.baseUrl}api/Security/User/Permissions`).toPromise();
		}

		return this.permissions;
	}
}
