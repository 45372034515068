import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { OAuth2Tokens, TokenManager } from '@forgerock/javascript-sdk';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return from(this.handle(req, next));
	}

	private async handle(req: HttpRequest<unknown>, next: HttpHandler): Promise<HttpEvent<unknown>> {
		try {
			const apiHostname = new URL(environment.api).hostname;
			const requestHostname = new URL(req.url).hostname;

			if (requestHostname && requestHostname === apiHostname) {
				const tokens = await TokenManager.getTokens({ login: 'redirect' }) as OAuth2Tokens;
				const authorizedRequest = req.clone({
					setHeaders: {
						Authorization: `Bearer ${tokens.idToken}`,
						"x-jrms-access": tokens.accessToken, // We will remove this once Megan adds the users info to the IdToken
					},
				});

				return next.handle(authorizedRequest)
					.pipe(tap(
						() => {},
						(error: unknown) => {
							if (
								error instanceof HttpErrorResponse &&
								error.status !== 401
							) {
								return;
							}

							// If somehow we get a 401 returned at this point, redirect
							// user to authenication gateway.
							TokenManager.getTokens({ login: 'redirect' })
								.catch(() => {});
						}
					))
					.toPromise();
			}
		}
		catch (error: unknown) {
			// console.log(req.url);
			// console.log(error);
		}

		return next.handle(req).toPromise();
	}
}
