import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FeatureFlagService } from './services/featureflag.service';
import { ConfigService } from './services/config.service';
import { AuthService } from './auth/auth.service';

// This function runs before everything else in the UI and will hold initializing the
// the rest of the app until it's done running. A good use of this would be to load
// the permissions here. Use sparingly.
export function initializeApp(
	authService: AuthService,
	configService: ConfigService,
	featureFlagService: FeatureFlagService
): () => Promise<void> {
	return async (): Promise<void> => {
		await Promise.all([
			featureFlagService.load(),
			configService.initialize(),
		]);

		if (await authService.authenticated()) {
			await Promise.all([
				await authService.authorize(),
				await authService.loadUserInfo(),
			]);
		}
	};
}

@NgModule({
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			useFactory: initializeApp,
			deps: [AuthService, ConfigService, FeatureFlagService],
		},
	],
})
export class InitializerModule {}
