import {
	HttpClient,
	HttpErrorResponse,
	HttpParams,
} from '@angular/common/http';
import { SkipSelf } from '@angular/core';
import { throwError } from 'rxjs';
import { BaseEntity, PaginatedList } from '../models';
import { ISerializer } from '../serializers';
import { CustomErrorResponse } from 'src/app/interfaces/custom-error-response';

export class GenericService<T extends BaseEntity> {
	public constructor(
		@SkipSelf() protected httpClient: HttpClient,
		protected url: string,
		protected endpoint: string,
		protected serializer: ISerializer<T>
	) {}

	public get(id: number): Promise<T> {
		return new Promise((resolve, reject) => {
			this.httpClient.get<T>(`${this.url}/${this.endpoint}/${id}`).subscribe({
				next: (data: T) => {
					const serializedData = this.serializer.fromJson(data as unknown as Record<string, unknown>);
					resolve(serializedData);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	public getAll(queryParams: HttpParams = null): Promise<T[]> {
		const params = queryParams !== null ? '?' + queryParams.toString() : '';

		return new Promise((resolve, reject) => {
			this.httpClient.get<T[]>(`${this.url}/${this.endpoint}${params}`).subscribe({
				next: (data: T[]) => {
					const convertedData = this.convertData(data);
					resolve(convertedData);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	public getPaginated(queryParams: HttpParams = null): Promise<PaginatedList<T>> {
		const params = queryParams !== null ? '?' + queryParams.toString() : '';

		return new Promise((resolve, reject) => {
			this.httpClient.get<PaginatedList<T>>(`${this.url}/${this.endpoint}/Page${params}`).subscribe({
				next: (data: PaginatedList<T>) => {
					const paginatedList = new PaginatedList<T>();

					paginatedList.hasNextPage = data.hasNextPage;
					paginatedList.hasPreviousPage = data.hasPreviousPage;
					paginatedList.items = data.items;
					paginatedList.pageNumber = data.pageNumber;
					paginatedList.totalCount = data.totalCount;
					paginatedList.totalPages = data.totalPages;

					resolve(paginatedList);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	public post(item: T, queryParams: HttpParams = null): Promise<T> {
		const params = queryParams !== null ? '?' + queryParams.toString() : '';

		return new Promise((resolve, reject) => {
			this.httpClient.post<T>(`${this.url}/${this.endpoint}${params}`, this.serializer.toJson(item)).subscribe({
				next: (data) => {
					const serializedData = this.serializer.fromJson(data as unknown as Record<string, unknown>);
					resolve(serializedData);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	public put(item: T, queryParams: HttpParams = null): Promise<T> {
		const params = queryParams !== null ? '?' + queryParams.toString() : '';

		return new Promise((resolve, reject) => {
			this.httpClient.put<T>(`${this.url}/${this.endpoint}/${item.id}${params}`, this.serializer.toJson(item)).subscribe({
				next: () => {
					resolve(item);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	public delete(id: number) {
		return new Promise((resolve, reject) => {
			this.httpClient.delete(`${this.url}/${this.endpoint}/${id}`).subscribe({
				next: () => {
					resolve(null);
				},
				error: (errorResponse: HttpErrorResponse | CustomErrorResponse) => {
					const handledError = this.handleErrors(errorResponse);
					reject(handledError);
				}
			});
		});
	}

	protected convertData(data: unknown[]): T[] {
		if (!Array.isArray(data)) {
			throw new Error('Invalid data format. Expected an array.');
		}

		return data.map((item) => {
			if (typeof item === 'object' && item !== null) {
				return this.serializer.fromJson(item as Record<string, unknown>); // Type assertion to satisfy the ISerializer interface
			} else {
				throw new Error('Invalid item format. Expected an object.');
			}
		});
	}

	protected handleErrors(errorResponse: HttpErrorResponse | CustomErrorResponse) {
		let errorSummary: string;
		let errorDetails: string;
		const errorFields: string[] = [];

		if (errorResponse instanceof HttpErrorResponse) {
			if (errorResponse.error instanceof ErrorEvent) {
				// client-side error
				errorSummary = errorResponse.error.message;
			} else {
				// server-side error
				// Forbidden
				if (errorResponse.status === 403) {
					errorSummary = 'Forbidden.';
					errorDetails = 'You do not have the appropriate permissions for this resource.';
				}
				// Internal Server Error
				else if (errorResponse.status === 500) {
					errorSummary = 'An error has occurred.';
					errorDetails = 'Something went wrong on the server. Please try again later.';
				}
				// Bad Request
				else if (errorResponse.status === 400) {
					errorSummary = (errorResponse.error as CustomErrorResponse).error?.title || 'Bad Request.';
				}
				// Not Found
				else if (errorResponse.status === 404) {
					errorSummary = (errorResponse.error as CustomErrorResponse).error?.detail || 'Not Found.';
				}
				// Conflict
				else if (errorResponse.status === 409) {
					errorSummary = (errorResponse.error as CustomErrorResponse).error?.detail || 'Conflict.';
				}
				// Anything else
				else {
					errorSummary = errorResponse.message;
				}
			}
		} else {
			// Custom error response
			errorSummary = errorResponse.error?.title || errorResponse.error?.detail || 'An unknown error occurred.';
		}

		if ((errorResponse as CustomErrorResponse).error?.errors) {
			const errors = (errorResponse as CustomErrorResponse).error.errors;
			for (const key in errors) {
				errorFields.push(errors[key]);
			}
			errorDetails = errorFields.length > 0 ? errorFields.join('\n') : '';
		}

		return throwError({
			summary: errorSummary || 'An unknown error occurred.',
			detail: errorDetails || '',
		});
	}
}
