export const AccCreate = 'AccCreate';
export const AccRead = 'AccRead';
export const AccUpdate = 'AccUpdate';
export const AccDelete = 'AccDelete';

export const AwardCreate = 'AwardCreate';
export const AwardRead = 'AwardRead';
export const AwardUpdate = 'AwardUpdate';
export const AwardDelete = 'AwardDelete';

export const Admin = 'Admin';

export const BasicUser = 'BasicUser';

export const OpsCalRead = 'OpsCalRead';

export const CategCreate = 'CategCreate';
export const CategRead = 'CategRead';
export const CategUpdate = 'CategUpdate';
export const CategDelete = 'CategDelete';

export const CompCreate = 'CompCreate';
export const CompRead = 'CompRead';
export const CompUpdate = 'CompUpdate';
export const CompDelete = 'CompDelete';

export const DutyDefCreate = 'DutyDefCreate';
export const DutyDefRead = 'DutyDefRead';
export const DutyDefUpdate = 'DutyDefUpdate';
export const DutyDefDelete = 'DutyDefDelete';

export const FacilCreate = 'FacilCreate';
export const FacilRead = 'FacilRead';
export const FacilUpdate = 'FacilUpdate';
export const FacilDelete = 'FacilDelete';

export const FetchSSN = 'FetchSSN';

export const LimitCreate = 'LimitCreate';
export const LimitRead = 'LimitRead';
export const LimitUpdate = 'LimitUpdate';
export const LimitDelete = 'LimitDelete';

export const MemberCreate = 'MemberCreate';
export const MemberRead = 'MemberRead';
export const MemberUpdate = 'MemberUpdate';
export const MemberDelete = 'MemberDelete';

export const MtoeCreate = 'MtoeCreate';
export const MtoeRead = 'MtoeRead';
export const MtoeUpdate = 'MtoeUpdate';
export const MtoeDelete = 'MtoeDelete';

export const MyAdmin = 'MyAdmin';

export const OccupCreate = 'OccupCreate';
export const OccupRead = 'OccupRead';
export const OccupUpdate = 'OccupUpdate';
export const OccupDelete = 'OccupDelete';

export const OpsCreate = 'OpsCreate';
export const OpsRead = 'OpsRead';
export const OpsUpdate = 'OpsUpdate';
export const OpsDelete = 'OpsDelete';

export const OrdCreate = 'OrdCreate';
export const OrdRead = 'OrdRead';
export const OrdUpdate = 'OrdUpdate';
export const OrdDelete = 'OrdDelete';

export const PackCreate = 'PackCreate';
export const PackRead = 'PackRead';
export const PackUpdate = 'PackUpdate';
export const PackDelete = 'PackDelete';

export const PayCreate = 'PayCreate';
export const PayRead = 'PayRead';
export const PayUpdate = 'PayUpdate';
export const PayDelete = 'PayDelete';

export const PermsCreate = 'PermsCreate';
export const PermsRead = 'PermsRead';
export const PermsUpdate = 'PermsUpdate';
export const PermsDelete = 'PermsDelete';

export const SADPermsCreate = 'SADPermsCreate';
export const SADPermsRead = 'SADPermsRead';
export const SADPermsUpdate = 'SADPermsUpdate';
export const SADPermsDelete = 'SADPermsDelete';

export const RecruitCreate = 'RecruitCreate';
export const RecruitRead = 'RecruitRead';
export const RecruitUpdate = 'RecruitUpdate';
export const RecruitDelete = 'RecruitDelete';

export const Reports = 'Reports';

export const SADOpsCreate = 'SADOpsCreate';
export const SADOpsRead = 'SADOpsRead';
export const SADOpsUpdate = 'SADOpsUpdate';
export const SADOpsDelete = 'SADOpsDelete';

export const SADOrdCreate = 'SADOrdCreate';
export const SADOrdRead = 'SADOrdRead';
export const SADOrdUpdate = 'SADOrdUpdate';
export const SADOrdDelete = 'SADOrdDelete';

export const SADReports = 'SADReports';
export const SADReportsDownload = 'SADReportDnld';

export const StrengthCreate = 'StrengthCreate';
export const StrengthRead = 'StrengthRead';
export const StrengthUpdate = 'StrengthUpdate';
export const StrengthDelete = 'StrengthDelete';

export const TrainCreate = 'TrainCreate';
export const TrainRead = 'TrainRead';
export const TrainUpdate = 'TrainUpdate';
export const TrainDelete = 'TrainDelete';

export const UnitCreate = 'UnitCreate';
export const UnitRead = 'UnitRead';
export const UnitUpdate = 'UnitUpdate';
export const UnitDelete = 'UnitDelete';

export const DashboardRead = 'DashboardRead';
