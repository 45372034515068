import { Component } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { FeatureFlagService } from '../services/featureflag.service';

@Component({
	selector: 'app-doc',
	templateUrl: './doc.component.html',
	styleUrls: [
		'./doc.component.scss'
	]
})
export class DocComponent {
	public feature: 'doc';

	public constructor(
		private breadcrumbService: BreadcrumbService,
		private featureFlagService: FeatureFlagService
	) {
		this.breadcrumbService.setItems([
			{ label: 'Personal Documentation', routerLink: ['/'] }
		]);
	}
	public isMyFeatureEnabled(): boolean {
		return this.featureFlagService.isFeatureEnabled('doc');
	}
}
