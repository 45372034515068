import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { SQIDto } from '../models/SQIDto';
import { SQISerializer } from '../serializers/SQISerializer';

@Injectable()
export class SQIService extends GenericService<SQIDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'SQIs',
			new SQISerializer()
		);
	}
}
