// login-alert.component.ts
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfigType } from '../interfaces/dynamic-dialog-config';

@Component({
	selector: 'app-login-alert',
	templateUrl: './login-alert.component.html',
	styleUrls: ['./login-alert.component.scss'],
})
export class LoginAlertComponent {
	public constructor(
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig
	) {}

	public get message(): string {
		return (this.config as DynamicDialogConfigType)?.data?.message || '';
	}

	public closeAlert(): void {
		this.ref.close();
	}

	public leaveSite(): void {
		window.location.href = 'https://tmd.texas.gov';
	}
}
