import { Injectable } from '@angular/core';
import { AppLinks } from '../interfaces/app-links';
import { MemberModel, MemberSimple, MyMember } from '../interfaces/MemberModel';
import { MemberRank } from '../interfaces/member-rank';
import { FemaEducationType, MilitaryEducationType } from '../interfaces/education-types';
import { MilitaryUnit } from '../interfaces/MilitaryUnit';

declare global {
	interface Navigator {
		msSaveOrOpenBlob: (blob: Blob, defaultName?: string) => boolean;
	}
}

// Not used
// interface IPartitionMembers {
// 	all: any[];
// 	officers: any[];
// 	warrants: any[];
// 	enlisted: any[];
// 	civilian: any[];
// 	aEmpty: boolean;
// 	oEmpty: boolean;
// 	wEmpty: boolean;
// 	eEmpty: boolean;
// 	cEmpty: boolean;
// }

@Injectable({
	providedIn: 'root',
})
export class GlobalService {
	private _isLoading = false;
	public myMember: MyMember = null;
	public activeStatus = [
		{
			name: 'Active',
			value: true,
		},
		{
			name: 'Inactive',
			value: false,
		},
	];
	public suspendedStatus = [
		{
			name: 'Suspended',
			value: true,
		},
		{
			name: 'Not Suspended',
			value: false,
		},
		{
			name: 'All',
			value: null,
		},
	];
	public priorServiceDischargeType = [
		{
			value: 1,
			label: 'HONORABLE',
		},
		{
			value: 2,
			label: 'DISHONORABLE',
		},
		{
			value: 3,
			label: 'NA',
		},
	];
	public components = [
		{
			id: 2,
			value: 2,
			label: 'TXARNG',
			name: 'TXARNG',
		},
		{
			id: 3,
			value: 3,
			label: 'TXANG',
			name: 'TXANG',
		},
		{
			id: 4,
			value: 4,
			label: 'TXSG',
			name: 'TXSG',
		},
	];
	public branchTypes = [
		{
			id: 1,
			name: 'Army',
		},
		{
			id: 2,
			name: 'Air',
		},
		{
			id: 29,
			name: 'Joint',
		},
		{
			id: 32,
			name: 'Maritime',
		},
		{
			id: 34,
			name: 'Medical',
		},
	];
	public nationalBranchTypes = [
		{
			value: 1,
			label: 'Army',
		},
		{
			value: 2,
			label: 'Air Force',
		},
		{
			value: 3,
			label: 'Navy',
		},
		{
			value: 4,
			label: 'Marine',
		},
		{
			value: 5,
			label: 'Coast Guard',
		},
		{
			value: 6,
			label: 'National Guard',
		},
		{
			value: 7,
			label: 'State Defense Force',
		},
		{
			value: 8,
			label: 'Other',
		},
		{
			value: 9,
			label: 'NA',
		},
	];
	public contactTypes = [
		{
			value: 1,
			label: 'JOINNOW',
		},
		{
			value: 2,
			label: 'PHONE',
		},
		{
			value: 3,
			label: 'EMAIL',
		},
		{
			value: 4,
			label: 'INPERSON',
		},
		{
			value: 5,
			label: 'SOCIALMEDIA',
		},
		{
			value: 6,
			label: 'ONLINE',
		},
	];
	public genderTypes = [
		{
			value: 1,
			label: 'male',
		},
		{
			value: 2,
			label: 'female',
		},
		{
			value: 3,
			label: 'unknown',
		},
	];
	public dateRanges = [
		{
			value: 1,
			label: 'LAST WEEK',
		},
		{
			value: 2,
			label: 'LAST MONTH',
		},
	];
	public yesNoTypes = [
		{
			value: 1,
			label: 'yes',
		},
		{
			value: 2,
			label: 'no',
		},
	];

	public dateOptions = {
		formatYear: 'yy',
		startingDay: 1,
		yearRange: 60,
	};
	public dateFormats = [
		'dd-MMMM-yyyy',
		'dd-MMMM-yyyy',
		'yyyy/MM/dd',
		'dd.MM.yyyy',
		'shortDate',
	];
	public dateFormat = this.dateFormats[0];
	private tableSortNode: HTMLSpanElement | undefined;

	public getMemberRankIcon(member: MemberModel | null): string {
		if (!member || !member.rank) {
			return null;
		}

		if (member.rank.rankType.paygrade.toString().startsWith('O')) {
			return `assets/images/RankLogos/${member.rank.rankType.paygrade}_sm.png`;
		}

		return `assets/images/RankLogos/${member.rank.rankType.paygrade}_${member.rank.rankType.acronym}_sm.png`;
	}

	public memberIsMe(myMember: MyMember, viewMember: MemberModel): boolean {
		if (myMember != null && viewMember != null) {
			return myMember.memberID == viewMember.memberID;
		}

		return false;
	}

	// public clearLocalStorage(): void {
	// 	localStorage.removeItem('MemberSearch');
	// 	localStorage.removeItem('SearchOption');
	// }

	// public checkEvenOdd(num: number): boolean {
	// 	if (num % 2 == 1) {
	// 		return false;
	// 	}

	// 	return true;
	// }

	public getActiveFema(types: FemaEducationType[]): FemaEducationType[] {
		const prunedTypes: FemaEducationType[] = [];

		if (types != null) {
			for (let i = 0; i < types.length; i++) {
				if (types[i].active) {
					prunedTypes.push(types[i]);
				}
			}
		}

		return prunedTypes;
	}

	public getActiveMilitary(
		types: MilitaryEducationType[]
	): MilitaryEducationType[] {
		const prunedTypes: MilitaryEducationType[] = [];

		if (types != null) {
			for (let i = 0; i < types.length; i++) {
				if (types[i].active) {
					prunedTypes.push(types[i]);
				}
			}
		}

		return prunedTypes;
	}

	public getMemberRanks(member: MemberModel) {
		if (!member) {
			return null;
		}

		for (let i = 0; i < member.memberRank.length; i++) {
			// If this is a TXSG rank and if the rank end date is null, then calculate the end date if possible
			if (
				!member.memberRank[i].rankEndDate &&
				member.memberRank[i].branchType &&
				member.memberRank[i].branchType.branchTypeID == 29
			) {
				let candidateRankDate: Date = null;

				for (let j = 0; j < member.memberDischarge.length; j++) {
					if (
						member.memberDischarge[j].dischargeDate >
							member.memberRank[i].dateOfRank &&
						(!candidateRankDate ||
							member.memberDischarge[j].dischargeDate <
								candidateRankDate)
					) {
						candidateRankDate =
							member.memberDischarge[j].dischargeDate;
					}
				}

				if (!candidateRankDate) {
					// find rank that immediately follows
					for (let j = 0; j < member.memberRank.length; j++) {
						if (
							member.memberRank[i].branchType.branchTypeID ==
								29 &&
							member.memberRank[j].dateOfRank >
								member.memberRank[i].dateOfRank &&
							(!candidateRankDate ||
								member.memberRank[j].dateOfRank <
									candidateRankDate)
						) {
							candidateRankDate = member.memberRank[j].dateOfRank;
						}
					}
				}

				member.memberRank[i].rankEndDate = candidateRankDate;
			}
		}

		// Now, remove ranks from the view that the current user shouldn't see
		const prunedRanks: MemberRank[] = [];
		const now = new Date();

		for (let i = 0; i < member.memberRank.length; i++) {
			if (new Date(member.memberRank[i].dateOfRank) <= now) {
				prunedRanks.push(member.memberRank[i]);
			}
		}
		return prunedRanks.sort(this.compareMemberRank);

		// return member.memberRank.sort(this.compareMemberRank);
	}

	public getMemberDesc(member: MemberModel): string {
		if (member == null || member.rank == null) {
			return null;
		}

		return `${member.rank.rankType.name} ${member.firstName} ${member.lastName}`;
	}

	public getMyMemberDesc(member: MyMember): string {
		if (member == null || member.rankAcronym == null) {
			return null;
		}

		return `${member.rankAcronym} ${member.lastName}`;
	}

	public getMemberDescFull(member: MemberModel): string {
		if (!member) {
			return null;
		}

		let desc = `${member.lastName}, ${member.firstName}`;

		if (member.suffix) {
			desc = `${desc} ${member.suffix}`;
		}

		if (member.rank) {
			desc = `${desc}`;
		}
		// if (member.rank) {
		//     desc = `${member.rank.rankType.name} (${member.rank.rankType.paygrade}) ${desc}`;
		// }
		return desc;
	}

	// public showUnit(me, unitId, component): boolean {
	// 	if (me == null) {
	// 		// Probably will never get into this case
	// 		if (component == null) {
	// 			return false;
	// 		} else {
	// 			return component.id == unitId;
	// 		}
	// 	} else if (me.role == 3) {
	// 		// If the logged in recruiter is ADMIN and not Army (since the army regimental breakdown happens automatically) and the recruiter component matches the unit
	// 		return me.component != 2 && me.component == unitId;
	// 	} else if (me.role == 2) {
	// 		// The recruiter is SYSADMIN, and the pulldown component is set and matches the unit
	// 		return component != null && component.id == unitId;
	// 	} else {
	// 		return false;
	// 	}
	// }

	public getObjPropIdx<T, U extends T[keyof T]>(obj: T[], searchString: U, propName: keyof T): number {
		return obj.map((x: T) => x[propName]).indexOf(searchString);
	}

	public getObjPropID<T, U>(obj: T[], searchString: U, propName: keyof T, idProp: keyof T): U extends T[keyof T] ? U : T[keyof T] | null {
		const idx = this.getObjPropIdx(obj, searchString as T[keyof T], propName);
		const id = idx === -1 ? null : obj[idx][idProp];

		return id as U extends T[keyof T] ? U : T[keyof T] | null;
	}

	// for recruiter component:
	// Not used
	// public getName(obj, searchString): any {
	// 	return this.getObjPropID(obj, searchString, 'value', 'label');
	// }

	// public getElem(obj, searchString): any {
	// 	return obj[this.getObjPropIdx(obj, searchString, 'value')];
	// }

	public compareMemberRank = (a: MemberRank, b: MemberRank) => {
		if (a.dateOfRank < b.dateOfRank) {
			return -1;
		}

		if (a.dateOfRank > b.dateOfRank) {
			return 1;
		}

		return 0;
	};

	public getByPayGradePrefix(members: MemberSimple[], prefix: string) {
		return members.filter((x) => x.payGrade.startsWith(prefix + '-'));
	}

	public sortByStringField<T>(
		fields: (keyof T)[] | keyof T,
		reverse?: boolean
	): (a: T, b: T) => number {
		return function (a: T, b: T) {
			if (fields == undefined) {
				return 0;
			}
			const rVal = reverse == undefined ? 1 : reverse ? -1 : 1;
			if (Array.isArray(fields)) {
				for (let i = 0; i < fields.length; i++) {
					const field = fields[i];
					if (a[field] == undefined) {
						continue;
					} else if (b[field] == undefined) {
						continue;
					}
					const aValue = a[field];
					const bValue = b[field];
					if (
						typeof aValue === 'string' &&
						typeof bValue === 'string'
					) {
						const A = aValue.toLowerCase();
						const B = bValue.toLowerCase();
						if (A < B) {
							return -1 * rVal;
						} else if (A > B) {
							return 1 * rVal;
						}
					} else {
						const A = aValue;
						const B = bValue;
						if (A < B) {
							return -1 * rVal;
						} else if (A > B) {
							return 1 * rVal;
						}
					}
				}
				return 0;
			} else {
				// If it's not an array
				const field = fields;
				const aValue = a[field];
				const bValue = b[field];
				if (typeof aValue === 'string' && typeof bValue === 'string') {
					const A = aValue.toLowerCase();
					const B = bValue.toLowerCase();
					return (A < B ? -1 : B < A ? 1 : 0) * rVal;
				} else {
					const A = aValue;
					const B = bValue;
					return (A < B ? -1 : B < A ? 1 : 0) * rVal;
				}
			}
		};
	}

	// public sortName(a, b): 1 | -1 | 0 {
	// 	// The issue is "this" becomes undefined.
	// 	// return this.sortAlphaByField('name')(a,b);
	// 	const groupA = a.name.toUpperCase();
	// 	const groupB = b.name.toUpperCase();

	// 	if (groupA < groupB) {
	// 		return -1;
	// 	}

	// 	if (groupA > groupB) {
	// 		return 1;
	// 	}

	// 	return 0;
	// }

	// public sortLastName(a, b): 1 | -1 | 0 {
	// 	// return this.sortAlphaByField('lastName')(a,b);
	// 	const groupA = a.lastName.toUpperCase();
	// 	const groupB = b.lastName.toUpperCase();

	// 	if (groupA < groupB) {
	// 		return -1;
	// 	}

	// 	if (groupA > groupB) {
	// 		return 1;
	// 	}

	// 	return 0;
	// }

	public sortNumber(a, b): number {
		return a - b;
	}

	// Not used
	// public partitionMembers(members): IPartitionMembers {
	// 	const partitioned = {
	// 		all: members,
	// 		officers: this.getByPayGradePrefix(members, 'O'),
	// 		warrants: this.getByPayGradePrefix(members, 'W'),
	// 		enlisted: this.getByPayGradePrefix(members, 'E'),
	// 		civilian: this.getByPayGradePrefix(members, 'C'),
	// 		aEmpty: false,
	// 		oEmpty: false,
	// 		wEmpty: false,
	// 		eEmpty: false,
	// 		cEmpty: false,
	// 	};

	// 	partitioned.oEmpty = partitioned.officers.length == 0;
	// 	partitioned.wEmpty = partitioned.warrants.length == 0;
	// 	partitioned.eEmpty = partitioned.enlisted.length == 0;
	// 	partitioned.cEmpty = partitioned.civilian.length == 0;
	// 	partitioned.aEmpty = partitioned.all.length == 0;

	// 	return partitioned;
	// }

	public formatDate(dt: string | Date | null): string {
		if (dt instanceof Date) {
			return `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
		} else if (typeof dt === 'string') {
			return dt.substring(0, dt.indexOf('T'));
		}
		return '';
	}

	// public unitsValid(masterUnits): boolean {
	// 	if (
	// 		masterUnits &&
	// 		masterUnits.length > 0 &&
	// 		masterUnits[0] &&
	// 		masterUnits[0].childUnits &&
	// 		masterUnits[0].childUnits.length > 0
	// 	) {
	// 		return true;
	// 	}

	// 	return false;
	// }

	public unitTreeParse(masterUnits: MilitaryUnit[], ret: string[]): string[] {
		if (masterUnits && masterUnits.length > 0) {
			ret.push(`${masterUnits[0].name}>`);
			if (
				masterUnits[0].childUnits &&
				masterUnits[0].childUnits.length > 0
			) {
				this.unitTreeParse(masterUnits[0].childUnits, ret);
			}
		}
		return ret;
	}

	// Used for getting app buttons before member has been loaded
	public getGenericAppButtons(fillAmount): object[] {
		const links = [
			{
				name: 'TMD Website',
				link: 'https://tmd.texas.gov/',
				img: 'TMD.png',
			},
			{
				name: 'TXSG Website',
				link: 'https://txsg.tmd.texas.gov/',
				img: 'TXSG.png',
			},
		];

		for (let i = links.length; i < fillAmount; i++) {
			links.push({
				name: undefined,
				link: undefined,
				img: 'BlankIcon.png',
			});
		}

		return links;
	}

	public getBoolAsYesNo(val): 'yes' | 'no' {
		if (val) {
			return 'yes';
		}

		return 'no';
	}

	public prettyPrint(str: string): string {
		return str == null ? '' : str;
	}

	//Used for getting app buttons after member has been loaded
	public getAppButtons(member: MyMember, fillAmount): AppLinks[] {
		//Null member is bad return generic
		if (member == null) {
			return this.getGenericAppButtons(fillAmount);
		}

		const links: AppLinks[] = [];

		//		if (this.hasRole(member['calculatedGroupMembership'], 'AdminOrRecruiterAdmin')) {
		links.push({
			name: 'Recruiting',
			routerLink: ['/recruiters'],
			img: 'recruiting.png',
		});
		//		}

		//		if (this.hasRole(member['calculatedGroupMembership'], 'Admin')) {
		links.push({
			name: 'Personnel',
			routerLink: ['/member/members'],
			img: 'PersDB.png',
		});
		//		}
		/*		else {
			links.push({
				name: 'Personnel',
				routerLink: ['/member/mymember/' + member.memberID],
				img: 'PersDB.png'
			});
		} */

		links.push({
			name: 'Unit Map',
			routerLink: ['/units'],
			img: 'Maps.png',
		});
		links.push({
			name: 'Calendar',
			routerLink: ['/sec/operations/calendar'],
			img: 'Calendar.png',
		});
		links.push({
			name: 'TMD Website',
			link: 'https://tmd.texas.gov/',
			img: 'TMD.png',
		});
		links.push({
			name: 'TXSG Website',
			link: 'https://txsg.tmd.texas.gov/',
			img: 'TXSG.png',
		});
		links.push({
			name: 'Resistentia futilis',
			routerLink: ['/contributors'],
			img: 'ResistentiaFutilis.png',
		});

		for (let i = links.length; i < fillAmount; i++) {
			links.push({ img: 'BlankIcon.png' });
		}

		return links;
	}

	public copyObj = (key, o): void => {
		if (key == 'childUnits') {
			Object.defineProperty(
				o,
				'children',
				Object.getOwnPropertyDescriptor(o, 'childUnits')
			);
		}
	};

	public traverse = <T>(o: T, func: (key: keyof T, o: T) => void): void => {
		for (const i in o) {
			if (typeof i === 'string' && o.hasOwnProperty(i)) {
				func.apply(this, [i as keyof T, o]);

				if (o[i] !== null && typeof o[i] === 'object') {
					//going one step down in the object tree!!
					this.traverse(o[i] as T, func);
				}
			}
		}
	};

	public downloadCsv(csvStr: string, fileRoot): void {
		const blob = new Blob([csvStr], { type: 'data:attachment/csv' });

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileRoot + '.csv');
		} else {
			const a = document.createElement('a');

			a.href = URL.createObjectURL(blob);
			a.download = fileRoot + '.csv';
			document.body.appendChild(a);
			a.click();

			document.body.removeChild(a);
		}
	}

	// public isLoading(): boolean {
	// 	return this._isLoading;
	// }

	// public show(): void {
	// 	this._isLoading = true;
	// }

	// public hide(): void {
	// 	this._isLoading = false;
	// }

	public filterNullish<T extends Record<string, string | number | boolean | null | undefined>>(
		obj: T
	): Record<string, NonNullable<T[keyof T]>> {
		const result: Record<string, NonNullable<T[keyof T]>> = {};
		for (const key in obj) {
			if (obj[key] !== null && obj[key] !== undefined) {
				result[key] = obj[key] as NonNullable<T[keyof T]>;
			}
		}
		return result;
	}

	public getParams<T extends Record<string, string | number | boolean | null | undefined>>(params: T): string {
		const filteredParams = this.filterNullish(params);
		if (Object.keys(filteredParams).length > 0) {
			const query = Object.keys(filteredParams)
				.map(
					(k) =>
						`${encodeURIComponent(k)}=${encodeURIComponent(
							filteredParams[k]?.toString() || ''
						)}`
				)
				.join('&');

			return '?' + query;
		}

		return null;
	}

	// public getUrlParameters(path): string[] {
	// 	if (path.indexOf('?') != -1) {
	// 		const urlParams = path.substring(path.indexOf('?') + 1).split('&');
	// 		const pairs = [];

	// 		for (const i in urlParams) {
	// 			if (urlParams[i].indexOf('=') == -1) {
	// 				continue;
	// 			}

	// 			pairs[urlParams[i].substring(0, urlParams[i].indexOf('='))] =
	// 				urlParams[i].substring(urlParams[i].indexOf('=') + 1);
	// 		}

	// 		return pairs;
	// 	}

	// 	return [];
	// }

	public TableColumnSort<T>(list: T[], sortBy: keyof T, event: MouseEvent): void {
		if (list == null || list === undefined) {
			return;
		}
		if (sortBy === undefined || sortBy === null) {
			return;
		}
		if (event === undefined || sortBy === null) {
			return;
		}

		// Stop you from clicking the caret and breaking things
		const trueTarget =
			event.target === this.tableSortNode
				? (event.target as HTMLElement).parentNode as HTMLElement
				: (event.target as HTMLElement);

		if (this.tableSortNode === undefined) {
			this.tableSortNode = document.createElement('span');
			this.tableSortNode.classList.add('fa');
		}

		let reverseSort = false;

		if (this.tableSortNode.parentNode === trueTarget) {
			reverseSort = !this.tableSortNode.classList.contains('fa-angle-up');
		}

		list.sort(this.sortByStringField<T>(sortBy, reverseSort));

		this.tableSortNode.classList.remove(reverseSort ? 'fa-angle-down' : 'fa-angle-up');
		this.tableSortNode.classList.add(reverseSort ? 'fa-angle-up' : 'fa-angle-down');

		trueTarget.appendChild(this.tableSortNode);
	}

	// public isEmpty(obj): boolean {
	// 	for (const prop in obj) {
	// 		if (obj.hasOwnProperty(prop)) {
	// 			return false;
	// 		}
	// 	}

	// 	return JSON.stringify(obj) === JSON.stringify({});
	// }

	public formatPhoneNumber(number: string) {
		return number
			?.padStart(10, '-')
			.replace(/^(.{3})(.{3})(.{4})/, '($1) $2-$3');
	}
}
