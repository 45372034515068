<div class="card mb-5">
	<div class="p-fluid p-formgrid grid">
		@if (showDatePicker) {
			<div class="field col-12 md:col-2 mb-0">
				<label for="dutyDateRange">
					{{ dateLabel }}
					{{ datePickerMode === "range" ? " Range" : "" }}
				</label>

				<p-calendar
					#dutyDateRangeCal
					inputId="dutyDateRange"
					selectionMode="{{ datePickerMode }}"
					placeholder="Select Date{{datePickerMode === 'range' ? ' Range' : ''}}"
					[(ngModel)]="dutyDateValue"
					[readonlyInput]="true"
					[showIcon]="true"
					(onSelect)="onDateChange()"
					[showClear]="showClear"
					(onClear)="onClear()"
					[maxDate]="dutyDateMaxDate"
				>
				</p-calendar>
			</div>
		}

		<div class="field col-12 md:col-2 mb-0">
			<label id="operationInput">Operation</label>

			<p-dropdown
				inputId="operationInput"
				[options]="operations"
				[(ngModel)]="operation"
				[disabled]="operationsDisabled"
				(onChange)="onOperationChange()"
				optionLabel="name"
				placeholder="Select Operation"
				[showClear]="showClear"
				(onClear)="onClear()"
				[filter]="true"
				filterBy="name"
			>
			</p-dropdown>
		</div>

		<div class="field col-12 md:col-2 mb-0">
			<label>&nbsp;</label><br>

			<button
				pTooltip="Search for Candidates"
				tooltipPosition="left"
				style="width: 100px"
				pButton
				type="submit"
				label="Search"
				(click)="onSubmit()"
				icon="pi pi-search"
				[disabled]="btnDisabled"
			></button>
		</div>

		<div class="right-box">
			<ng-content></ng-content>
		</div>
	</div>
</div>
