import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { DutyDefinitionFullDto, MTOEAssignmentDto } from '../models';
import { DutyDefinitionSerializer, MTOEAssignmentSerializer } from '../serializers';
import { CustomErrorResponse } from 'src/app/interfaces/custom-error-response';

@Injectable()
export class MTOEAssignmentService extends GenericService<MTOEAssignmentDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'MTOEAssignments',
			new MTOEAssignmentSerializer()
		);
	}

	public getByMemberId(memberId: number): Promise<DutyDefinitionFullDto> {
		return new Promise((resolve, reject) => {
			this.httpClient
				.get<DutyDefinitionFullDto>(`${this.url}/MTOEAssignments/GetMemberAssignment/${memberId}`)
				.subscribe({
					next: (data: DutyDefinitionFullDto) => {
						const convertedData = new DutyDefinitionSerializer().fromJson(data as unknown as Record<string, unknown>);
						resolve(convertedData);
					},
					error: (errorResponse: CustomErrorResponse) => {
						const handledError = this.handleErrors(errorResponse);
						reject(handledError);
					}
				});
		});
	}
}
