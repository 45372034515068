import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { CompanyUnitDto, DutyDefinitionFullDto } from '../models';
import { DutyDefinitionSerializer } from '../serializers';
import { CustomErrorResponse } from 'src/app/interfaces/custom-error-response';

@Injectable()
export class DutyDefinitionService extends GenericService<DutyDefinitionFullDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'DutyDefinitions',
			new DutyDefinitionSerializer()
		);
	}

	public getAllByCompanyId(companyId: number): Promise<DutyDefinitionFullDto[]> {
		return new Promise((resolve, reject) => {
			this.httpClient
				.get<DutyDefinitionFullDto[]>(`${this.url}/${this.endpoint}/Company/${companyId}`)
				.subscribe({
					next: (data: DutyDefinitionFullDto[]) => {
						const convertedData = this.convertData(data);
						resolve(convertedData);
					},
					error: (errorResponse: CustomErrorResponse) => {
						const handledError = this.handleErrors(errorResponse);
						reject(handledError);
					}
				});
		});
	}

	public getAllCompanyUnitsByDutyDefintionId(dutyDefinitionId: number): Promise<CompanyUnitDto[]> {
		return new Promise((resolve, reject) => {
			this.httpClient
				.get<CompanyUnitDto[]>(`${this.url}/${this.endpoint}/${dutyDefinitionId}/Companies`)
				.subscribe({
					next: (data: CompanyUnitDto[]) => {
						resolve(data);
					},
					error: (errorResponse: CustomErrorResponse) => {
						const handledError = this.handleErrors(errorResponse);
						reject(handledError);
					}
				});
		});
	}

	// public getAllCompanyUnitsByDutyDefintionId(dutyDefinitionId: number): Promise<CompanyUnitDto[]> {
	// 	return new Promise((resolve, reject) => {
	// 		this.httpClient
	// 			.get<CompanyUnitDto[]>(`${this.url}/${this.endpoint}/${dutyDefinitionId}/Companies`)
	// 			.subscribe({
	// 				next: (data: CompanyUnitDto[]) => {
	// 					const convertedData = data.map(x => new CompanyUnitSerializer().fromJson(x));
	// 					resolve(convertedData);
	// 				},
	// 				error: (errorResponse: CustomErrorResponse) => {
	// 					const handledError = this.handleErrors(errorResponse);
	// 					reject(handledError);
	// 				}
	// 			});
	// 	});
	// }
}
