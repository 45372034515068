<div class="layout-dashboard">
	<div class="grid">
		<div class="col-12 xl:col-12">
			<div class="card no-gutter orders overflow-hidden">
				<div class="card-header">
					<h4>Texas Military Department</h4>
				</div>
				<div>
					<p style="font-size: 1.33rem">
						The Texas Military Department is composed of the three
						branches of the military in the state of Texas. These
						branches are the Texas Army National Guard, the Texas
						Air National Guard, and the Texas State Guard. All three
						branches are administered by the state Adjutant General,
						an appointee of the Governor of Texas, and fall under
						the command of the Governor.
					</p>
				</div>
			</div>
		</div>

		<ng-template
			permissions
			[PermissionsOnly]="[Permissions.DashboardRead]"
		>
			<div class="col-12 md:col-6 xl:col-3">
				<div
					class="card no-gutter widget-overview-box widget-overview-box-1"
				>
					<span class="overview-icon"
						><i class="pi pi-users"></i
					></span>
					<span class="overview-title">TXARNG Strength</span>
					@if (txarng_membersLength === 0) {
					<div style="text-align: center">
						<i
							class="pi pi-spin pi-spinner"
							style="font-size: 2rem"
						></i>
					</div>
					} @if (txarng_membersLength != 0) {
					<div class="grid overview-detail">
						<div class="col-12">
							<div class="overview-number">
								{{ txarng_membersLength }}
							</div>
							<div class="overview-subtext">Active</div>
						</div>
					</div>
					}
				</div>
			</div>
			<div class="col-12 md:col-6 xl:col-3">
				<div
					class="card no-gutter widget-overview-box widget-overview-box-2"
				>
					<span class="overview-icon"
						><i class="pi pi-users"></i
					></span>
					<span class="overview-title">TXANG Strength</span>
					@if (txang_membersLength === 0) {
					<div style="text-align: center">
						<i
							class="pi pi-spin pi-spinner"
							style="font-size: 2rem"
						></i>
					</div>
					} @if (txang_membersLength != 0) {
					<div class="grid overview-detail">
						<div class="col-12">
							<div class="overview-number">
								{{ txang_membersLength }}
							</div>
							<div class="overview-subtext">Active</div>
						</div>
					</div>
					}
				</div>
			</div>
			<div class="col-12 md:col-6 xl:col-3">
				<div
					class="card no-gutter widget-overview-box widget-overview-box-3"
				>
					<span class="overview-icon"
						><i class="pi pi-users"></i
					></span>
					<span class="overview-title">TXSG Strength</span>
					@if (txsg_membersLength === 0) {
					<div style="text-align: center">
						<i
							class="pi pi-spin pi-spinner"
							style="font-size: 2rem"
						></i>
					</div>
					} @if (txsg_membersLength != 0) {
					<div class="grid overview-detail">
						<div class="col-12">
							<div class="overview-number">
								{{ txsg_membersLength }}
							</div>
							<div class="overview-subtext">Active</div>
						</div>
					</div>
					}
				</div>
			</div>
			<div class="col-12 md:col-6 xl:col-3">
				<div
					class="card no-gutter widget-overview-box widget-overview-box-4"
				>
					<span class="overview-icon"
						><i class="pi pi-user-plus"></i
					></span>
					<span class="overview-title">TMD Strength</span>
					@if (tmd_membersLength === 0) {
					<div style="text-align: center">
						<i
							class="pi pi-spin pi-spinner"
							style="font-size: 2rem"
						></i>
					</div>
					} @if (tmd_membersLength != 0) {
					<div class="grid overview-detail">
						<div class="col-12">
							<div class="overview-number">
								{{ tmd_membersLength }}
							</div>
							<div class="overview-subtext">Combined</div>
						</div>
					</div>
					}
				</div>
			</div>
		</ng-template>

		<div class="col-12 xl:col-12">
			<style>
				.DashboardCenteredButtons .p-button {
					font-size: 1.75rem;
					margin-top: 0.2em;
				}
				.DashboardCenteredButtons .col-12 {
					display: grid;
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 1em; /* Adjust the gap between buttons */
				}
			</style>
			<div class="card">
				<div class="grid DashboardCenteredButtons">
					<div class="col-12">
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/leadership', '_blank');"
						>
							Leadership
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/crisis-get-support-now', '_blank');"
						>
							Crisis Support
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/tmd-counseling', '_blank');"
						>
							TMD Counseling
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/family-support-service', '_blank');"
						>
							Family Support Services
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/tmd-sapr', '_blank');"
						>
							SHARP
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/office-of-the-general-counsel', '_blank');"
						>
							JAG
						</button>
						<button
							class="p-button"
							onclick="window.open('https://rise.articulate.com/share/z_5aW4VWXSYGKngx7yIewmpPD_QSgBAT#/', '_blank');"
						>
							State Active Duty
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/ols-rally-point', '_blank');"
						>
							Pay Calculator
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/capps', '_blank');"
						>
							CAPPS
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/money-for-college', '_blank');"
						>
							TMD Education Benefits
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/workers-compensation', '_blank');"
						>
							Workers Compensation
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/fraud-alert', '_blank');"
						>
							Fraud Alert
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/texas-military-department-policies-and-regulations', '_blank');"
						>
							TMD Serially Numbered Issuances
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/news', '_blank');"
						>
							TMD News
						</button>
						<button
							class="p-button"
							onclick="window.open('https://tmd.texas.gov/contact-us', '_blank');"
						>
							TMD Contacts
						</button>
						<a
							class="p-button"
							href="https://txsgstatetx.sharepoint.com/sites/JRMSHelpDesk" target="_blank">
							JRMS HelpDesk
						</a>
					</div>
				</div>
				<p>
					Note: Clicking any of the above buttons will open a new tab
				</p>
			</div>
		</div>
	</div>
</div>
