import { CompanyFullDto } from '../models';
import { ISerializer } from './ISerializer';

export class CompanySerializer implements ISerializer<CompanyFullDto> {
	public fromJson(json: Record<string, unknown>): CompanyFullDto {
		const company = new CompanyFullDto();
		company.battalion = json['battalion'] as string;
		company.battalionId = json['battalionId'] as number;
		company.id = json['id'] as number;
		company.name = json['name'] as string;
		company.uic = json['uic'] as string;
		// Map other properties from JSON to CompanyFullDto
		return company;
	}

	public toJson(entity: CompanyFullDto): Record<string, unknown> {
		return {
			battalion: entity.battalion,
			battalionId: entity.battalionId,
			id: entity.id,
			name: entity.name,
			uic: entity.uic,
			// Map other properties from CompanyFullDto to JSON
		};
	}
}
