@if (isMyFeatureEnabled()) {
<div>
	<style>
		@font-face {
			font-family: StarWars;
			src: url(assets/fonts/starwars.ttf);
		}
		@font-face {
			font-family: StarWarsTitle;
			src: url(assets/fonts/starwarstitle.ttf);
		}
	</style>
	<audio controls autoplay loop hidden>
		<source src="assets/media/tfa.ogg#t=9" type="audio/ogg" />
		<source src="assets/media/tfa.mp3#t=9" type="audio/mpeg" />
		<!-- <source src="assets/media/Meatball-Parade.mp3#t=9" type="audio/mpeg"> -->
	</audio>
	<div class="t6container">
		<div class="fade"></div>
		<section class="star-wars">
			<div class="crawl">
				<div class="title">
					<h1 style="text-align: center">
						<strong>TXSG Presents:</strong>
					</h1>
					<h1
						style="
							text-align: center;
							font-family: StarWars;
							font-size: 350px;
						"
					>
						RMS
					</h1>
					<h1>
						<strong>
							The Texas State Guard (TXSG) T6 Shop is responsible
							for signal, communications, computers, and cyber
							internally and in cooperation with our TMD partners.
						</strong>
					</h1>
					<h1>
						<strong>
							As a shop comprised of uniquely skilled
							IT/Radio/Cyber experts, the TXSG T6 operates as a
							flat organization.&nbsp; Each member brings
							experience and talent to create an unstoppable team.
						</strong>
					</h1>
					<h1>
						<strong>
							RMS was launched in 2016 after an 18 month build
							out. The RMS system has been recognized as a
							&quot;Best in Texas&quot; awardee thrice in
							competion against other Texas state agencies (2018,
							2019, and 2020).
						</strong>
					</h1>
					<h1>
						<strong>
							The RMS system is a critical tool in the mission of
							the TXSG. The professionalism and expertise of the
							volunteer service members have saved the taxpayers
							of Texas over $4.5 million dollars in donated time
							and resources.
						</strong>
					</h1>
					<h1>
						<strong>
							Side Note: RMS was slated to be named PMERS by LTC
							Chistopher Sauceda. However, MSG John Gately&apos;s
							cunning and wit resulted in a name change to RMS,
							without LTC Sauceda&apos;s knowledge and much to his
							chagrin!
						</strong>
					</h1>
					<h1
						style="
							text-align: center;
							font-family: StarWars;
							font-size: 100px;
						"
					>
						<strong>The TXSG T6 Team</strong>
					</h1>
					<h2>
						<strong>
							COL Joseph Jelinski - DCofS for Support*<br />
							LTC Chris Sauceda - OIC<br />
							LTC William Richards - Deputy OIC<br />
							MAJ Timothy Clement - Systems Administrator OIC<br />
							CPT David Arnold - Project Mananger<br />
							CPT Kenneth Bell - Cyber OIC<br />
							CPT Larry Gawlik - Programming OIC<br />
							CPT Paul Gilbert - Radio OIC*<br />
							CPT Adam Gonzales - Special Projects<br />
							1LT Stephen Coder - Special Projects<br />
							1LT Robert Johnson - Radio Team<br />
							1LT William Dove - Sr. Programmer <br />
							2LT Amanda Livingston - Database Architect*<br />
							CW5 Thomas Dodd - Lead Database Architect<br />
							CW3 Darrell Prather - Sr. Programmer<br />
							CW2 Stephen Hopper - Lead Programmer<br />
							WO1 Christopher Caruso - Cyber Team<br />
							WO1 Andrew Garthe - Cyber Team*<br />
							WO1 Jacob A. Gately - Sr. Programmer<br />
							WO1 John Turner - Sr. Programmer<br />
							MSG John J. Gately - NCOIC &amp; Product Manager<br />
							MSG James Williams - Special Projects<br />
							SFC Jay Davis - Special Projects<br />
							SFC Walter Messner - Database Architect <br />
							SSG Joel Grimes - Sr. Programmer<br />
							SSG Erik Matthies - Radio Team<br />
							SSG Andrew Williams - Cyber Team<br />
							SSG Michael Littlejohn - Sr. Programmer<br />
							SGT Steven Cheeseman - Cyber Team<br />
							SGT Francis O&apos;Connell - Lead Programmer*<br />
							CPL Shawn Carter - Technical Writer<br />
							CPL Grant Hopper - Radio Team<br />
							CPL Wandra Jordan - Cyber Team<br />
							PFC Snuffy Smith - Fake Account for testing<br />
							PVT Samuel Hopper - Radio Team<br />
							* Members no longer with the TXSG<br />
						</strong>
					</h2>
					<h1>
						<strong>
							January 2022 the Texas Adjutant General ordered the
							TXSG Readiness Management System (RMS) to be
							elevated to replace an outdated system. With that
							order, a much larger team was formed! <br />
							<br />
						</strong>
					</h1>
					<h1 style="text-align: center">
						<strong>Texas Military Department Presents:</strong>
					</h1>
					<h1
						style="
							text-align: center;
							font-family: StarWars;
							font-size: 350px;
						"
					>
						JRMS<br /><br />
					</h1>
					<h1
						style="
							text-align: center;
							font-family: StarWars;
							font-size: 100px;
						"
					>
						<strong>Texas Military Department Team</strong>
					</h1>
					<h2>
						<strong>
							COL Tanya Trout - TxARNG - J6 OIC<br />
							LTC Chris Sauceda - TXSG - T6 OIC<br />
							LTC William Richards - TXSG - Deputy T6<br />
							LTC Michael DeRobio - TxARNG - Sr. Programmer<br />
							LTC Adam Dials - TxARNG<br />
							MAJ Timothy Clement - TXSG - T6 - Server Admin<br />
							MAJ Bruce Minor - TXSG - T6 - Project Manager<br />
							Maj Christopher Drye - TxANR<br />
							CPT Markisha Homer - TxARNG - Project Manager<br />
							CPT David Arnold - TXSG - Project Mananger<br />
							CPT Larry Gawlik - TXSG - T6 - Lead Programmer<br />
							CPT Kenneth Bell - TXSG - T6 - Cyber OIC<br />
							CPT Samuel Garcia - TxARNG<br />
							Capt Rhonda O'Hara - TxANR<br />
							1LT William Dove - TXSG - T6 - Lead Programmer<br />
							1LT Roberto Garcia - TxARNG<br />
							1LT Meghan Quillin - TxARNG<br />
							2LT Patrick Wynne - TXSG - Sr. Programmer<br />
							CW5 Thomas Dodd - TXSG - T6 - Lead Database
							Architect<br />
							CW4 Jorge Rodriguez - TxARNG - J6<br />
							CW3 Darrell Prather - TXSG - T6 - Sr. Programmer<br />
							CW2 Stephen Hopper - TXSG - T6 - Lead Programmer<br />
							CW3 Paul Schroeder - TXSG - T1<br />
							WO1 Christopher Caruso - TXSG - T6 - Cyber Team<br />
							WO1 Jacob A. Gately - TXSG - T6 - Sr. Programmer<br />
							WO1 John Turner - TXSG - T6 - Sr. Programmer<br />
							SGM Dolores Gonzalez - TXSG - Q.A. Team<br />
							MSG John J. Gately - TXSG - T6 - Product Manager<br />
							MSG James William - TXSG - T6 - Specail Projects<br />
							MSG Gerald Steward - TXSG - Deputy T1<br />
							SMSgt Devonne Golding - TxANR<br />
							SFC Walter Messner - TXSG - T6 - Database
							Architect<br />
							MSgt James Valenzuela - TxANR<br />
							SSG Joel Grimes - TXSG - T6 - Sr. Programmer<br />
							SSG Orrin Spence - TXSG - T6 - Sr. Programmer<br />
							SSG Andrew Williams - TXSG - T6 - Cyber Team<br />
							SSG Michael Littlejohn - TXSG - T6 - Sr.
							Programmer<br />
							SSG Robin Hefner - TxARNG<br />
							SSG Christine Durham - Texas Army Naitonal Guard<br />
							SGT Steven Cheeseman - TXSG - T6 - Cyber Team<br />
							SGT Donny Gore - TXSG - Project Mananger<br />
							SGT Partrick Hill - TxARNG<br />
							SGT Detrice Mitchell - TxARNG<br />
							SGT Antonio Nunez - TxARNG<br />
							CPL Wandra Jordan - TXSG - T6 - Cyber Team<br />
							CPL Amada Byland - TXSG - T1<br />
							CPL Taiana Spence - TXSG - T6 - Jr. Programmer<br />
							CPL Shawn Carter - TXSG - T6 - Database<br />
							SPC Mykole Bridgman - TxARNG<br />
							SPC Jasmin Molina - TxARNG<br />
							SPC Nevada Monoz - TxARNG<br />
							SPC Isolivette Lugo - TxARNG <br />
							SrA Rosa Aguilar - TxANR<br />
							PFC Jenica Wynne - TXSG - Project Manager<br />
							PFC Phuoc Troung - TXSG - Project Manager<br />
							PV2 Sundeepan Sen - TXSG - T6 - Jr. Programmer<br />
							Mr. Julio Sevilla - USPFO<br />
							Mr. Carlos Medina - USPFO<br />
							Mr. Rick Vincent - USPFO<br />
							Mr. Joe Davis - i7MEDIA<br />
							Mr. Elijah Fowler - i7MEDIA<br />
							Mr. Shawn Grout - i7MEDIA<br />
							Mr. Joe Stombaugh - i7MEDIA<br />
							Mr. Phillip Davis - i7MEDIA<br />
						</strong>
					</h2>
					<h1
						style="
							text-align: center;
							font-family: StarWars;
							font-size: 100px;
						"
					>
						<strong>JRMS Steering Committee Members</strong>
					</h1>
					<h2>
						<strong>
							COL Tanya Trout - TxARNG<br />
							Col Christopher Howell - TxANR<br />
							COL Darren Fitz Gerald - TXSG<br />
							LTC Yesenia Sigcho - TxARNG<br />
							MAJ Aimie Tibbetts TxARNG<br />
							Maj Aaron Blackstone - TxANR<br />
							MSG John Gately - TXSG<br />
						</strong>
					</h2>
				</div>
			</div>
		</section>
	</div>
</div>
} @else {
<div><strong>This section is not active</strong></div>
}
