import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config, TokenManager } from '@forgerock/javascript-sdk';
import { environment } from 'src/environments/environment';
import { BuildResponse } from '../interfaces/build-response';

@Injectable()
export class ConfigService {
	public build: string = '';

	public constructor(private http: HttpClient) {}

	public async initialize() {
		this.configureAuthentication();
		await Promise.all([this.getTokenFromQueryParams(), this.getBuildFile()]);
	}

	private configureAuthentication(): void {
		/** ***************************************************************************
		 * RockForge JavaScript SDK INTEGRATION POINT
		 * Summary: Configure the SDK
		 * ----------------------------------------------------------------------------
		 * Details: Below, you will see the following settings:
		 * - clientId: (OAuth 2.0 only) this is the OAuth 2.0 client you created in ForgeRock, such as `ForgeRockSDKClient`
		 * - redirectUri: (OAuth 2.0 only) this is the URI/URL of this app to which the
		 *   OAuth 2.0 flow redirects
		 * - scope: (OAuth 2.0 only) these are the OAuth scopes that you will request from
		 *   ForgeRock
		 * - serverConfig: this includes the baseUrl of your ForgeRock AM, and should
		 *   include the deployment path at the end, such as `/am/` or `/openam/`
		 * - realmPath: this is the realm to use within ForgeRock. such as `alpha` or `root`
		 * - tree: The authentication journey/tree to use, such as `sdkAuthenticationTree`
		 *************************************************************************** */

		Config.set({
			clientId: environment.WEB_OAUTH_CLIENT,
			redirectUri: `${window.location.origin}/login`,
			scope: 'openid profile email',
			serverConfig: {
				baseUrl: environment.AM_URL,
				// paths: {
				// 	authenticate: 'json/realms/root/realms/TDIS/authenticate',
				// 	authorize: 'oauth2/realms/root/realms/TDIS/authorize',
				// },
				timeout: 3000, // 9000 or less
			},
			realmPath: environment.REALM_PATH,
			tree: environment.JOURNEY_LOGIN,
		});
	}

	private async getTokenFromQueryParams() {
		const params = new URLSearchParams(window.location.search);
		const code: string | undefined = params.get('code');
		const state: string | undefined = params.get('state');

		if (code && state) {
			try {
				await TokenManager.getTokens({ query: { code, state } });
			}
			catch (error: unknown) {
				console.log(error);
			}
		}
	}

	private async getBuildFile(): Promise<void> {
		const buildFile: BuildResponse = await this.http
			.get<BuildResponse>('/assets/build.json')
			.toPromise();
		this.build = buildFile?.build ?? '';
	}
}
