<div class="layout-sidebar" (click)="appMain.onMenuClick()">
	<a [routerLink]="['/sec']" class="logo">
		<img
			id="app-logo"
			max
			width="100rem"
			src="assets/jrms_smlogo.png"
			alt="J-RMS"
		/>
	</a>

	<div class="layout-menu-container">
		<ul class="layout-menu">
			@for (item of model; track item; let i = $index) {
				@if (!item.separator) {
					<li app-menuitem [item]="item" [index]="i" [root]="true"></li>
				}

				@if (item.separator) {
					<li class="menu-separator"></li>
				}
			}
		</ul>
	</div>
</div>
