import { BranchDto } from '../models';
import { MOSDto } from '../models/MOSDto';
import { ISerializer } from './ISerializer';

export class MOSSerializer implements ISerializer<MOSDto> {
	public fromJson(json: Record<string, unknown>): MOSDto {
		const mosDto = new MOSDto();
		mosDto.id = json['id'] as number;
		mosDto.code = json['code'] as string;
		mosDto.name = json['name'] as string;
		mosDto.branchId = json['branchId'] as number;
		mosDto.branch = (json['branch'] as BranchDto) || null;
		// Map other properties from JSON to MOSDto
		return mosDto;
	}

	public toJson(entity: MOSDto): Record<string, unknown> {
		return {
			id: entity.id,
			code: entity.code,
			name: entity.name,
			branchId: entity.branch?.id,
			// Map other properties from MOSDto to JSON
		};
	}
}