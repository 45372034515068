import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { OperationPayRate } from 'src/app/interfaces/OperationPayRate';
import { PayRateBatch } from 'src/app/interfaces/PayRateBatch';
import { PayRate } from 'src/app/interfaces/payRate';
import { BranchDto } from 'src/app/mtoe/models';
import { BranchService } from 'src/app/mtoe/services';
import { RmsService } from 'src/app/services/rms.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
	selector: 'app-pay-assignment',
	templateUrl: './pay-assignment.component.html',
	styleUrls: ['./pay-assignment.component.scss'],
	providers: [BranchService]
})
export class PayAssignmentComponent implements OnInit {
	@Input()
	public operationId: number;
	public pays: PayRate[] = [];
	public modal: boolean = false;
	public loadingAvailablePays: boolean = false;
	public availablePays: PayRate[] = [];
	public selectedPay: PayRate = null;
	// enum values
	public SelectMode = {
		Start: 1,
		Single: 2,
		Branch: 3,
		Active: 4,
		Type: 5,
		Review: 6,
	};
	public selectMode: number = this.SelectMode.Start;
	public branches: BranchDto[] = null;
	public selectedBranches: BranchDto[] = null;

	public active: boolean = true;
	public activeOptions: SelectItem[] = [
		{ label: 'Active', value: true },
		{ label: 'Inactive', value: false },
	];

	public selectedPersonnelType: string = null;
	public personnelTypes: SelectItem[] = [
		{ label: 'Officer', value: 'O' },
		{ label: 'Enlisted', value: 'E' },
		{ label: 'Not Applicable', value: null },
	];

	// Timestamp is for caching purposes.
	private availablePaysTimestamp: Date = null;

	public constructor(
		private route: ActivatedRoute,
		private rmsService: RmsService,
		private branchService: BranchService,
		public spinnerService: SpinnerService,
		private messageService: MessageService,
		private confirmationService: ConfirmationService
	) {}

	public async ngOnInit(): Promise<void> {
		if (!this.operationId) {
			this.operationId = Number(this.route.snapshot.paramMap.get('id'));
		}

		await this.loadPays();
	}

	public async loadPays() {
		this.spinnerService.showSpinner = true;

		try {
			this.pays = await this.rmsService.GetPayRatesForOperation(
				this.operationId
			);
		}
		catch (error: unknown) {
			this.handleError(error, 'Error Loading');
		}

		this.spinnerService.showSpinner = false;
	}

	public async loadBranches(): Promise<void> {
		if (this.branches !== null) {
			return;
		}

		try {
			this.branches = await this.branchService.getAll();
		}
		catch (error: unknown) {
			this.handleError(error, 'Error fetching Branches.');
		}
	}

	public checkTimestamp(availablePaysTimestamp: Date | null): boolean {
		if (availablePaysTimestamp !== null) {
			const halfAMinuteAfterTimestamp = new Date(availablePaysTimestamp.getTime() + 1 * 60000);
			return availablePaysTimestamp.getTime() < halfAMinuteAfterTimestamp.getTime();
		}
		return false;
	}

	public async loadAvailablePays() {
		if (
			this.availablePays.length > 0 &&
			this.availablePaysTimestamp !== null &&
			this.checkTimestamp(this.availablePaysTimestamp)
		) {
			return;
		}

		this.spinnerService.showSpinner = true;
		this.loadingAvailablePays = true;

		try {
			this.availablePays = await this.rmsService.GetPayRates().toPromise();
			this.availablePaysTimestamp = new Date();
		} catch (error: unknown) {
			this.handleError(error, 'Error Loading');
		}

		this.spinnerService.showSpinner = false;
		this.loadingAvailablePays = false;
	}

	public async addPayToOperation(): Promise<void> {
		this.modal = true;
		await this.loadAvailablePays();
		this.availablePays = this.availablePays.filter(
			(x) => !this.pays.some((p) => p.id === x.id)
		);
	}

	public async assignPayToOperation(): Promise<void> {
		this.spinnerService.showSpinner = true;

		try {
			const operationPayRate = new OperationPayRate();

			operationPayRate.operationId = this.operationId;
			operationPayRate.payRateId = this.selectedPay.id;

			await this.rmsService.AssignPayRateToOperationAsync(
				operationPayRate
			);

			await this.loadPays();
			this.closeModal();
		} catch (error: unknown) {
			this.handleError(error, 'Error Assigning');
		}

		this.spinnerService.showSpinner = false;
	}

	public removePayFromOperation(payRate: PayRate) {
		this.confirmationService.confirm({
			message: `Are you sure you want to remove this Pay Rate from the Operation?`,
			header: 'Confirm',
			icon: 'pi pi-exclamation-triangle',
			accept: async () => {
				this.spinnerService.showSpinner = true;

				try {
					const operationPayRate = new OperationPayRate();

					operationPayRate.operationId = this.operationId;
					operationPayRate.payRateId = payRate.id;

					await this.rmsService.RemovePayRateFromOperation(
						operationPayRate
					);

					this.closeModal();
					this.pays = this.pays.filter((x) => x.id !== payRate.id);
				}
				catch (error: unknown) {
					this.handleError(error, 'Error Removing');
				}

				this.spinnerService.showSpinner = false;
			},
		});
	}

	public async togglePays(payRate: PayRate) {
		this.spinnerService.showSpinner = true;

		try {
			const operationPayRate = new OperationPayRate();

			operationPayRate.operationId = this.operationId;
			operationPayRate.payRateId = payRate.id;

			await this.rmsService.ActivateDeactivePayRateByOperationId(
				operationPayRate
			);
		}
		catch (error: unknown) {
			this.handleError(error, 'Error Activating/Deactivating Pay Rate');
		}

		this.spinnerService.showSpinner = false;
	}

	public async setPickerMode(mode: number) {
		this.selectMode = mode;

		if (mode = this.SelectMode.Branch) {
			await this.loadBranches();
		}
	}

	public async addBatch() {
		const batch = new PayRateBatch();

		batch.operationId = this.operationId;
		batch.branchIds = this.selectedBranches?.map(x => x.id) ?? null;
		batch.isActive = this.active;
		batch.personnelType = this.selectedPersonnelType ?? null;

		try {
			await this.rmsService.AddPayRateBatchToOperation(batch);

			await this.loadPays();
			this.closeModal();
		}
		catch (error: unknown) {
			this.handleError(error, 'Error Adding Pay Rate Batch');
		}
	}

	public closeModal() {
		this.modal = false;
		this.selectedPay = null;
		this.selectMode = this.SelectMode.Start;
		this.selectedBranches = null;
		this.active = true;
		this.selectedPersonnelType = null;
	}

	private handleError(error: unknown, summary: string): void {
		const errorMessage = error instanceof Error ? error.message : String(error);
		this.messageService.add({
			severity: 'error',
			summary,
			detail: errorMessage,
		});
	}
}
