// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// this is the default file used in development and on the dev deployment

export const environment = {
	production: false,
	testing: false,
	staging: false,
	localapi: false,
	api: 'https://devjrmsapi.tmd.texas.gov/',
	azureMapsSubscriptionKey: 'e1yR4EeRKSiIjGXjLrp4GV3YpXaI1IvM-g-GOGGRlfE',
	name: 'BETA',

	// RockForge JavaScript SDK
	WEB_OAUTH_CLIENT: 'TDIS_TMD',
	AM_URL: 'https://sbx.myaccess.texas.gov/sso/',
	REALM_PATH: '/TDIS',
	JOURNEY_LOGIN: 'TMD_TREE',
};
