import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	public menuMode = 'static';
	public colorScheme = 'light';
	public menuTheme = 'layout-sidebar-darkgray';
	public inputStyle = 'outlined';
	public ripple: boolean;

	public constructor(private primengConfig: PrimeNGConfig) {}

	public ngOnInit(): void {
		this.primengConfig.ripple = true;
		this.ripple = true;
	}
}
