import { Injectable } from '@angular/core';
import {
	UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
	public constructor(private authService: AuthService) {}

	/**
	 * Extends CanActivate to protect selected routes from unauthenticated access
	 *
	 * @param next - Route that the user is trying to access
	 * @param state - Router state
	 * @returns Promise - Boolean or route to redirect the user to
	 */
	public async canActivate(): Promise<true | UrlTree> {
		const authenticated = await this.authService.authenticated();

		if (!authenticated) {
			await this.authService.authenticate();
			await this.authService.authorize();
		}

		return true;
	}
}
