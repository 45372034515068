import { BaseEntity } from './BaseEntity';

export class DutyDefinitionFullDto extends BaseEntity {
	public asi1: string | null = null;
	public asi1Id: number | null = null;
	public asi2: string | null = null;
	public asi2Id: number | null = null;
	public asi3: string | null = null;
	public asi3Id: number | null = null;
	public asi4: string | null = null;
	public asi4Id: number | null = null;
	public auth: boolean | null = null;
	public dutyPosition: string | null = null;
	public fte: boolean | null = null;
	public grade: string | null = null;
	public ln: string | null = null;
	public milcivexper: string | null = null;
	public paraNo: number | null = null;
	public paraTitle: string | null = null;
	public pmosCode: string | null = null;
	public pmosId: number | null = null;
	public pmosName: string | null = null;
	public req: boolean | null = null;
	public rsmosCode: string | null = null;
	public rsmosId: number | null = null;
	public rsmosName: string | null = null;
	public sqi: string | null = null;
	public sqiId: number | null = null;
}
