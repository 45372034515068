@if (isMyFeatureEnabled()) {
<div>
	<h1 class="page-header">Personal Documentation</h1>
	<p>Hello [FirstName],</p>
	<p>
		Thank you for your interest to serve the State of Texas with the Texas
		State Guard (TXSG), one of three branches in the Texas Military
		Department (TMD); the other two are the Texas Army National Guard
		(TXARNG) and Texas Air National Guard (TXANG). The TXSG has Army, Air,
		Maritime (Navy &amp; Marine) and Medical components.
	</p>
	<p>Enlistment Qualifications:</p>
	<ul>
		<li>Resident of Texas (180 days or more)</li>
		<li>Age 17-67</li>
		<li>Valid Texas Driver’s License</li>
		<li>Reasonable Good Health</li>
		<li>Pass Criminal Background Check</li>
		<li>
			High School Diploma, GED, Home SchoolCertificate, or College
			Transcript
		</li>
		<li>Prior Military Service NOT Required</li>
		<li>
			Prior Service requires Honorable discharge and eligible
			Re-Enlistment Code
		</li>
		<li>Federal Retirement NOT affected</li>
		<li>Education and Age Requirements for Officer Commissioning</li>
	</ul>
	<p>Below are 3 items all applicants must complete:</p>
	<ol start="1">
		<li>
			TXSG Accession Packet (Application Form) - Document to collect all
			necessary and relevant information for purpose of Joining the Texas
			State Guard. All items must be answered truthfully and
			correctly/completely. Omissions and willful misinformation will be
			cause for your Application to be rejected and prevent any
			re-submission thereafter.
		</li>
	</ol>
	<div style="margin-left: 2em">
		<ul>
			<li>
				<a
					href="https://tmd.texas.gov/Data/Sites/1/media/recruitment/txsg/txsg-accession-form-dtd-01apr2018-e.pdf"
					>TXSG Accession Form dtd 01Apr2018-E</a
				>
			</li>
		</ul>
	</div>
	<ol start="2">
		<li>
			TXSG Form 2807-1 (Medical Screening Form) - If you answer yes to a
			question you must give an explanation on page two in the remarks
			section. Example 11f asks if you wear glasses or contacts. If you
			wear glasses, you would put "11f. Wear glasses".
		</li>
	</ol>
	<div style="margin-left: 2em">
		<ul>
			<li>
				<a
					href="https://tmd.texas.gov/Data/Sites/1/media/recruitment/txsg/txsg2807-1_23oct2017-e.pdf"
					>TXSG Form 2807-1</a
				>
			</li>
		</ul>
	</div>
	<ol start="3">
		<li>
			Background Check - You do not have to wait to hear from a recruiter
			before setting and completing your fingerprinting appointment for a
			basic background check. Apply online (see link below) for an
			appointment at an office near you. The cost is $10 for a basic
			background check. Ensure you take the email receipt with you to your
			appointment as the recruiter will need that along with the payment
			receipt. Our goal is to complete your enlistment process as quickly
			as possible.
		</li>
	</ol>
	<div style="margin-left: 2em">
		<ul>
			<li>
				<a href="http://identogo.com/FP/texas.aspx"
					>Fingerprinting Appointment</a
				>
				– Enter Service Code 11G7VN
			</li>
		</ul>
	</div>
	<p>
		<a id="_GoBack" name="_GoBack"></a> The following documents are required
		and to be given to your Recruiter when meeting:
	</p>
	<ol>
		<li>Valid Texas Driver’s License</li>
		<li>Social Security Card</li>
		<li>
			High School Diploma, GED, Home School Certificate, College
			Transcripts (can be unofficial)
		</li>
		<li>
			Prior service DD214, NGB 22, Discharge Certificate, DARP 249R,
			Honorable Discharge Certificate
		</li>
		<li>
			txsg2807-1 Medical Screening Form (save as a PDF once completed).
			Once you have been contacted by your recruiter you can send the form
			to be medically cleared by the TXSG Surgeon before your appointment
			with your Recruiter. This will speed up your approval time.
		</li>
		<li>Any Certifications or Licenses</li>
	</ol>
	<p>
		If you have ever been arrested, provide a copy of the disposition from
		the court clerk. If the arrest record is from years past and the court
		does not have a record of it, provide a letter from the court clerk
		stating so. You must disclose ALL arrests as failure to do so is an
		omission and automatic rejection of your Application into the TXSG,
		barring from any future submissions.
	</p>
	<p>
		A Recruiter in your area will make contact and answer any additional
		questions you may have and assist you with an Enlistment Packet.
	</p>
	<p>
		If you do not hear from a Recruiter within 10-15 days, please contact
		the TXSG Recruiting and Retention Group by email:
		<a href="mailto:txsgrecruiting@txsg.state.tx.us"
			>txsgrecruiting&#64;txsg.state.tx.us</a
		>
	</p>
	<p>
		We strive to keep initial contact within 10-days or less from submission
		and appreciate your patience, communication and enthusiasm!
	</p>
	<table width="100%">
		<tbody>
			<tr>
				<td>
					LTC Mikel T. Sledge <br />
					TXSG HHC, Recruiting Commander
				</td>
				<td>
					MSG Ernie Casiano <br />
					TXSG HHC, Recruiting NCOIC
				</td>
			</tr>
		</tbody>
	</table>
</div>
} @else {
<div><strong>This section is not active</strong></div>
}
