<div class="layout-topbar">
	<div class="topbar-left">
		<a
			tabindex="0"
			class="menu-button"
			(click)="appMain.onMenuButtonClick($event)"
		>
			<i class="pi pi-chevron-left"></i>
		</a>

		<img
			id="logo-horizontal"
			class="horizontal-logo"
			[routerLink]="['/']"
			src="assets/images/Logos/jrms_icon_logo.png"
			alt="JRMS"
		/>

		<span class="topbar-separator"></span>

		@for (item of items; track item; let last = $last) {
			<span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
		}

		@if (spinnerSvc.showSpinner) {
			<p-progressSpinner
				class="ml-4"
				[style]="{ width: '20px', height: '20px' }"
				styleClass="custom-spinner"
				strokeWidth="3"
				fill="var(--surface-ground)"
				animationDuration=".5s"
			></p-progressSpinner>
		}
		<!-- <img id="logo-mobile" class="mobile-logo" [src]="'assets/images/Logos'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.png'" alt="JRMS" /> -->
	</div>

	<app-menu></app-menu>

	<div class="layout-mask modal-in"></div>

	<div class="topbar-right">
		<ul class="topbar-menu">
			<li class="profile-item">
				<a routerLink="/sec/member/mymember/{{ memberId }}">
					<span class="profile-name">{{ username }}</span>
				</a>
			</li>
		</ul>
	</div>
</div>
