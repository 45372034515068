import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BreadcrumbService } from '../../app.breadcrumb.service';
import * as atlas from 'azure-maps-control/dist/atlas-core.js';
import { environment } from 'src/environments/environment';

import { MemberService } from '../../services/member.service';
import { Strength } from 'src/app/interfaces/strength';
import { AtlasMapOptions, ChartData, Columns, OrdersChartOptions, Week } from 'src/app/interfaces/dashboard-chart';
import * as PermissionConstants from 'src/app/permissions.constants';

@Component({
	templateUrl: './dashboard.component.html',
	styleUrls: [
		'./dashboard.component.scss',
	],
	providers: [MemberService],
})
export class DashboardDemoComponent implements OnInit {
	public cols: Columns[];
	public items: MenuItem[];
	public ordersChart: ChartData;
	public ordersChartOptions: OrdersChartOptions;
	public orderWeek: Week[];
	public revenueChart: ChartData;

	public txarng_membersLength: number = 0;
	public txang_membersLength: number = 0;
	public txsg_membersLength: number = 0;
	public tmd_membersLength: number = 0;

	public Permissions = PermissionConstants;

	public constructor(
		private breadcrumbService: BreadcrumbService,
		private memberService: MemberService
	) {
		this.breadcrumbService.setItems([
			{ label: 'Dashboard', routerLink: ['/'] },
		]);
	}

	public ngOnInit() {
		this.cols = [
			{ field: 'vin', header: 'Vin' },
			{ field: 'year', header: 'Year' },
			{ field: 'brand', header: 'Brand' },
			{ field: 'color', header: 'Color' },
		];

		this.items = [
			{
				label: 'Shipments',
				items: [
					{ label: 'Tracker', icon: 'pi pi-compass' },
					{ label: 'Map', icon: 'pi pi-map-marker' },
					{ label: 'Manage', icon: 'pi pi-pencil' },
				],
			},
		];

		this.ordersChart = {
			labels: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
			],
			datasets: [
				{
					label: 'New',
					data: [2, 7, 20, 9, 16, 9, 5],
					backgroundColor: ['rgba(100, 181, 246, 0.2)'],
					borderColor: ['#64B5F6'],
					borderWidth: 3,
					fill: true,
					tension: 0.4,
				},
			],
		};

		this.ordersChartOptions = {
			plugins: {
				legend: {
					display: true,
				},
			},
			hover: {
				mode: 'index',
			},
			scales: {
				y: {
					ticks: {
						min: 0,
						max: 20,
					},
				},
			},
		};

		this.orderWeek = [
			{ name: 'This Week', code: '0' },
			{ name: 'Last Week', code: '1' },
		];

		this.revenueChart = {
			labels: ['Deployed', 'Not Available', 'Ready to Deploy'],
			datasets: [
				{
					data: [400, 72, 1025],
					backgroundColor: ['#64B5F6', '#7986CB', '#4DB6AC'],
				},
			],
		};

		this.retrieveComponents();
		// this.initMap();
	}

	public retrieveComponents() {
		this.memberService.getStrengthStatus().subscribe({
			next: (result: Strength) => {
				this.txang_membersLength = result.txang;
				this.txarng_membersLength = result.txarng;
				this.txsg_membersLength = result.txsg;
				this.tmd_membersLength = result.total;
			},
		});
	}

	public initMap() {
		// This function is using atlas and we do not have control over types
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		atlas.setDomain('atlas.azure.us');

		const mapOptions: AtlasMapOptions = {
			center: [-99.33, 31.35],
			zoom: 4.5,
			showLogo: false,
			style: 'road_shaded_relief',
			language: 'en-US',
			authOptions: {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
				authType: atlas.AuthenticationType.subscriptionKey,
				subscriptionKey: environment.azureMapsSubscriptionKey,
			},
		};

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		const map = new atlas.Map('myMap', mapOptions);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		const zoomControl = new atlas.control.ZoomControl();
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		const compassControl = new atlas.control.CompassControl();
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		const pitchControl = new atlas.control.PitchControl();
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		const styleControl = new atlas.control.StyleControl();

		const controlsOptions = {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
			position: atlas.ControlPosition.TopRight,
		};

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		map.controls.add(
			[zoomControl, compassControl, pitchControl, styleControl],
			controlsOptions
		);
	}

	public changeDataset(event: MouseEvent) {
		const dataSet: number[][] = [
			[2, 7, 20, 9, 16, 9, 5],
			[2, 4, 9, 20, 16, 12, 20],
			[2, 17, 7, 15, 4, 20, 8],
			[2, 2, 20, 4, 17, 16, 20],
		];

		const target = event.currentTarget as HTMLElement;
		const dataIndex = parseInt(target.getAttribute('data-index') || '');
		const dataLabel = target.getAttribute('data-label') || '';
		const dataStroke = target.getAttribute('data-stroke') || '';
		const dataFill = target.getAttribute('data-fill') || '';

		if (this.ordersChart && this.ordersChart.datasets && this.ordersChart.datasets[0]) {
			this.ordersChart.datasets[0].data = dataSet[dataIndex];
			this.ordersChart.datasets[0].label = dataLabel;
			this.ordersChart.datasets[0].borderColor = dataStroke;
			this.ordersChart.datasets[0].backgroundColor = dataFill;
		}
	}
}
