import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'activeInactive',
})
export class ActiveInactivePipe implements PipeTransform {
	public transform(value: boolean): string {
		return value ? 'Active' : 'Inactive';
	}
}
