import { Component } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { FeatureFlagService } from '../services/featureflag.service';

@Component({
	selector: 'app-doc',
	templateUrl: './contributors.component.html',
	styleUrls: [
		'./contributors.component.scss'
	]
})
export class ContributorsComponent {
	public feature:'contributors';

	public constructor(
		private breadcrumbService: BreadcrumbService,
		private featureFlagService: FeatureFlagService
	)

	{
		this.breadcrumbService.setItems([
			{label: 'Contributors', routerLink: ['/']}
		]);

	}

	public isMyFeatureEnabled(): boolean {
		return this.featureFlagService.isFeatureEnabled('contributors');
	}
}
