import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GenericService } from './generic.service';
import { ASIDto } from '../models/ASIDto';
import { ASISerializer } from '../serializers/ASISerializer';

@Injectable()
export class ASIService extends GenericService<ASIDto> {
	public constructor(@SkipSelf() public httpClient: HttpClient) {
		super(
			httpClient,
			environment.api + 'api',
			'ASIs',
			new ASISerializer()
		);
	}
}
