import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import {
	TokenManager,
	TokenStorage,
	FRUser,
	UserManager,
} from '@forgerock/javascript-sdk';
import { UserInfo, UserService } from '../services/user.service';
import { environment } from 'src/environments/environment';
import { MemberService } from '../services/member.service';
import { MyMember } from '../interfaces/MemberModel';

export type Role = {
	id: number;
	name: string;
	description: string;
};

export type Permission = {
	id: number;
	name: string;
	systemIdentifier: string;
	description: string;
};


@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private baseUrl = environment.api;

	public constructor(
		private http: HttpClient,
		private ngxPermissionsService: NgxPermissionsService,
		private ngxRolesService: NgxRolesService,
		private router: Router,
		private userService: UserService,
		private memberService: MemberService,
	) { }

	public async login(): Promise<void> {
		const authenticated = await this.authenticated();

		if (!authenticated) {
			await this.authenticate();
			await this.authorize();
			await this.loadUserInfo();
		}

		// User is authenticated, redirect to the dashboard
		await this.router.navigate(['/sec'], { replaceUrl: true });
	}

	public async logout(): Promise<void> {
		await FRUser.logout();
		this.userService.resetUser();
		await this.router.navigate(['./']);
	}

	public async authenticated(): Promise<boolean> {
		try {
			const tokens = await TokenStorage.get();

			if (tokens === undefined) {
				this.userService.authenticated = false;
				return false;
			}

			this.userService.authenticated = true;
			return true;
		}
		catch (error: unknown) {
			console.error(error);

			this.userService.authenticated = false;
			return false;
		}
	}

	public async authenticate(): Promise<void> {
		try {
			await TokenManager.getTokens({ login: 'redirect' });
		}
		catch (error: unknown) {
			console.log(error);
		}
	}

	public async authorize(): Promise<void> {
		await this.userService.getUserSecurityInfo();

		if (this.userService.permissions.length) {
			this.ngxPermissionsService.loadPermissions(this.userService.permissions.map((x: Permission) => x.systemIdentifier));
		}
		else {
			this.ngxPermissionsService.flushPermissions();
		}

		if (this.userService.roles.length && this.userService.roles.some(x => x.id === 1)) {
			this.ngxRolesService.addRole('SuperSysAdmin', () => true);
		}
		else {
			this.ngxRolesService.flushRoles();
		}
	}

	// I don't really like this method being in this service but
	// putting it in the UserService causes a circular dependency issue.
	public async loadUserInfo(): Promise<MyMember> {
		try {
			const userInfo = await UserManager.getCurrentUser();

			if (userInfo === undefined) {
				await this.authenticate();
				await this.loadUserInfo();
				return null;
			}

			this.userService.myMemberData = await this.memberService.getMyMember().toPromise();

			if (userInfo) {
				this.userService.info = userInfo as UserInfo;
			}
		}
		catch (error: unknown) {
			await this.authenticate();
		}
		return null;
	}
}
