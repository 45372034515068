import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppCodeModule } from './blocks/app-code/app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { IconsComponent } from './utilities/icons.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { BlocksComponent } from './blocks/blocks/blocks.component';
import { BlockViewerComponent } from './blocks/blockviewer/blockviewer.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import { IconService } from './demo/service/iconservice';
import { BreadcrumbService } from './app.breadcrumb.service';
import { MenuService } from './app.menu.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { GlobalService } from './services/globals.service';
import { MemberService } from './services/member.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RmsPrimengModule } from './RmsPrimengModule.module';
import { LandingComponent } from './landing/landing.component';
import { RmsService } from './services/rms.service';
import { DeactivateConfirmComponent } from './form-guard/deactivate-confirm/deactivate-confirm.component';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoginAlertComponent } from './login-alert/login-alert.component';
import { FeatureFlagService } from './services/featureflag.service';
import { InitializerModule } from './initializer.module';
import { ConfigService } from './services/config.service';
import { AuthService } from './auth/auth.service';
import { UserService } from './services/user.service';

@NgModule({
	imports: [
		InitializerModule,
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		RmsPrimengModule,
		FullCalendarModule,
		AppCodeModule,
		NgxPermissionsModule.forRoot(),
	],
	declarations: [
		AppComponent,
		AppMainComponent,
		AppMenuComponent,
		AppMenuitemComponent,
		AppTopBarComponent,
		AppSearchComponent,
		AppFooterComponent,
		DashboardDemoComponent,
		IconsComponent,
		AppLoginComponent,
		AppNotfoundComponent,
		AppErrorComponent,
		AppAccessdeniedComponent,
		BlockViewerComponent,
		BlocksComponent,
		LandingComponent,
		DeactivateConfirmComponent,
		LoginAlertComponent,
	],
	providers: [
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		FeatureFlagService,
		ConfigService,
		AuthService,
		UserService,
		HttpErrorHandler,
		IconService,
		MenuService,
		BreadcrumbService,
		GlobalService,
		MemberService,
		MessageService,
		ConfirmationService,
		RmsService,
		DialogService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		MessageService
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {

	public calendarOptions: CalendarOptions = {
		plugins: [
			timeGridPlugin,
			dayGridPlugin,
			interactionPlugin,
		],
		initialView: 'dayGridMonth',
	};
}
