import { BaseEntity } from "./BaseEntity";

export class PaginatedList<T extends BaseEntity> {
	public hasNextPage: boolean = null;
	public hasPreviousPage: boolean = null;
	public items: Array<T> = null;
	public pageNumber: number = null;
	public totalCount: number = null;
	public totalPages: number = null;
}
