import { Component } from '@angular/core';
import { MenuService } from './app.menu.service';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
	selector: 'app-main',
	templateUrl: './app.main.component.html',
})
export class AppMainComponent {
	// public subscription: Subscription;
	public items: MenuItem[];
	public overlayMenuActive: boolean;
	public staticMenuDesktopInactive: boolean;
	public staticMenuMobileActive: boolean;
	public menuClick: boolean;
	public search = false;
	public searchClick = false;
	public userMenuClick: boolean;
	public topbarUserMenuActive: boolean;
	public notificationMenuClick: boolean;
	public topbarNotificationMenuActive: boolean;
	public rightMenuClick: boolean;
	public rightMenuActive: boolean;
	public configActive: boolean;
	public configClick: boolean;
	public resetMenu: boolean;
	public menuHoverActive = false;

	public constructor(
		private menuService: MenuService,
		private primengConfig: PrimeNGConfig,
		public app: AppComponent
	) {}

	public onLayoutClick() {
		if (!this.searchClick) {
			this.search = false;
		}

		if (!this.userMenuClick) {
			this.topbarUserMenuActive = false;
		}

		if (!this.notificationMenuClick) {
			this.topbarNotificationMenuActive = false;
		}

		if (!this.rightMenuClick) {
			this.rightMenuActive = false;
		}

		if (!this.menuClick) {
			if (this.isSlim() || this.isHorizontal()) {
				this.menuService.reset();
			}

			if (this.overlayMenuActive || this.staticMenuMobileActive) {
				this.hideOverlayMenu();
			}

			this.menuHoverActive = false;
			this.unblockBodyScroll();
		}

		if (this.configActive && !this.configClick) {
			this.configActive = false;
		}

		this.searchClick = false;
		this.configClick = false;
		this.userMenuClick = false;
		this.rightMenuClick = false;
		this.notificationMenuClick = false;
		this.menuClick = false;
	}

	public onMenuButtonClick(event: MouseEvent) {
		this.menuClick = true;
		this.topbarUserMenuActive = false;
		this.topbarNotificationMenuActive = false;
		this.rightMenuActive = false;

		if (this.isOverlay()) {
			this.overlayMenuActive = !this.overlayMenuActive;
		}

		if (this.isDesktop()) {
			this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
		}
		else {
			this.staticMenuMobileActive = !this.staticMenuMobileActive;

			if (this.staticMenuMobileActive) {
				this.blockBodyScroll();
			}
			else {
				this.unblockBodyScroll();
			}
		}

		event.preventDefault();
	}

	public onSearchClick() {
		this.search = !this.search;
		this.searchClick = !this.searchClick;
	}

	public onSearchHide() {
		this.search = false;
		this.searchClick = false;
	}

	public onMenuClick() {
		this.menuClick = true;
		this.resetMenu = false;
	}

	public onTopbarUserMenuButtonClick(event: MouseEvent) {
		this.userMenuClick = true;
		this.topbarUserMenuActive = !this.topbarUserMenuActive;

		this.hideOverlayMenu();
		event.preventDefault();
	}

	public onTopbarNotificationMenuButtonClick(event: MouseEvent) {
		this.notificationMenuClick = true;
		this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

		this.hideOverlayMenu();
		event.preventDefault();
	}

	public onRightMenuClick(event: MouseEvent) {
		this.rightMenuClick = true;
		this.rightMenuActive = !this.rightMenuActive;

		this.hideOverlayMenu();
		event.preventDefault();
	}

	public onRippleChange(event: CheckboxChangeEvent) {
		const checked = event.checked as boolean;
		this.app.ripple = checked;
		this.primengConfig.ripple = checked;
	}

	public onConfigClick() {
		this.configClick = true;
	}

	public isSlim() {
		return this.app.menuMode === 'slim';
	}

	public isHorizontal() {
		return this.app.menuMode === 'horizontal';
	}

	public isOverlay() {
		return this.app.menuMode === 'overlay';
	}

	public isDesktop() {
		return window.innerWidth > 1091;
	}

	public isMobile() {
		return window.innerWidth <= 1091;
	}

	public hideOverlayMenu() {
		this.overlayMenuActive = false;
		this.staticMenuMobileActive = false;
	}

	public blockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.add('blocked-scroll');
		}
		else {
			document.body.className += ' blocked-scroll';
		}
	}

	public unblockBodyScroll(): void {
		if (document.body.classList) {
			document.body.classList.remove('blocked-scroll');
		}
		else {
			document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
				'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}
}
