import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { MenuItemService } from './services/menuitem.service';
import { CustomValue, MenuItem, MenuItemModel, SeparatorItem } from './interfaces/menu-item';
import { UserService } from './services/user.service';
import { AppMainComponent } from './app.main.component';

@Component({
	selector: 'app-menu',
	templateUrl: './app.menu.component.html',
	styleUrls: [],
})
export class AppMenuComponent implements OnInit {
	public model: (MenuItemModel | SeparatorItem)[];

	public constructor(
		public appMain: AppMainComponent,
		private authService: AuthService,
		private menuItemService: MenuItemService,
		private userService: UserService
	) { }

	public async ngOnInit(): Promise<void> {
		const menuItems = await this.menuItemService.GetMenuItems();
		const model: (MenuItemModel | SeparatorItem)[] = [
			{
				label: '',
				id: 0,
				name: '',
				url: '',
				customValue: '',
				children: [],
				items: [
					{
						label: 'Home',
						icon: 'pi pi-fw pi-home',
						routerLink: ['/sec'],
						id: 0,
						name: 'Home',
						url: '/sec',
						customValue: '',
						children: [],
					},
				],
			},
			{ separator: true },
		];

		for (const menuitem of menuItems) {
			model.push(this.processMenuItem(menuitem));

			if (menuitem !== menuItems[menuItems.length - 1]) {
				model.push({ separator: true });
			}
		}

		this.model = model;
	}

	private processMenuItem(menuItem: MenuItem): MenuItemModel {
		const item: MenuItemModel = {
			id: menuItem.id,
			name: menuItem.name,
			url: menuItem.url,
			customValue: menuItem.customValue,
			children: [],
			label: menuItem.name,
		};

		let customValue: CustomValue = null;

		try {
			customValue = JSON.parse(menuItem.customValue) as { route?: string; icon?: string; command?: string };
		}
		catch (error) { }

		if (menuItem.url !== null && menuItem.url.trim() !== '') {
			if (customValue && 'route' in customValue && customValue.route === 'memberId') {
				const userId = this.userService.myMemberData.memberID.toString();

				item.routerLink = [menuItem.url, userId];
			}
			else {
				item.routerLink = [menuItem.url];
			}
		}

		if (customValue && 'icon' in customValue && customValue.icon !== undefined) {
			item.icon = customValue.icon;
		}

		if (customValue?.command === 'logout') {
			item.command = async () => await this.authService.logout();
		}

		if (menuItem.children.length > 0) {
			item.items = [];

			for (const child of menuItem.children) {
				item.items.push(this.processMenuItem(child));
			}
		}

		return item;
	}
}
