import { BaseEntity } from "./BaseEntity";

export class CompanyUnitDto extends BaseEntity {
	public uic: string | null = null;
	public branch: string | null = null;
	public brigade: string | null = null;
	public battalion: string | null = null;
	public company: string | null = null;
	public branchId: number | null = null;
	public brigadeId: number | null = null;
	public battalionId: number | null = null;
	public companyId: number | null = null;
}
